<template>
    <el-container class="residential-address flex-column">
      <section class="flex-column gap-half">
        <Typography variant="h3" textWeight="400">What’s your residential address</Typography>
        <typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
            This is required for our compliance requirements.
        </typography>
      </section>
      <PostcodeFormComponent
        class="mb-0" v-if="!isManual && step === 1"
        :showManualOptions="true"
        @set-manual="setManual"
        @select-address="selectAddress"
        @validPostcode="validPostcode"
        @toggle-next="hideNextButton = $event"/>
      <ManualAddressFormComponent
        v-if="step === 2"
        :step="step"
        :address="selectedAddress"
        :isLegalOwner="isLegalOwner"
        :isOnResidentialForm="true"
        @back="backToPostCodeForm"
        @select-address="selectAddress"
        @update-legalowner="updateLegalOwner"
        @validPostcode="validPostcode" />
      <ManualAddressFormComponent
        v-if="isManual && step === 1"
        :step="step"
        :address="selectedAddress"
        :isLegalOwner="isLegalOwner"
        :isOnResidentialForm="true"
        @manual-address-data="onAddAddress($event, null)"
        @back="backToPostCodeForm"
        @select-address="selectAddress"
        @update-legalowner="updateLegalOwner"
        @validPostcode="validPostcode" />
      <div v-if="step === 1 && isValidPostcode" class="w-50">
        <Button class="w-100" :loading="createSubmitting"  @handle-click="nextStep" :isActive="selectedAddress" v-if="hideNextButton" buttonText="next"/>
      </div>
      <div v-if="step === 2" class="w-50">
        <Button class="w-100" :loading="createSubmitting" :isActive="selectedAddress" buttonText="add address" @handle-click="onAddAddress(null, selectedAddress.ADDRESS)"/>
      </div>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import ManualAddressFormComponent from '@/modules/properties/components/ManualAddressFormComponent.vue';
import PostcodeFormComponent from '@/modules/properties/components/PostcodeFormComponent.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    Typography,
    PostcodeFormComponent,
    Button,
    ManualAddressFormComponent
  },

  emits: ['next'],

  data() {
    return {
      isManual: false,
      step: 1,
      hideNextButton: false,
      isValidPostcode: false,
      selectedAddress: null,
      isShowCreate: false,
      createSubmitting: false,
      isLegalOwner: true,
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),

    hasResidentialAddress() {
      const userBusinessDetails = this.user?.userBusinessDetails;
      const postcode = userBusinessDetails.residencyPostcode;
      const town = userBusinessDetails.residencyTown;
      const addressline = userBusinessDetails.residencyAddressLine;

      return postcode && town && addressline;
    }
  },

  created() {
    if (this.hasResidentialAddress) {
      this.isManual = true;
    }
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'getBusinessDetails',
      'saveBusinessDetails',
      'getAccountStatuses',
      'getUser',
      'saveUserState',
      'updateBusinessDetails'
    ]),

    validPostcode(value) {
      this.isValidPostcode = value;
    },

    async selectAddress(address) {
      this.selectedAddress = null;

      if (!this.isShowCreate) {
        this.isShowCreate = true;
      }

      this.selectedAddress = {
        ...address,
        latlng: [address.LAT, address.LNG]
      };
    },

    updateLegalOwner() {
      this.isLegalOwner = !this.isLegalOwner;
    },

    nextStep() {
      this.step += 1;
    },

    backToPostCodeForm() {
      this.isValidPostcode = false;
      this.isManual = false;
      this.step = 1;
      this.selectedAddress = null;
    },

    onAddAddress(manualAddress = null, lookUpAddress) {
      const { selectedAddress } = this;
      let formData = {};

      if (manualAddress === null) {
        const [addressLineRaw] = selectedAddress.ADDRESS.split(`, ${selectedAddress.POSTCODE}`);
        const [addressLine] = addressLineRaw.split(`, ${selectedAddress.POST_TOWN}`);
        formData = {
          residencyAddressLookup: lookUpAddress,
          residencyAddressLine: addressLine,
          residencyTown: selectedAddress.POST_TOWN,
          residencyPostcode: selectedAddress.POSTCODE,
          type: 'soletrader'
        };
      } else {
        formData = {
          residencyAddressLine: manualAddress.addressLine,
          residencyTown: manualAddress.city,
          residencyPostcode: manualAddress.postcode,
          residencyAddressLookup: null,
          type: 'soletrader'
        };
      }

      this.createSubmitting = true;

      this.updateBusinessDetails({ id: this.user?.userBusinessDetails.id, userId: this.user.id, formData })
        .then(async () => {
          this.$notify.success({
            message: 'Business Details successfully saved.'
          });

          await this.saveUserState(await this.getUser(this.user.id));

          this.$emit('next', true);
        })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error submitting business details.'
          });
        })
        .finally(() => {
          this.createSubmitting = false;
        });
    },

    setManual() {
      this.isManual = true;
      this.selectedAddress = null;
    }
  },
});
</script>
<style lang="scss" scoped>
.residential-address {
  &__next-wrapper {
    width: 50%;
  }
}
.mb-0 {
  margin-bottom: 0;
}
</style>
