import { CirclePlusFilled } from '@element-plus/icons';
import moment from 'moment';
import { defineComponent } from 'vue';
import Button from '@/core/components/ui/Button.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import CurrencyFilter from '@/filters/currency.filter';
export default defineComponent({
    name: 'insurance-detail',
    components: {
        CirclePlusFilled,
        Button
    },
    props: ['value', 'actionIsButtonType'],
    data() {
        return {
            userInsurances: []
        };
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler() {
                this.initialize();
            }
        },
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            const { value } = this;
            if (value && value.length) {
                this.userInsurances = value.map((item) => {
                    const { id, insurance, userVerificationStatus, metadata } = item;
                    return {
                        id,
                        name: insurance.name,
                        sum: CurrencyFilter.formatToCurrency(metadata.sum),
                        expiryDate: moment(metadata.expiryDate).format('DD/MM/YYYY'),
                        isVerified: userVerificationStatus === UserVerificationStatusEnum.VERIFIED
                    };
                });
            }
        },
        onDeleteValue(id) {
            this.$emit('on-delete-value', id);
        },
        onToggleForm() {
            this.$emit('on-taggle-form');
        }
    }
});
