<template>
    <el-container class="sole-trader-name flex-column">
      <section class="flex-column gap-half h-100">
        <Typography variant="h3" textWeight="400">How do you refer to your Sole Trading?</Typography>
        <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
            Please let us know your preferred / Trading As name
        </Typography>
        <div class="sole-trader-name__content flex-column h-100 jc-sb">
          <el-form :model="form" class="default-form" label-position="top">
            <el-form-item label="Preferred / Trading As Name">
                <el-input v-model="form.companyName" placeholder="E.g. Klein Buildings"></el-input>
            </el-form-item>
          </el-form>
          <Button :isActive="isFormReady" buttonText="continue" @handle-click="onNext"/>
        </div>
      </section>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    Button
  },

  emits: ['next'],

  data() {
    return {
      form: {
        companyName: ''
      },
      isFormReady: false
    };
  },

  computed: {
  },

  watch: {
    'form.companyName': {
      deep: true,
      immediate: true,
      handler(value) {
        if (value && value !== '') {
          this.isFormReady = true;
        } else {
          this.isFormReady = false;
        }
      }
    }
  },

  async created() {
  },

  methods: {
    onNext() {
      this.$emit('next');
    }
  },
});
</script>
<style lang="scss" scoped>
.sole-trader-name {
  &__content {
    width: 50%;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }
  :deep(.el-input) {
    height: 42px;
    width: 100%;

    .el-input__inner {
        background: rgba(12, 15, 74, 0.05);
        border-radius: 100px;
        outline:none;
        border: none;
        padding: 20px 20px 20px 20px;
    }
  }
}
</style>
