<template>
    <el-scrollbar class="company-card-list w-100">
      <div class="company-card-list__scrollbar-content flex-column w-100">
        <SelectionCard
          class="company-card-list__item"
          @click="selectCard(company.value)"
          v-for="(company, index) in companies"
          :unsetMinWidth="true"
          :key="index"
          :data="company"
          :selectedValue="selectedCompany">
        </SelectionCard>
      </div>
    </el-scrollbar>
</template>
<script>
import { defineComponent } from 'vue';

import SelectionCard from '@/core/components/common/cards/SelectionCard.vue';

export default defineComponent({
  components: {
    SelectionCard
  },

  emits: ['on-select'],

  data() {
    return {
      companies: [
        {
          value: 1,
          title: 'Iknowa Ltd',
          label: 'Company Number: 29846400',
        },
        {
          value: 2,
          title: 'Explore Iknowa',
          label: 'Company Number: 29846400',
        },
        {
          value: 3,
          title: 'Iknowa Ltd',
          label: 'Company Number: 29846400',
        },
        {
          value: 4,
          title: 'Explore Iknowa',
          label: 'Company Number: 29846400',
        },
        {
          value: 5,
          title: 'Iknowa Ltd',
          label: 'Company Number: 29846400',
        },
        {
          value: 6,
          title: 'Explore Iknowa',
          label: 'Company Number: 29846400',
        },
        {
          value: 7,
          title: 'Iknowa Ltd',
          label: 'Company Number: 29846400',
        },
        {
          value: 8,
          title: 'Explore Iknowa',
          label: 'Company Number: 29846400',
        },
      ],
      selectedCompany: null
    };
  },

  methods: {
    selectCard(value) {
      this.selectedCompany = value;
      this.$emit('on-select', value);
    },
  },
});
</script>
<style lang="scss" scoped>
.company-card-list {
  &__scrollbar-content {
    padding: 0.5rem;
    box-sizing: border-box;
  }

  &__item {
    max-height: 90px;
    min-height: 90px;
  }
}
</style>
