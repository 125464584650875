<template>
  <div class="flex-row ai-c gap-half">
    <el-checkbox v-model="agreedToTerms" @change="onChange"/>
    <p class="default-text">
      {{tag}} <span class="bg-yellow cursor-pointer" @click="onOpenAgreement">{{spanTag}}</span>
    </p>
  </div>
</template>
<script>
export default {
  emits: ['on-change-state', 'on-open-agreement'],
  props: ['tag', 'spanTag'],

  data() {
    return {
      agreedToTerms: false
    };
  },

  methods: {
    onChange() {
      this.$emit('on-change-state', this.agreedToTerms);
    },

    onOpenAgreement() {
      this.$emit('on-open-agreement');
    }
  },
};
</script>
<style lang="scss" scoped>
.el-checkbox {
    display: flex;
    align-items: flex-start;

    .el-checkbox__label {
      font-style: normal;
      font-size: 13px;
      line-height: 16px;
      color: rgba(255, 255, 255, 1);
    }

    :deep(.el-checkbox__input .el-checkbox__inner) {
      border-radius: 100px;
      background: rgba(12, 15, 74, 0.05);
      border: 0 solid transparent;
      width: 18px;
      height: 18px;
    }

    :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
    display: none;
    }

    :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
    border: 0 solid transparent;
    background: rgba(12, 15, 74, 0.05);
    transition: 0.2s ease-in-out;
    }

    :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    background: #ffa500;
    border: 4px solid #ffa500;
    transition: 0.2s ease-in-out;
    }
}

:deep(.el-checkbox__input) {
    margin-top: 5px;
  }

p {
  white-space: normal;
  font-size: 13px;
  line-height: 16px;

  .bg-yellow {
    color: #FFA500;
  }
}
</style>
