<template>
    <div class="passport-profile flex-row ai-fs gap-half">
        <ProfilePicturePlaceholder/>
        <div class="passport-profile__wrapper flex-column gap-0">
          <Typography variant="h4">{{ getFullName }}</Typography>
          <div class="flex-row ai-c">
            <Typography variant="custom" textSize="0.75rem" textWeight="400" textColor="rgba(12, 15, 74, .5)">
              {{ getAddress }}
            </Typography>
            <div class="flex-row ai-c gap-half" v-if="user.postcode">
              <Icon
                customPath="profile"
                iconName="location-icon.svg"
                iconHeight="0.625rem"
                iconWidth="0.625rem"/>
              <Typography
                variant="custom"
                textSize="0.75rem"
                textWeight="400"
                textColor="rgba(12, 15, 74, .5)">
                {{ getPostcode }}
              </Typography>
            </div>
          </div>
          <ActionItemComponent
            fontColor="#FFA500"
            label="Update Profile"
            icon="arrow-right.svg"
            :isReverse="true"/>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import ProfilePicturePlaceholder from '@/core/components/common/profile/ProfilePicturePlaceholder.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  name: 'PassportProfile',

  components: {
    ProfilePicturePlaceholder,
    Typography,
    Icon,
    ActionItemComponent
  },

  props: ['user'],

  created() {
    console.log(this.user, 'user');
  },

  computed: {
    getFullName() {
      const { user } = this;

      return `${user?.firstName} ${user?.lastName}` || '';
    },

    getAddress() {
      return this.user?.address || '';
    },

    getPostcode() {
      return this.user?.postcode || '';
    }
  }
});
</script>
<style lang="scss" scoped>
</style>
