import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
export default defineComponent({
    name: 'workstation-create-modal',
    emits: ['close'],
    components: {
        BaseModal
    },
    props: {
        show: Boolean
    },
    data: () => {
        return {
            companyName: '',
            companyNumber: '',
            charactersRemaining: 50
        };
    },
    computed: {
        ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation']),
        setPlaceholderByTypeWorkstation() {
            return this.getTypeWorkstation === 'team' ? 'Team name' : 'Company name';
        }
    },
    watch: {
        companyName() {
            this.charactersRemaining = 50 - this.companyName.length;
        }
    },
    created() {
        this.setSelectedWorkstationDetail(null);
    },
    methods: {
        ...mapActions(['setWorkstationCompanyModal', 'setWorkstationPlanModal', 'setWorkstationChooseModal']),
        ...mapActions(USER_WORKSTATION, ['setTypeWorkstation', 'setSelectedWorkstationDetail']),
        nextStep() {
            if (!this.companyName.length)
                return;
            const { companyName, companyNumber } = this;
            this.setSelectedWorkstationDetail({
                name: companyName,
                companyNumber
            });
            this.setWorkstationCompanyModal(false);
            this.setWorkstationPlanModal(true);
        },
        handleBack() {
            this.setWorkstationCompanyModal(false);
            this.setWorkstationChooseModal(true);
        },
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        }
    }
});
