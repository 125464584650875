<template>
    <el-container class="limited-company flex-column jc-sb">
      <section class="flex-column">
        <div class="flex-column gap-half">
            <Typography variant="h3" textWeight="400">Let’s confirm your company</Typography>
            <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
                Below are the companies that you are associated with. If you are the owner / director, please select them below.
            </Typography>
        </div>
        <div class="limited-company__wrapper w-50">
          <CompanyCardList v-if="screenType === DEFAULT_SCREEN" @on-select="onSelectCard"/>
          <ManualCompanySearchForm v-if="screenType === MANUAL_SEARCH_SCREEN"/>
          <SelectedCompanyList v-if="screenType === SELECTED_LIST_SCREEN" @on-agree="onAgreementChange"/>
        </div>
      </section>
      <section class="limited-company__footer flex-row ai-c jc-sb w-100" v-if="screenType !== MANUAL_SEARCH_SCREEN">
        <Button
          class="limited-company__button w-50"
          :isActive="isFormReady"
          @handle-click="onHandleClick"
          :buttonText="isOnTheForm ? 'save' : 'next'"/>
        <div class="flex-row ai-c gap-half" @click="onSetManualCompanySearchScreen" v-if="screenType === DEFAULT_SCREEN">
          <Typography
            class="cursor-pointer"
            variant="p"
            textColor="rgba(12, 15, 74, 0.5)"
            textWeight="500">
            Search for a
          </Typography>
          <div class="flex-row ai-c gap-half cursor-pointer">
            <Typography
                class="cursor-pointer"
                variant="p"
                whiteSpace="nowrap"
                textColor="#FAA500"
                textWeight="500">
                different company
            </Typography>
            <el-icon :size="14" color="#FAA500"><ArrowRightBold/></el-icon>
          </div>
        </div>
      </section>
    </el-container>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import CompanyCardList from '@/modules/account-setup/components/CompanyCardList.vue';
import SelectedCompanyList from '@/modules/account-setup/components/SelectedCompanyList.vue';
import ManualCompanySearchForm from '@/modules/account-setup/forms/ManualCompanySearchForm.vue';
import { USERS_STORE } from '@/store/modules/users';

const DEFAULT_SCREEN = 'default';
const MANUAL_SEARCH_SCREEN = 'manual-search';
const SELECTED_LIST_SCREEN = 'selected-list';

export default defineComponent({
  components: {
    Typography,
    Button,
    ArrowRightBold,
    CompanyCardList,
    ManualCompanySearchForm,
    SelectedCompanyList
  },

  emits: ['next'],

  props: ['checkValidation'],

  data() {
    return {
      userCompany: [],
      showForm: false,
      isFormReady: false,

      screenType: DEFAULT_SCREEN,

      SELECTED_LIST_SCREEN,
      MANUAL_SEARCH_SCREEN,
      DEFAULT_SCREEN,

      selectedCompany: null,
      agreement: false
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  watch: {
    selectedCompany: {
      immediate: true,
      deep: true,
      handler(value) {
        this.isFormReady = value;
      }
    },

    agreement: {
      immediate: true,
      deep: true,
      handler(value) {
        this.isFormReady = value;
      }
    },
  },

  async created() {
  },

  methods: {
    ...mapActions(USERS_STORE, []),

    onToggleForm() {
      this.showForm = !this.showForm;
    },

    onHandleClick() {
      if (this.screenType === DEFAULT_SCREEN) {
        this.setScreen(SELECTED_LIST_SCREEN);
        this.isFormReady = false;
      } else {
        this.$emit('next');
      }
    },

    setScreen(type) {
      this.screenType = type;
    },

    onSetManualCompanySearchScreen() {
      this.setScreen(MANUAL_SEARCH_SCREEN);
    },

    onSelectCard(value) {
      this.selectedCompany = value;
    },

    onAgreementChange(state) {
      this.agreement = state;
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.limited-company {
  &__wrapper {
    max-height: 350px;
  }
}

@include media-sm-max-width() {
  .limited-company {
    &__wrapper {
      width: 100%;
      max-height: 280px;
    }
    &__footer {
      flex-direction: column;
      gap: .5rem;
      align-items: flex-start;
    }
    &__button {
      width: 100%;
    }
  }
}
</style>
