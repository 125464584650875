import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import InviteOthersComponent from '@/modules/project-post/components/InviteOthersComponent.vue';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { PROPERTY_STORE } from '@/store/modules/property-details';
const POST_PROJECT_ROUTE = 'post-project';
const PROPERTY_MEMBER_ROUTE = 'property-details/members';
export default defineComponent({
    name: 'project-manage-members-modal',
    emits: ['close'],
    components: {
        BaseModal,
        InviteOthersComponent
    },
    props: {
        show: Boolean,
    },
    data: () => {
        return {
            entityType: POST_PROJECT_ROUTE,
            typeChoose: '',
        };
    },
    computed: {
        ...mapGetters(PROPERTY_STORE, [
            'property',
            'propertyMembers',
            'unsavedPropertyMembers'
        ]),
        ...mapGetters(PROPERTIES_PROJECT_POST_STORE, ['unsavedProjectMembers']),
        ...mapGetters(PROJECTS_STORE, [
            'currentSelectedProject',
        ]),
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(['setProjectManageMembersModal']),
        ...mapActions(PROPERTY_STORE, [
            'setUnsavedPropertyMembers',
            'createPropertyMembers',
            'setAddedPropertyMembers'
        ]),
        ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
            'setCurrentJourney',
            'setJourneyCompleted',
            'setUnsavedProjectMembers',
            'setProjectBudget',
            'setStatusProjectType',
            'createProjectMembers',
            'setAddedProjectMembers'
        ]),
        initialize() {
            const { $route } = this;
            const { name } = $route;
            if (name.includes(PROPERTY_MEMBER_ROUTE)) {
                this.entityType = PROPERTY_MEMBER_ROUTE;
            }
        },
        async onSave() {
            if (this.entityType === PROPERTY_MEMBER_ROUTE) {
                this.onSavePropertyMembers();
            }
            else {
                this.onSaveProjectMembers();
            }
        },
        async onSaveProjectMembers() {
            const { id: projectId } = this.currentSelectedProject;
            const unsavedProjectMembers = this.unsavedProjectMembers.map((item) => {
                return {
                    memberPermissionId: item.role,
                    email: item.email
                };
            });
            if (unsavedProjectMembers?.length) {
                await this.createProjectMembers({
                    projectId,
                    formData: { projectMembers: unsavedProjectMembers }
                }).then(async () => {
                    this.setUnsavedProjectMembers([]);
                    this.setAddedProjectMembers(true);
                    this.cancel();
                })
                    .catch(() => {
                    this.$notify.error({
                        message: 'Error saving members. Check if the email already exist.'
                    });
                })
                    .finally(() => {
                });
            }
        },
        async onSavePropertyMembers() {
            const { params } = this.$route;
            const propertyId = params.id;
            const unsavedPropertyMembers = this.unsavedPropertyMembers.map((item) => {
                return {
                    memberPermissionId: item.role,
                    email: item.email
                };
            });
            const currentPropertyMembersCount = this.propertyMembers.length;
            const currentTotalMembers = this.unsavedPropertyMembers.length + currentPropertyMembersCount;
            const { property } = this;
            if (currentTotalMembers > property?.maxMemberLimit) {
                this.$notify.error({
                    message: `You can only add a total of ${property.maxMemberLimit} members based on your subscription.`
                });
                return;
            }
            if (unsavedPropertyMembers?.length) {
                await this.createPropertyMembers({
                    propertyId,
                    formData: { propertyMembers: unsavedPropertyMembers }
                }).then(async () => {
                    this.setUnsavedPropertyMembers([]);
                    this.setAddedPropertyMembers(true);
                    this.$notify.success({
                        type: 'success',
                        message: 'Successfully saved.'
                    });
                    this.cancel();
                })
                    .catch(() => {
                    this.$notify.error({
                        message: 'Error saving members. Check if the email already exist.'
                    });
                })
                    .finally(() => {
                });
            }
        },
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        }
    }
});
