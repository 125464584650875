<template>
    <div class="timeline-container flex-column jc-sb h-100 w-100">
      <section class="timeline-container__wrapper h-100 w-100">
        <RoutineTimeline v-if="type === projectType.ROUTINE"/>
        <StandardTimeline v-else/>
      </section>
      <section class="flex-column w-100">
        <Button
            class="timeline-container__button"
            buttonText="continue"
            :isActive="true"
            activeColor="#4B4BFF"
            activeFontColor="#FFFFFF"/>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import projectType from '@/core/constants/status/project-type';
import RoutineTimeline from '@/modules/project-dashboard-children/components/screens/RoutineTimeline.vue';
import StandardTimeline from '@/modules/project-dashboard-children/components/screens/StandardTimeline.vue';

export default defineComponent({
  components: {
    Button,
    StandardTimeline,
    RoutineTimeline
  },
  data() {
    return {
      projectType,
      type: projectType.STANDARD
    };
  },
});
</script>
<style lang="scss" scoped>
.timeline-container {
  height: calc(100% - 6.5rem);
  &__button {
    max-width: 342px;
    width: 100%;
  }
}
</style>
