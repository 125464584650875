<template>
    <div class="property-card default-card flex-column jc-sb h-100 cursor-pointer">
      <section class="property-card__image-wrapper overflow-hidden">
        <img :src="require(`@/assets/images/sample-image.jpg`)" alt="property image">
      </section>
      <section class="flex-column w-100 gap-0">
        <div class="flex-row ai-c jc-sb">
            <div class="flex-row ai-c gap-half">
              <Icon
                iconName="trade-avatar-circle-filled.svg"
                iconHeight="1rem"
                iconWidth="1rem"/>
              <Typography
                variant="custom"
                textSize="0.75rem"
                textWeight="700"
                textColor="#4A92E5">
                Commercial
              </Typography>
            </div>
            <div class="flex-row ai-c gap-half">
              <Icon
                customPath="profile"
                iconName="location-icon.svg"
                iconHeight="0.625rem"
                iconWidth="0.625rem"/>
              <Typography
                variant="custom"
                textSize="0.75rem"
                textWeight="400"
                textColor="rgba(12, 15, 74, .5)">
                SS12NS
              </Typography>
            </div>
        </div>
        <Typography variant="h6">23 Bretton Gardens, Wolverhampton</Typography>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    Icon,
  }
});
</script>
<style lang="scss" scoped>
.property-card {
  border-radius: 16px;
  // max-height: unset !important;
  max-height: 186px !important;
  // min-height: 186px !important;

  &__image-wrapper {
    border-radius: 8px;
    height: 80px;

    .img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
