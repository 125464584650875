<template>
    <div class="standard-timeline flex-row gap-2 h-100 w-100">
      <section class="flex-column">
        <SelectionCard
          class="selection-card-custom-class"
          @click="handleSelect(timeline.value)"
          v-for="(timeline, index) in timelineData"
          :key="index"
          :data="timeline"
          :selectedValue="selectedTimelineType"/>
      </section>
      <el-divider direction="vertical" class="h-50"/>
      <section
        class="standard-timeline__calendar-container h-100 relative"
        v-if="selectedTimelineType === timelineType.SCHEDULE">
        <el-date-picker
          v-model="standardDate"
          start-placeholder="DD/MM/YYYY"
          end-placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          value-format="YYYY-MM-DD"
          type="daterange"
          popper-class="popper"
          range-separator="-"
          :disabledDate="disabledDate">
        </el-date-picker>
      </section>
    </div>
</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';

import SelectionCard from '@/core/components/common/cards/SelectionCard.vue';

import timelineType from '../../constants';

export default defineComponent({
  components: {
    SelectionCard,
  },
  data() {
    return {
      timelineType,
      timelineData: [
        {
          value: timelineType.FLEXIBLE,
          icon: 'light-bulb.svg',
          title: 'Flexible',
          label: 'Let your local experts propose a start date, for your project.'
        },
        {
          value: timelineType.SCHEDULE,
          icon: 'routine.svg',
          title: 'Schedule',
          label: 'Select the dates that best work for your project.'
        },
      ],
      selectedTimelineType: '',
      standardDate: [],
      disabledDate(date) {
        return date < moment(new Date()).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).toDate();
      },
    };
  },
  methods: {
    handleSelect(type) {
      this.selectedTimelineType = type;
    },
  },
});
</script>
<style lang="scss" scoped>
.standard-timeline {
  &__button {
    min-width: 303px;
    max-width: 303px;
  }

  &__calendar-container {
    :deep(.el-range-editor.el-input__inner) {
      border-color: #FAA100;
    }
  }
}

.selection-card-custom-class {
  max-width: 430px !important;
  min-width: 430px !important;
  max-height: 110px !important;
  min-height: 110px !important;
}
</style>
