<template>
    <el-container class="subscription-screen flex-column jc-sb">
      <section class="flex-column gap-half">
        <Typography variant="h3" textWeight="400">Choose Your Iknowa Subscription Plan</Typography>
        <Typography
          class="hide-in-mobile"
          variant="h6"
          textColor="rgba(12, 15, 74, 0.5)"
          textWeight="500">
            Below are the companies that you are associated with. If you are the owner / director, please select them below.
        </Typography>
      </section>
      <section class="subscription-screen__wrapper flex-column w-50">
        <div class="flex-row ai-c" v-for="(value, index) in values" :key="index">
          <Icon iconName="blue-check-filled-icon.svg" iconHeight="24px" iconWidth="24px"/>
          <Typography variant="p" textWeight="400">{{ value.label }}</Typography>
        </div>
      </section>
      <section class="subscription-screen__footer grid w-100">
        <SubscriptionItem :isYearly="true" @on-select="onSelectItem"/>
        <SubscriptionItem @on-select="onSelectItem"/>
      </section>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import SubscriptionItem from '@/modules/account-setup/components/SubscriptionItem.vue';

export default defineComponent({
  components: {
    Typography,
    Icon,
    SubscriptionItem
  },

  emits: ['next-screen'],

  data() {
    return {
      values: [
        {
          label: 'Verified Iknowa Company'
        },
        {
          label: '1GB Free Storage For Documents'
        },
        {
          label: 'Bid & Win Unlimited New Jobs'
        },
        {
          label: 'Cancel Anytime'
        },
      ],
    };
  },

  computed: {
  },

  async created() {
  },

  methods: {
    onSelectItem() {
      this.$emit('next-screen');
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.subscription-screen {
  &__wrapper {
    max-height: 350px;
  }
  &__footer.grid {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}

@include media-md-max-width() {
  .subscription-screen {
    overflow-y: auto;
  }
}

@include media-sm-max-width() {
  .subscription-screen {
    &__wrapper {
      width: 100%;
      max-height: 280px;
      gap: .5rem;
    }
    &__footer.grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
    &__button {
      width: 100%;
    }
  }
}
</style>
