// import { BellFilled } from '@element-plus/icons';
// import { User } from '@/core/packages/shared-library';
import moment from 'moment';
import { computed, defineComponent } from 'vue';
// import { useRouter } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';
// import Burger from '@/core/components/ui/Burger.vue';
import HeaderWelcomeComponent from '@/core/components/layouts/HeaderWelcomeComponent.vue';
import BackButton from '@/core/components/ui/BackButton.vue';
import { DASHBOARD_ROUTE } from '@/modules/dashboard/routes';
import { MEMBERS_ROUTE } from '@/modules/members/routes';
import { PROPERTY_DETAILS } from '@/modules/property-details/routes';
import { USERS_STORE } from '@/store/modules/users';
import { NOTIFICATIONS_STORE } from '../../../store/modules/notifications';
export default defineComponent({
    name: 'layout-header',
    components: {
        // Burger,
        // BellFilled,
        HeaderWelcomeComponent,
        BackButton
    },
    props: {
        title: String,
        showBackButton: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const getGetCurrentMonth = computed(() => {
            return moment(new Date()).format('MMMM');
        });
        const getGetCurrentDay = computed(() => {
            return moment(new Date()).format('dddd');
        });
        const getGetCurrentDayNumber = computed(() => {
            return moment(new Date()).format('D');
        });
        return {
            getGetCurrentMonth,
            getGetCurrentDay,
            getGetCurrentDayNumber,
        };
    },
    data() {
        return {
            unreadNotificationsCount: 0,
            windowWidth: window.innerWidth,
            isCohortEnabled: false,
            isInProjectDetails: false,
            isInManageJobs: false,
            MEMBERS_ROUTE,
            PROPERTY_DETAILS,
            DASHBOARD_ROUTE
        };
    },
    watch: {
        windowWidth(newWidth) {
            this.windowWidth = newWidth;
            this.checkIfInProjectDetails();
        },
        isCohortEnabled: {
            handler() {
                this.initializeCohort();
            }
        },
        title: {
            immediate: true,
            handler() {
                this.checkIfInProjectDetails();
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        this.initializeCohort();
        this.checkIfInProjectDetails();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    computed: {
        ...mapGetters(['isNavCollapse']),
        ...mapGetters(NOTIFICATIONS_STORE, ['hasNewNotifications', 'notificationList']),
        ...mapGetters(USERS_STORE, ['user', 'isCohort']),
        pageTitle() {
            return this.title || '';
        },
        currentRoute() {
            return this.$route.name;
        },
        routeTitle() {
            return (this.$route?.meta?.title || '');
        },
        isDashboardRoute() {
            return this.currentRoute === DASHBOARD_ROUTE;
        }
    },
    async created() {
        if (!this.notificationList.length) {
            // await this.fetchNotificationList();
        }
        this.unreadNotificationsCount = (this.notificationList.filter((notification) => {
            return notification.state === 'unread';
        })).length;
    },
    methods: {
        ...mapActions(['setNavCollapseState']),
        ...mapActions(USERS_STORE, ['setCohort']),
        ...mapActions(NOTIFICATIONS_STORE, ['toggleDrawer', 'fetchNotificationList']),
        toggleSideNav() {
            this.setNavCollapseState(!this.isNavCollapse);
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        initializeCohort() {
            if (!this.isCohortEnabled) {
                this.setCohort(false);
            }
            else {
                this.setCohort(true);
            }
        },
        checkIfInProjectDetails() {
            if (this.pageTitle === 'Project details') {
                this.isInProjectDetails = true;
            }
            else {
                this.isInProjectDetails = false;
            }
        },
    }
});
