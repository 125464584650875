<template>
    <el-container class="id-check flex-column jc-sb">
      <section class="flex-column">
        <section class="flex-column">
          <Typography variant="h3" textWeight="400">
            {{ isIdVerified ? 'Nice job! Your ID has been successfully verified.' : 'We need to verify your identity' }}
          </Typography>
          <span class="flex-column gap-0" v-if="!isIdVerified">
            <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
              We will require your passport or driver’s license
            </Typography>
            <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
              Verified ID is required to ensure trust security and compliance on Iknowa.
            </Typography>
          </span>
        </section>
        <section class="id-check__wrapper flex-row ai-c jc-fs w-50">
          <Icon
            customPath="images"
            iconName="id-check.png"
            iconHeight="250px"
            iconWidth="250px"/>
        </section>
      </section>
      <section class="id-check__footer flex-column w-50">
        <Button
          class="id-check__button w-100"
          v-if="!isIdVerified"
          style="border: 1px solid #4F55F0"
          @handle-click="onStartVerification"
          :isActive="true"
          activeColor="#FFF"
          activeFontColor="#4F55F0"
          buttonText="Start ID verification"/>
        <Button
          v-if="isIdVerified"
          class="id-check__button w-100"
          :isActive="isIdVerified"
          @handle-click="onHandleClick"
          buttonText="next"/>
      </section>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    Button,
    Icon
  },

  emits: ['next'],

  data() {
    return {
      showForm: false,
      isIdVerified: false
    };
  },

  computed: {
  },

  async created() {
  },

  methods: {
    onToggleForm() {
      this.showForm = !this.showForm;
    },

    onHandleClick() {}
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.id-check {
  &__wrapper {
  }
}

@include media-sm-max-width() {
  .id-check {
    &__wrapper {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    &__footer {
      flex-direction: column;
      gap: .5rem;
      align-items: flex-start;
      width: 100%;
    }
  }
}
</style>
