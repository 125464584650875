import { defineComponent } from 'vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
export default defineComponent({
    components: {
        Icon,
        Typography
    },
    props: ['score']
});
