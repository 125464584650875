<template>
    <el-container class="company-type flex-column gap-2">
      <section class="flex-column gap-half">
        <Typography variant="h3" textWeight="400">Great stuff. What type of company<br>
         do you have, {{ firstName }}?</Typography>
        <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
            Before you can bid on new project, we need to understand how you’re setup as a business.
        </Typography>
      </section>
      <section class="company-type__wrapper grid">
        <SelectionCard
            @click="selectCard(type.value)"
            v-for="(type, index) in companyType"
            :unsetMinWidth="true"
            :key="index"
            :data="type"
            :selectedValue="selectedType">
          </SelectionCard>
      </section>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import SelectionCard from '@/core/components/common/cards/SelectionCard.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { LIMITED_COMPANY, SOLE_TRADER } from '@/core/constants/workstation';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    Typography,
    SelectionCard
  },

  emits: ['next'],

  data() {
    return {
      showForm: false,
      submitting: false,
      selectedType: null,
      companyType: [
        {
          value: SOLE_TRADER,
          title: 'Sole Trader',
          label: 'This can also include partnerships',
          icon: 'icon-house.svg',
        },
        {
          value: LIMITED_COMPANY,
          title: 'Limited Company',
          label: 'Registered on Companies House',
          icon: 'icon-house.svg',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation']),
    ...mapGetters(['workstationSetupModal']),

    firstName() {
      return this.user?.firstName.charAt(0).toUpperCase() + this.user?.firstName.slice(1);
    }
  },

  async created() {
  },

  methods: {
    ...mapActions(USER_WORKSTATION, ['setTypeWorkstation']),
    ...mapActions(['setWorkstationSetupModal', 'setLimitedCompanySetupModal', 'setSoleTraderSetupModal']),

    selectCard(value) {
      this.setTypeWorkstation(value);
      this.setWorkstationSetupModal(false);

      if (this.getTypeWorkstation === SOLE_TRADER) {
        this.setSoleTraderSetupModal(true);
        this.setLimitedCompanySetupModal(false);
      }

      if (this.getTypeWorkstation === LIMITED_COMPANY) {
        this.setLimitedCompanySetupModal(true);
        this.setSoleTraderSetupModal(false);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.company-type {
  &__wrapper {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

@include media-xs-max-width() {
  .company-type {
    &__wrapper {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
}
</style>
