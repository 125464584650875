import { ArrowLeftBold, Check } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
// import TruliooEmbedIdComponent from '@/core/components/common/trulioo/EmbedIdComponent.vue';
import { AUTH_SESSION_EXPIRY, AUTH_TOKEN, AUTH_USER } from '@/core/constants';
import { BusinessDetailTypeEnum } from '@/core/packages/shared-library';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '@/core/utils/common';
import TermsAndConditionsData from '@/modules/account-setup/termsAndConditionsData';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'account-setup-dialog',
    emits: ['close'],
    components: {
        ArrowLeftBold,
        // TruliooEmbedIdComponent,
        Check
    },
    props: {
        show: Boolean,
        showBusinessTypeOnly: Boolean,
        showWorkstationFormOnly: Boolean
    },
    data() {
        return {
            termsAndCons: TermsAndConditionsData,
            contents: {
                welcome: true,
                // idCheck: false,
                address: false,
                phoneNumber: false,
                profileImage: false,
                terms: false,
                confirmation: false,
            },
            disableBtn: false,
            originalContents: {},
            userForm: {
                addressLine: '',
                city: null,
                phoneNumber: null,
                postcode: null,
                profileImage: null,
                terms: null
            },
            userWorkstationForm: {
                companyName: null,
                companyNumber: null
            },
            rules: {
            // phoneNumber: [
            //   {
            //     required: true,
            //     message: 'Enter your phone number',
            //     trigger: 'blur',
            //   },
            // ]
            },
            profileImage: null,
            uploadingProfileImage: false,
            newUploadedImageKey: '',
            apiUrl: process.env.VUE_APP_API_URL,
            identification: null,
            windowWidth: window.innerWidth,
            colSpan: 10,
            isSoleTrader: false,
            isLimitedCompany: false,
            disableBusinessTypeBtn: true,
            disableWorkstationBtn: true,
            isCreatingUserWorkstation: false,
            hideSteps: false,
            agreedToTerms: false,
        };
    },
    watch: {
        // show: {
        //   immediate: true,
        //   handler(value) {
        //   }
        // },
        windowWidth(newWidth) {
            this.windowWidth = newWidth;
            if (this.windowWidth <= 490) {
                this.colSpan = 24;
            }
        },
        userWorkstationForm: {
            immediate: true,
            deep: true,
            handler(value) {
                this.validateUserWorkstationForm(value);
            }
        },
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        ...mapGetters([
            'openAccountSetupModal'
        ]),
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    created() {
        this.originalContents = this.contents;
        if (this.windowWidth <= 490) {
            this.colSpan = 24;
        }
        // this.resetContentList();
        // if (this.$props?.showBusinessTypeOnly) {
        //   this.hideSteps = this.$props?.showBusinessTypeOnly;
        //   this.setContentState('confirmation');
        // }
        if (this.$props?.showWorkstationFormOnly) {
            this.hideSteps = this.$props?.showWorkstationFormOnly;
            this.setContentState('workstation');
        }
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'user',
            'updateUser',
            'saveUserProfile',
            'initializeUserState',
            'saveBusinessDetails',
            'getAccountStatuses',
            'createUserWorkstation'
        ]),
        ...mapActions(USER_WORKSTATION, ['setActiveUserWorkstation', 'getUserWorkstations']),
        setIdentification(identification) {
            this.identification = identification;
        },
        resetContentList() {
            const { originalContents: contents } = this;
            for (const content in contents) {
                if (typeof contents[content] !== 'undefined') {
                    contents[content] = !contents[content];
                }
            }
        },
        onNextContent(type = 1) {
            const { contents } = this;
            const props = Object.keys(contents);
            let next = '';
            for (let i = 0; i < props.length; i++) {
                if (contents[props[i]]) {
                    const nextKey = type ? i + 1 : i - 1;
                    if (nextKey in props) {
                        contents[props[i]] = !contents[props[i]];
                        next = props[nextKey];
                        break;
                    }
                }
            }
            if (next && next in contents) {
                contents[next] = true;
                next = '';
            }
        },
        setContentState(state) {
            const { contents } = this;
            for (const key of Object.keys(contents)) {
                if (key === state) {
                    contents[key] = true;
                }
                else {
                    contents[key] = false;
                }
            }
        },
        handleErrorFiles(err) {
            this.$notify.error({
                title: 'Upload Error',
                message: err || 'Uploading files error.'
            });
        },
        onProgressUploadProfileImage() {
            this.uploadingProfileImage = true;
        },
        updateProfileImageOnSuccess(response) {
            this.profileImage = null;
            this.newUploadedImageKey = response.attachment;
            this.profileImage = response.src;
            this.uploadingProfileImage = false;
        },
        onSelectBusinessType(option) {
            this.isSoleTrader = false;
            this.isLimitedCompany = false;
            if (option in this) {
                this[option] = true;
            }
            this.disableBusinessTypeBtn = false;
        },
        async onConfirmBusinessType() {
            if (this.isSoleTrader) {
                this.onCreateWorkstation();
            }
            else {
                this.onNextContent();
            }
        },
        async onCreateWorkstation() {
            const { companyName, companyNumber } = this.userWorkstationForm;
            const type = this.isSoleTrader ? BusinessDetailTypeEnum.SOLETRADER : BusinessDetailTypeEnum.LIMITED_COMPANY;
            const { user } = this;
            // const data = {
            //   type,
            //   businessName: user.lastName,
            //   userVerificationStatus: UserVerificationStatusEnum.VERIFIED,
            //   userWorkstationId: this.activeUserWorkstation.id,
            //   companyName,
            //   companyNumber
            // };
            await this.updateUser({
                user: {
                    hasBusinessType: true
                },
                userId: user.id
            });
            if (this.isLimitedCompany || this.showWorkstationFormOnly) {
                // await this.saveBusinessDetails({ userId: user.id, formData: data, forceCreate: true })
                //   .then(async () => {});
                // await this.saveUserProfile({
                //   userId: user.id,
                //   profile: {
                //     userWorkstationId: this.activeUserWorkstation.id,
                //     name: companyName
                //   }
                // });
                await this.createUserWorkstation({
                    userId: user.id,
                    userWorkstation: {
                        name: companyName,
                        companyName,
                        companyNumber,
                        businessType: type
                    }
                }).then(async (response) => {
                    await this.getUserWorkstations(user.id);
                    await this.setActiveUserWorkstation(response);
                    await this.initializeUserState();
                    await this.getAccountStatuses();
                });
            }
            const authUser = getLocalStorageWithExpiry(AUTH_USER);
            authUser.hasBusinessType = true;
            setLocalStorageWithExpiry(authUser, AUTH_USER, AUTH_SESSION_EXPIRY);
            this.$emit('close');
        },
        validateUserWorkstationForm(form) {
            let disableBtn = false;
            for (const key of Object.keys(form)) {
                if (!form[key]) {
                    disableBtn = true;
                }
            }
            this.disableWorkstationBtn = disableBtn;
        },
        async onAccept() {
            const { roleId, id } = this.user;
            const { phoneNumber: contactNo, addressLine, city, postcode } = this.userForm;
            // if (!this.identification) {
            //   return;
            // }
            if (!addressLine || !city || city === null || !postcode || city === postcode || !this.userForm.phoneNumber || this.userForm.phoneNumber === null || !this.agreedToTerms || !this.newUploadedImageKey) {
                this.$notify.error({
                    title: 'All fields are required',
                    message: 'Please complete all stages.'
                });
                return;
            }
            const fullAddress = [addressLine, city].join(' ');
            await this.updateUser({
                user: {
                    roleId,
                    hasAccountSetup: true,
                    hasBusinessType: true,
                    contactNo,
                    postcode,
                    address: fullAddress,
                },
                userId: id
            })
                .then(async () => {
                const authUser = getLocalStorageWithExpiry(AUTH_USER);
                authUser.hasBusinessType = true;
                authUser.hasAccountSetup = true;
                setLocalStorageWithExpiry(authUser, AUTH_USER, AUTH_SESSION_EXPIRY);
                this.setContentState('confirmation');
                this.hideSteps = !this.hideSteps;
            });
            // const data = {
            //   type: BusinessDetailTypeEnum.SOLETRADER,
            //   businessName: lastName,
            //   userVerificationStatus: UserVerificationStatusEnum.VERIFIED,
            //   userWorkstationId: this.activeUserWorkstation.id
            // };
            // await this.saveBusinessDetails({ userId: id, formData: data })
            //   .then(async () => {});
            await this.saveUserProfile({
                userId: id,
                profile: {
                    profileImage: this.newUploadedImageKey
                }
            });
            await this.initializeUserState();
        },
        onCancel() {
            this.$emit('close');
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        }
    },
});
