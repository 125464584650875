<template>
    <el-form
        label-position="top"
        class="form"
        @submit.prevent="findAddress"
        >
        <el-form-item
          label="Postcode"
          class="iknowa-form-item"
        >
          <el-input
            class="search-input"
            v-model="searchPostcode"
          >
          </el-input>
        </el-form-item>
        <el-form-item class="mb-0">
          <div v-if="postcodes.length > 0"
            class="list-container"
          >
            <ul class="list">
              <li :class="{ 'is-active': DpaUPRN === DPA.UPRN }" v-for="{ DPA } in postcodes" :key="DPA.UPRN" @click="handleClick(DPA)">
                <div>{{ DPA.ADDRESS }}</div>
              </li>
            </ul>
          </div>
        </el-form-item>
        <el-button
            native-type="submit"
            :class="[searchPostcode.length >= 5 ? 'small-button' :'disabled-button']"
            :disabled="searchPostcode.length < 5"
            round
            v-if="!hideFindAddress"
            :loading="submitting"
        >FIND ADDRESS</el-button
        >
        <div class="form__footer flex-row ai-c gap-half" v-if="showManualOptions">
          <Typography
            class="cursor-pointer"
            variant="p"
            textColor="rgba(12, 15, 74, 0.5)"
            textWeight="500">
            Can’t find your address?
          </Typography>
          <div class="flex-row ai-c gap-half cursor-pointer" @click="setManual">
            <Typography
              class="cursor-pointer"
              variant="p"
              textColor="#FAA500"
              textWeight="500">
              Enter Manually
            </Typography>
            <el-icon :size="14" color="#FAA500"><ArrowRightBold/></el-icon>
          </div>
        </div>
    </el-form>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';
import axios from 'axios';

import Typography from '@/core/components/ui/Typography.vue';

import { VUE_APP_OS_API, VUE_APP_OS_POSTCODE_URL } from '../../../config';

export default {
  components: {
    Typography,
    ArrowRightBold
  },

  data() {
    return {
      searchPostcode: '',
      postcodes: [],
      submitting: false,
      DpaUPRN: '',
      prevPostCode: '',
      hideFindAddress: false
    };
  },

  emits: ['toggle-next', 'select-address', 'validPostcode', 'hideMap', 'set-manual'],

  props: ['showManualOptions'],

  methods: {
    async findAddress() {
      this.postcodes = [];
      this.submitting = true;
      try {
        const response = await axios.get(`${VUE_APP_OS_POSTCODE_URL}?postcode=${this.searchPostcode}&output_srs=EPSG:4326&key=${VUE_APP_OS_API}`);
        this.postcodes = response.data.results;
        if (this.postcodes.length) {
          this.$emit('select-address', { LAT: this.postcodes[0].DPA.LAT, LNG: this.postcodes[0].DPA.LNG });
          this.prevPostCode = this.searchPostcode;
          this.$emit('toggle-next', false);
        }
        this.submitting = false;
      } catch (e) {
        this.submitting = false;
        this.$notify.error({
          title: 'Error in finding address',
          message: 'Please enter a valid postcode'
        });
      }
    },
    handleClick(DPA) {
      this.$emit('select-address', DPA);
      this.$emit('validPostcode', true);
      this.DpaUPRN = DPA.UPRN;
      this.$emit('toggle-next', true);
    },
    postCodeChecker() {
      if (this.prevPostCode === this.searchPostcode) {
        this.hideFindAddress = true;
      } else {
        this.hideFindAddress = false;
      }
    },

    setManual() {
      this.$emit('set-manual');
    }
  },
  watch: {
    searchPostcode(value) {
      if (value.length < 5) {
        this.postcodes = [];
        this.$emit('hideMap');
        this.$emit('validPostcode', false);
        this.prevPostCode = '';
      }

      this.postCodeChecker();
      this.searchPostcode = value.split(' ').join('');
    },
    prevPostCode: {
      immediate: true,
      handler() {
        this.postCodeChecker();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-form {
    margin-bottom:1.5rem;
    max-width: 400px;
    .iknowa-form-item {
        margin-bottom:1rem;
    }
    .small-button {
        color: #fff;
        background: #FAA200;
        padding: 1rem 2.5rem;
        height: 42px;
        box-sizing: border-box;
        border-radius: 8px;
        border: none;
        outline: none;
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .disabled-button {
      @extend .small-button;
      background-color: rgba(12, 15, 74, 0.05);
      color: rgba(12, 15, 74, 0.5)
    }
}
::v-deep .el-form-item.iknowa-form-item .el-form-item__label {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #0C0F4A;
    text-align: left;
    position:relative;
    z-index: 12;
}
::v-deep .el-input .el-input__inner {
    border: 0 solid rgba(255, 255, 255, 0.15);
    box-sizing: border-box;
    height: 40px;
    outline: none;
    font-size: 16px;
    line-height: 22px;
    border-radius: 100px;
    color: #0C0F4A;
    background-color: rgba(12, 15, 74, 0.05);
    padding: 5px 20px;
    text-transform: uppercase;
}

.list {
  height: 230px;
  overflow-y: auto;
  list-style: none;
  background: rgba(12, 15, 74, 0.05);
  border-radius: 18px;
  padding: 13px 10px;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  li {
    color: #0C0F4A;
    text-align: left;
    padding: 7px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    cursor: pointer;
    font-family: Mulish;

    &:hover {
      background-color: rgba(255, 165, 0, 0.6);
      color: #FFF;
      border-radius: 20px;
    }
  }
  li.is-active {
    background-color: rgba(255, 165, 0, 0.6);
    color: #FFF;
    border-radius: 20px;
  }
}
.list::-webkit-scrollbar {
  display: none;
}
.mb-0 {
  margin-bottom: 0;
}
</style>
