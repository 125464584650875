import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import BudgetComponent from '@/modules/project-post/components/BudgetComponent.vue';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    name: 'project-budget-modal',
    emits: ['close'],
    components: {
        BaseModal,
        BudgetComponent
    },
    props: {
        show: Boolean,
    },
    data: () => {
        return {
            typeChoose: ''
        };
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, [
            'currentSelectedProject',
        ]),
        ...mapGetters(PROPERTIES_PROJECT_POST_STORE, [
            'projectBudget'
        ]),
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId', 'updateProject', 'setCurrentSelectedProject']),
        cancel() {
            this.$emit('close');
        },
        async confirm() {
            await this.updateProjectDetail();
        },
        async updateProjectDetail() {
            const { projectBudget } = this;
            if (!projectBudget.hasChanged) {
                this.cancel();
                return;
            }
            const formData = {
                minBudget: projectBudget.min,
                maxBudget: projectBudget.max
            };
            const { currentSelectedProject } = this;
            this.updateProject({ projectId: currentSelectedProject.id, formData })
                .then(async (response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Project Update Successful',
                        message: 'Updating Project Details successful.',
                        duration: 4000,
                    });
                    await this.getProjectByRefId(currentSelectedProject.refId)
                        .then((projectResponse) => {
                        if (projectResponse) {
                            this.setCurrentSelectedProject(projectResponse);
                        }
                    });
                    this.$emit('close');
                }
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Project Update Error',
                    message: e?.data?.message ?? 'Updating Project Details error occurred.',
                });
            })
                .finally(() => {
            });
        }
    }
});
