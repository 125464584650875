<template>
  <SetupDialogSlot :header="activeForm?.formHeader">
    <template #content>
      <WizardForm
        :config="wizardFormConfig"
        @set-active-form="setActiveForm"
        @on-complete="onComplete"/>
    </template>
  </SetupDialogSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import WizardForm from '@/core/components/common/forms/WizardForm.vue';
import SetupDialogSlot from '@/core/components/slots/SetupDialogSlot.vue';
import { PASSPORT_OVERVIEW } from '@/modules/passport-overview/routes';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    WizardForm,
    SetupDialogSlot
  },

  data() {
    return {
      wizardFormConfig: [
        // {
        //   name: 'tour',
        //   title: 'Tour',
        //   component: 'Tour',
        //   formHeader: 'Let’s show you around...',
        // },
        {
          name: 'specialisms',
          title: 'Specialisms',
          component: 'Specialism',
          dependent: 'tour',
          formHeader: 'Trade Specialisms',
          hideArrow: true,
        },
        // {
        //   name: 'certifications',
        //   title: 'Certifications',
        //   component: 'Certificate',
        //   dependent: 'specialisms',
        //   formHeader: 'Certifications',
        //   hideArrow: true,
        // },
        // {
        //   name: 'associatedCompany',
        //   title: 'Associations',
        //   component: 'AssociatedCompany',
        //   dependent: 'certifications',
        //   formHeader: 'Associated Companies',
        //   hideArrow: true,
        // },
        // {
        //   name: 'residentialAddress',
        //   title: 'Residential Address',
        //   component: 'ResidentialAddress',
        //   dependent: 'associatedCompany',
        //   formHeader: 'Residential Address',
        //   hideArrow: true,
        // },
        {
          name: 'profilePhoto',
          title: 'Profile Picture',
          component: 'ProfilePhoto',
          dependent: 'residentialAddress',
          formHeader: 'Add your Profile Picture',
          hideArrow: true,
        },
        {
          name: 'phoneNumber',
          title: 'Phone Number',
          component: 'PhoneNumber',
          dependent: 'residentialAddress',
          formHeader: 'Phone Number',
          hideArrow: true,
        },
      ],

      activeForm: null,
      PASSPORT_OVERVIEW
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['isOnPassportOverview'])
  },

  methods: {
    ...mapActions(['setAccountSetupModal', 'setPassportPreviewModal']),
    ...mapActions(USERS_STORE, ['setNotInMainLayout']),

    setActiveForm(form) {
      this.activeForm = form;
    },

    onComplete() {
      this.setAccountSetupModal(false);
      this.setNotInMainLayout(true);
      this.$router.push({ name: PASSPORT_OVERVIEW });
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
