<template>
    <el-container class="certificate-component flex-column">
      <section class="flex-column gap-half">
        <Typography variant="h3" textWeight="400">Are you currently associated with any companies?</Typography>
        <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
            Use the box below to search for the company - or skip if this isn’t applicable.
        </Typography>
      </section>
      <CompanyAssociationForm v-if="screenType === DEFAULT_SCREEN"/>
      <AssociatedCompanyList v-if="screenType === ASSOCIATION_LIST_SCREEN"/>
      <div class="certificate-component__footer flex-row ai-c gap-half" v-if="screenType === DEFAULT_SCREEN">
        <Typography
          class="cursor-pointer"
          variant="p"
          textColor="rgba(12, 15, 74, 0.5)"
          textWeight="500">
          Not a UK Company?
        </Typography>
        <div class="flex-row ai-c gap-half cursor-pointer" @click="setScreen(ASSOCIATION_LIST_SCREEN)">
          <Typography
            class="cursor-pointer"
            variant="p"
            whiteSpace="nowrap"
            textColor="#FAA500"
            textWeight="500">
            Enter Manually
          </Typography>
          <el-icon :size="14" color="#FAA500"><ArrowRightBold/></el-icon>
        </div>
      </div>
    </el-container>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';

import Typography from '@/core/components/ui/Typography.vue';
import AssociatedCompanyList from '@/modules/account-setup/components/AssociatedCompanyList.vue';
import CompanyAssociationForm from '@/modules/account-setup/forms/CompanyAssociationForm.vue';

const DEFAULT_SCREEN = 'default';
const ASSOCIATION_LIST_SCREEN = 'association-list';

export default defineComponent({
  components: {
    Typography,
    AssociatedCompanyList,
    CompanyAssociationForm,
    ArrowRightBold,
  },

  emits: ['next'],

  data() {
    return {
      showForm: false,
      addedAssociations: [],
      submitting: false,
      screenType: DEFAULT_SCREEN,
      ASSOCIATION_LIST_SCREEN,
      DEFAULT_SCREEN
    };
  },

  computed: {
  },

  async created() {
  },

  methods: {
    setScreen(screen) {
      this.screenType = screen;
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
