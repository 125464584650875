import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CancelPaymentModal from '@/core/components/common/modals/CancelPaymentModal.vue';
import EwalletConfirmInterestModal from '@/core/components/common/modals/EwalletConfirmInterestModal.vue';
import EwalletThankYouModal from '@/core/components/common/modals/EwalletThankYouModal.vue';
import PaymentSuccessfulModal from '@/core/components/common/modals/PaymentSuccessfulModal.vue';
import ProjectAttachmentsModal from '@/core/components/common/modals/ProjectAttachmentsModal.vue';
import ProjectBudgetModal from '@/core/components/common/modals/ProjectBudgetModal.vue';
import ProjectDescriptionModal from '@/core/components/common/modals/ProjectDescriptionModal.vue';
import ProjectManageMembersModal from '@/core/components/common/modals/ProjectManageMembersModal.vue';
import UnverifiedAccountModal from '@/core/components/common/modals/UnverifiedAccountModal.vue';
import UserAddNewSkillRedirectModal from '@/core/components/common/modals/UserAddNewSkillRedirectModal.vue';
import UserBuildProfileModal from '@/core/components/common/modals/UserBuildProfileModal.vue';
import UserReferAndEarnModal from '@/core/components/common/modals/UserReferAndEarnModal.vue';
import WorkstationBillingModal from '@/core/components/common/modals/WorkstationBillingModal.vue';
import WorkstationChooseModal from '@/core/components/common/modals/WorkstationChooseModal.vue';
import WorkstationCompanyModal from '@/core/components/common/modals/WorkstationCompanyModal.vue';
import WorkstationInviteModal from '@/core/components/common/modals/WorkstationInviteModal.vue';
import WorkstationPaymentConfirmationModal from '@/core/components/common/modals/WorkstationPaymentConfirmationModal.vue';
// import WorkstationPlanModal from '@/core/components/common/modals/WorkstationPlanModal.vue';
import WorkstationProfileModal from '@/core/components/common/modals/WorkstationProfileModal.vue';
import FooterMenu from '@/core/components/layouts/FooterMenu.vue';
import Header from '@/core/components/layouts/Header.vue';
// import RightSidebarLogComponent from '@/core/components/layouts/RightSidebarLogComponent.vue';
import SecondaryHeader from '@/core/components/layouts/SecondaryHeader.vue';
import Sidebar from '@/core/components/layouts/Sidebar.vue';
import NotificationDrawer from '@/core/components/notifications/NotificationDrawer.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { AUTH_TOKEN, AUTH_USER_NAV_COLLAPSE, PUSHER_API_KEY, PUSHER_CLUSTER } from '@/core/constants';
import { BusinessDetailTypeEnum, UserVerificationStatusEnum } from '@/core/packages/shared-library';
import $axios from '@/core/utils/axios-api-config';
import { getLocalStorageWithExpiry } from '@/core/utils/common';
import PusherPlugin from '@/core/utils/pusher';
// eslint-disable-next-line import/no-cycle
import { appInstance } from '@/main';
import AccountSetupWizardDialog from '@/modules/account-setup/dialog/AccountSetupWizardDialog.vue';
import CompanyTypeDialog from '@/modules/account-setup/dialog/CompanyTypeDialog.vue';
import LimitedCompanyWizardDialog from '@/modules/account-setup/dialog/LimitedCompanyWizardDialog.vue';
import SoleTraderWizardDialog from '@/modules/account-setup/dialog/SoleTraderWizardDialog.vue';
import { CREATE_PROPERTY_MAP_ROUTE } from '@/modules/create-property-map/routes';
import ProjectEscrowSidebarComponent from '@/modules/project-details-children/components/escrowSidebar/ProjectEscrowSidebarComponent.vue';
// import MoneyInDrawer from '@/modules/e-wallet/components/MoneyInDrawer.vue';
import RightSidebarWalletComponent from '@/modules/property-details-children/components/RightSidebarWalletComponent.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { MESSAGES_STORE } from '@/store/modules/messages';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'main-layout',
    components: {
        Loader,
        // RightSidebarLogComponent,
        RightSidebarWalletComponent,
        Sidebar,
        Header,
        NotificationDrawer,
        UserReferAndEarnModal,
        UserBuildProfileModal,
        UnverifiedAccountModal,
        UserAddNewSkillRedirectModal,
        FooterMenu,
        EwalletConfirmInterestModal,
        EwalletThankYouModal,
        WorkstationChooseModal,
        WorkstationCompanyModal,
        // WorkstationPlanModal,
        WorkstationInviteModal,
        WorkstationProfileModal,
        ProjectDescriptionModal,
        WorkstationPaymentConfirmationModal,
        ProjectManageMembersModal,
        ProjectAttachmentsModal,
        ProjectBudgetModal,
        AccountSetupWizardDialog,
        PaymentSuccessfulModal,
        // MoneyInDrawer
        WorkstationBillingModal,
        CancelPaymentModal,
        ProjectEscrowSidebarComponent,
        SecondaryHeader,
        CompanyTypeDialog,
        SoleTraderWizardDialog,
        LimitedCompanyWizardDialog
    },
    data() {
        return {
            CREATE_PROPERTY_MAP_ROUTE,
            isModalShow: false,
            initializing: false,
            pagesWithoutScroll: [
                'project-overview',
                'property-details',
                'messages',
                'select-property',
                'account-settings',
                'project-search',
                'property-listing',
                'projects',
                'page-profile',
                'billing',
                'e-wallet',
                'estimates',
                'insurance',
                'post-project',
                'dashboard',
                'chat',
                'estimate-schedule',
            ],
            pagesWithoutHeader: [
                'project-overview',
                'project-estimate',
                'project-schedule',
                'messages',
                'page-profile',
            ],
            pagesWithoutActivityLog: [
                'property-details-documents',
                'property-details-members',
                'property-details-wallet',
                'messages',
                'property-details-projects',
                'project-estimate',
                'project-schedule',
                'e-wallet',
                'post-project',
                'select-property',
                'account-settings',
                'project-search',
                'property-listing',
                'projects',
                'members',
                'attachments',
                'chat',
                'details',
                'page-profile',
                'billing',
                'documents',
                'estimates',
                'estimate-form',
                'estimate-schedule',
                'project-jobs',
                'insurance',
                'properties',
                'opportunity-courses',
                'opportunity-billing',
                'my-jobs',
                'find-jobs',
                'calendar',
                'opportunity-select-spot',
                'opportunity-details',
                'dashboard',
                'cohorts',
                'find-property',
                'property-details-home',
                'post-project-overview',
                'property-details-kyp',
                'estimate',
                'schedule',
                'manage-members',
                'clients',
                'templates',
                'template-edit',
                'jobs',
                'trade-passport-overview',
                'trade-passport-specialism',
                'trade-passport-certifications',
                'trade-passport-companies',
                'trade-passport-insurances',
                'trade-passport-projects',
                'trade-passport-reviews',
                'trade-passport-gallery',
                'trade-passport-recommendations',
                'workstation-setup',
                'locked-page',
                'courses-opportunities',
                'course-details',
                'courses-enrolled',
                'project-dashboard',
                'new-project'
            ],
            pagesWithWalletSidebar: [
                'property-details-wallet',
                'e-wallet',
            ],
            pagesWithProjectEscrowSidebar: [
                'overview',
            ],
            pagesWithMarginTop50: [
                'messages',
                'project-schedule',
            ],
            pageWithSubPages: [
                'profile'
            ],
            showBusinessTypeOnly: false
        };
    },
    created() {
        this.setUserBuildProfileModal(false);
        this.initialize();
        this.initVuePusherNotification();
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isNavCollapse', 'openUserReferAndEarnModal', 'openUserBuildProfileModal',
            'openUnverifiedAccountModal', 'openAddNewSkillRedirectModal', 'openWorkstationChooseModal', 'openWorkstationCompanyModal',
            'openWorkstationPlanModal', 'openWorkstationInviteModal', 'openWorkstationProfileModal', 'openEwalletConfirmInterestModal', 'openEwalletThankYouModal',
            'openProjectDescriptionModal', 'openProjectManageMembersModal', 'openProjectAttachmentsModal', 'openProjectBudgetModal', 'openAccountSetupModal',
            'openWorkstationPaymentConfirmationModal', 'openPaymentSuccessfulModal', 'openPaymentUnsuccesfulModal', 'openPassportPreviewModal', 'workstationSetupModal',
            'soleTraderSetupModal', 'limitedCompanySetupModal']),
        ...mapGetters(USERS_STORE, ['user', 'isHosted', 'notInMainLayout', 'isOnPassportOverview']),
        ...mapGetters(AUTHENTICATION_STORE, ['isAuthenticated', 'authenticatedUser']),
        ...mapGetters(PROJECTS_STORE, ['enableProjectEscrowSidebar']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        ...mapGetters(PROPERTY_STORE, ['getIsMapView']),
        sessionIsNavCollapse() {
            const defaultState = this.isNavCollapse;
            const sessionState = JSON.parse(window.localStorage.getItem(AUTH_USER_NAV_COLLAPSE) || '{}');
            return typeof sessionState.isNavCollapse !== 'undefined' ? sessionState.isNavCollapse : defaultState;
        },
        currentRoute() {
            const route = this.$route?.meta?.showBackButton ? this.$route?.meta?.previousRoute?.meta?.title : this.$route?.meta?.title;
            return route;
        },
        activeMenuRoute() {
            const route = this.$route;
            return route;
        },
        useSecondaryHeader() {
            const route = this.$route;
            return route.meta?.useSecondaryHeader;
        },
        hasNoHeader() {
            const route = this.$route;
            return route.meta?.noHeader;
        },
        pageClassName() {
            const { activeMenuRoute, pageWithSubPages } = this;
            let name = activeMenuRoute.name.replace('/', '-');
            for (let i = 0; i < pageWithSubPages.length; i++) {
                if (name.indexOf(`${pageWithSubPages[i]}-`) > -1) {
                    name = `${name} page-${pageWithSubPages[i]}`;
                }
            }
            return name;
        },
        showBackButton() {
            return this.$route?.meta?.showBackButton === true;
        }
    },
    beforeUnmount() {
        this.setMainLayoutInitialized(false);
    },
    methods: {
        ...mapActions(MESSAGES_STORE, ['getUnreadMessagesByUser']),
        ...mapActions(USERS_STORE, [
            'initializeUserState',
            'setMainLayoutInitialized',
            'saveBusinessDetails',
            'saveUserProfile'
        ]),
        ...mapActions(USER_WORKSTATION, ['getActiveUserWorkstation', 'setUserWorkstations', 'clearActiveUserWorkstation']),
        ...mapActions(PROJECTS_STORE, [
            'setEnableProjectEscrowSidebar',
        ]),
        ...mapActions(['setUserReferAndEarnModal',
            'setUserBuildProfileModal', 'setUnverifiedAccountModal',
            'setAddNewSkillRedirectModal', 'setWorkstationChooseModal', 'setWorkstationCompanyModal', 'setWorkstationPlanModal', 'setWorkstationInviteModal', 'setWorkstationProfileModal', 'setEwalletConfirmInterestModal', 'setEwalletThankYouModal',
            'setProjectDescriptionModal', 'setProjectManageMembersModal', 'setProjectAttachmentsModal', 'setProjectBudgetModal', 'setAccountSetupModal',
            'setWorkstationPaymentConfirmationModal', 'setPaymentSuccessfulModal', 'setPassportPreviewModal', 'setWorkstationSetupModal',
            'setLimitedCompanySetupModal', 'setSoleTraderSetupModal']),
        /**
         * Initialize all necessary data for this app before loadign the content
         */
        async initialize() {
            this.initializing = true;
            this.setEnableProjectEscrowSidebar(false);
            await this.clearActiveUserWorkstation();
            await this.setUserWorkstations([]);
            await this.getActiveUserWorkstation();
            this.clearLocalStorage();
            if (this.isAuthenticated && !this.user) {
                await this.initializeUserState();
                // (this as any).initNotificationMessagesChannel();
            }
            const { hasAccountSetup } = this.user;
            // this.setAccountSetupModal(false);
            // if ((!hasAccountSetup && !this.isTradesperson)) {
            if ((!hasAccountSetup)) {
                // if (hasAccountSetup && !hasBusinessType) {
                //   this.showBusinessTypeOnly = !this.showBusinessTypeOnly;
                // }
                this.setAccountSetupModal(true);
                this.setPassportPreviewModal(false);
            }
            // if soletrader doesn't have business details yet, create one
            // this is temporary, we need a default to verify them
            if (!this.isHosted && this.isTradesperson && !this.user.userBusinessDetails) {
                this.onSaveBusinessDetail();
            }
            if (this.user?.userIdentification?.userVerificationStatus !== UserVerificationStatusEnum.VERIFIED) {
                this.onSaveIdentification();
            }
            if (!this.isHosted && !this.user?.userPublicProfile?.userVerificationStatus) {
                this.onSaveProfile();
            }
            // To emphasize the loader
            setTimeout(() => {
                this.initializing = false;
                this.setMainLayoutInitialized(true);
            }, 500);
        },
        async initNotificationMessagesChannel() {
            (this).getUnreadMessagesByUser({ fromUser: false });
        },
        async onSaveBusinessDetail() {
            const { user } = this;
            const data = {
                type: BusinessDetailTypeEnum.SOLETRADER,
                businessName: user.lastName,
                userVerificationStatus: UserVerificationStatusEnum.PENDING_VERIFICATION
            };
            await this.saveBusinessDetails({ userId: user.id, formData: data })
                .then(async () => {
                await this.initializeUserState();
            });
        },
        // forcing to create, tmp
        async onSaveIdentification() {
            const { user } = this;
            await $axios.post(`/users/${user.id}/identifications`);
        },
        async onSaveProfile() {
            const { user } = this;
            await this.saveUserProfile({
                userId: user.id,
            });
        },
        initVuePusherNotification() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            appInstance.use(PusherPlugin, {
                apiKey: PUSHER_API_KEY,
                options: {
                    cluster: PUSHER_CLUSTER,
                    authEndpoint: `${process.env.VUE_APP_API_URL}/user-notifications/authenticate`,
                    auth: { headers: { Authorization: `Bearer ${token}` } },
                }
            });
            this.$pusher.connection.bind('state_change', () => {
                // states = {previous: 'oldState', current: 'newState'}
                // console.log(states.current);
            });
        },
        isPageWithoutScroll(pageClassName) {
            const { pagesWithoutScroll } = this;
            return pagesWithoutScroll.includes(pageClassName);
        },
        isPageWithoutHeader(pageClassName) {
            const { pagesWithoutHeader } = this;
            return this.checkPageInclusion(pagesWithoutHeader, pageClassName);
        },
        isPageWithoutActivityLog(pageClassName) {
            const { pagesWithoutActivityLog } = this;
            return this.checkPageInclusion(pagesWithoutActivityLog, pageClassName);
        },
        isPagesWithWalletSidebar(pageClassName) {
            const { pagesWithWalletSidebar } = this;
            return this.checkPageInclusion(pagesWithWalletSidebar, pageClassName);
        },
        isPagesWithProjectEscrowSidebar(pageClassName) {
            const { pagesWithProjectEscrowSidebar } = this;
            return this.checkPageInclusion(pagesWithProjectEscrowSidebar, pageClassName);
        },
        isPagesWithMarginTop50(pageClassName) {
            const { pagesWithMarginTop50 } = this;
            return this.checkPageInclusion(pagesWithMarginTop50, pageClassName);
        },
        checkPageInclusion(attr, pageClassName) {
            const name = pageClassName.split(' ');
            let included = false;
            for (const item of name) {
                if (attr.includes(item)) {
                    included = true;
                    break;
                }
            }
            return included;
        },
        async onConfirmPayment() {
            this.setPaymentSuccessfulModal(false);
            this.$router.push(this.$route.path);
        },
        clearLocalStorage() {
            window.localStorage.removeItem('vw');
        }
    },
});
