import { defineComponent, ref, watch } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PROJECTS_STORE } from '@/store/modules/projects';
const __default__ = defineComponent({
    components: {},
    emits: ['category-change'],
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        cascaderHeight: {
            type: String,
            default: '350px'
        },
        removeShadow: {
            type: Boolean,
            default: false
        },
        subCat: {
            type: Array,
            default: () => []
        },
        mainCat: {
            type: Array,
            default: () => []
        },
    },
    setup() {
        const filterText = ref('');
        const treeRef = ref();
        const defaultProps = {
            children: 'children',
            label: 'label',
        };
        watch(filterText, (val) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            treeRef.value.filter(val);
        });
        const filterNode = (value, data) => {
            if (!value)
                return true;
            return data.label.toLowerCase().includes(value.toLowerCase());
        };
        return {
            filterText,
            treeRef,
            defaultProps,
            filterNode
        };
    },
    data() {
        return {
            showcascader: false,
            isCascadeBoxClicked: false,
            mouseOverContainer: false,
            isInputFocused: false,
        };
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, ['subCategories', 'mainCategories', 'toBePassedCategories']),
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['setSubCategories', 'setMainCategories', 'setToBePassedCategories']),
        setShowCascader(state) {
            this.showcascader = state;
        },
        onCascaderMouseOver() {
            this.isCascadeBoxClicked = true;
        },
        onCascaderMouseOut() {
            this.isCascadeBoxClicked = false;
            // eslint-disable-next-line dot-notation
            this.$refs['categoryInput'].focus();
        },
        onBlur(e) {
            setTimeout(() => {
                if (this.isCascadeBoxClicked) {
                    this.isCascadeBoxClicked = false;
                    e.preventDefault();
                    return;
                }
                this.setShowCascader(false);
            }, 100);
        },
        handleFocus() {
            this.isInputFocused = true;
            this.setShowCascader(true);
        },
        onSetblur() {
            this.isCascadeBoxClicked = true;
        },
        async check(node, data) {
            const { mainCategories } = this;
            this.showcascader = true;
            // Add all main categories into one array newMainCategoriesegories
            if (node.children || (data.halfCheckedNodes && data.halfCheckedNodes.length)) {
                const nodesToCheck = node.children ? [node] : data.halfCheckedNodes;
                nodesToCheck.forEach((element) => {
                    if (!mainCategories.includes(element.value)) {
                        mainCategories.push(element.value);
                    }
                });
            }
            // Add all sub categories into one array subCategories
            this.setSubCategories(data.checkedNodes
                .filter((item) => !item.children)
                .map((item) => item.value));
            await this.generateAddedCategories();
            this.$emit('category-change', this.toBePassedCategories);
        },
        generateAddedCategories() {
            this.setToBePassedCategories([]);
            this.mainCategories.forEach((main) => {
                const mainCategoryObj = this.categories.find((category) => category.value === main);
                if (mainCategoryObj && mainCategoryObj.children) {
                    mainCategoryObj.children.forEach((sub) => {
                        if (this.subCategories.includes(sub.value)) {
                            this.toBePassedCategories.push([main, sub.value]);
                        }
                    });
                }
            });
        },
    },
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "f2e94260": (_ctx.$props.cascaderHeight)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
