<template>
  <BaseModal :show="show" :showCancelBtn="false" :showSubmitBtn="false" :showDefaultCloseBtn="false" @cancel="cancel" :setManualWidth="true" :hasActions="false" :width="'30%'">
    <div class="content">
      <div class="modal-header">
        <div class="modal-header__left">
          <div class="modal-header__icon" @click="handleBack">
            <inline-svg :src="require(`@/assets/icons/arrow-left.svg`)"></inline-svg>
          </div>
          <h2 class="modal-header__title">Invite others</h2>
        </div>
        <div class="modal-header__icon">
          <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
        </div>
      </div>
      <div class="seat">
        <div class="seat__left">
          <p><span>8</span> people as max</p>
        </div>
        <div class="seat__right">
          <p>Buy more seats</p>
          <inline-svg :src="require(`@/assets/icons/arrow-right.svg`)"></inline-svg>
        </div>
      </div>
      <el-form class="form">
        <el-form-item>
          <div class="input-container">
            <el-input v-model="inputMember.email" type="email" placeholder="Email" />
            <div class="current-role" @click="toggleRole">
              <span>{{ inputMember.role || 'Viewer' }}</span>
              <inline-svg :src="require(`@/assets/icons/arrow-expand-more.svg`)"></inline-svg>
            </div>
            <div class="roles" v-if="isShowRoles">
              <div @click="handleChooseRole('Viewer')">
                <div>Viewer</div>
                <inline-svg v-if="inputMember.role === 'Viewer' || !inputMember.role" :src="require(`@/assets/icons/done.svg`)"></inline-svg>
              </div>
              <div @click="handleChooseRole('Editor')">
                <div>Editor</div>
                <inline-svg v-if="inputMember.role === 'Editor'" :src="require(`@/assets/icons/done.svg`)"></inline-svg>
              </div>
              <div @click="handleChooseRole('Previewer')">
                <div>Previewer</div>
                <inline-svg v-if="inputMember.role === 'Previewer'" :src="require(`@/assets/icons/done.svg`)"></inline-svg>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <span>Message (optional)</span>
          <el-input v-model="inputMember.message" :rows="8" :col="16" class="message-input" type="textarea" />
        </el-form-item>
        <div class="max-invitations" v-if="members.length === maxInvite">
          You have exceed the members seat you buy, click create will go to payment step
        </div>
        <div class="members">
          <div class="members__item" v-for="(member, idx) in members" :key="idx">
            <div class="members__item__email">{{member.email}}</div>
            <div class="members__item__role">
              <div>{{member.role}}</div>
              <inline-svg :src="require(`@/assets/icons/arrow-expand-more.svg`)"></inline-svg>
            </div>
          </div>
        </div>
        <el-form-item>
          <el-button class="btn" @click="sendInvite">Create</el-button>
          <el-button class="btn" @click="nextStep">Skip</el-button>
        </el-form-item>
      </el-form>
    </div>
  </BaseModal>
</template>
<script>
import { mapActions } from 'vuex';

import BaseModal from '@/core/components/ui/BaseModal.vue';

export default {
  name: 'workstation-create-company-modal',
  emits: ['close'],
  components: {
    BaseModal
  },
  props: ['show'],
  data: () => {
    return {
      inputMember: {
        email: '',
        message: '',
        role: 'Viewer'
      },
      members: [],
      isShowRoles: false,
      maxInvite: 8
    };
  },
  computed: {},
  methods: {
    ...mapActions(['setWorkstationInviteModal', 'setWorkstationProfileModal', 'setWorkstationPlanModal']),
    nextStep() {
      this.setWorkstationInviteModal(false);
      this.setWorkstationProfileModal(true);
    },
    sendInvite() {
      if (this.members.length === this.maxInvite) return;
      const member = {
        email: this.inputMember.email,
        message: this.inputMember.message,
        role: this.inputMember.role
      };

      if (!member.email) return;

      this.members.push(member);
    },
    toggleRole() {
      this.isShowRoles = !this.isShowRoles;
    },
    handleChooseRole(value) {
      this.inputMember.role = value;
      this.toggleRole();
    },
    cancel() {
      this.$emit('close');
    },

    confirm() {
      this.$emit('close');
    },
    handleBack() {
      this.setWorkstationInviteModal(false);
      this.setWorkstationPlanModal(true);
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  position: relative;
  font-family: 'Mullish', Arial;
  color: #0c0f4a;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
    &__icon {
      cursor: pointer;
    }
    &__left {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .seat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__left {
      span {
        color: #faa100;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
    }
  }

  .form {
    margin-top: 15px;
    .input-container {
      position: relative;
      .current-role {
        position: absolute;
        right: 28px;
        top: 0;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .roles {
        position: absolute;
        background: #fff;
        padding: 15px;
        top: 4rem;
        right: 0;
        z-index: 10;
        width: 140px;
        box-shadow: 0px 4px 20px 0px #2a2a2a1a;
        div {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    :deep(.el-textarea__inner) {
      resize: none;
      width: 100%;
      border-radius: 8px;
    }

    .max-invitations {
      color: #FAA100;
      font-size: 12px;
    }

    .members {
      --scrollbar-width: 12px;
      --mask-height: 32px;
      padding-bottom: var(--mask-height);
      --mask-image-content: linear-gradient(to bottom, transparent, black var(--mask-height), black calc(100% - var(--mask-height)), transparent);
      --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
      --mask-image-scrollbar: linear-gradient(black, black);
      --mask-size-scrollbar: var(--scrollbar-width) 100%;
      mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
      mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
      mask-position: 0 0, 100% 0;
      mask-repeat: no-repeat, no-repeat;

      display: flex;
      position: relative;
      flex-direction: column;
      gap: 12px;
      max-height: 170px;
      overflow-y: auto;
      font-size: 12px;
      padding: 12px 0;
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__email {
          line-height: 24px;
          font-weight: 400;
        }
        &__role {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 600;
          line-height: 30px;
        }
      }
    }
  }

  .btn {
    padding: 0 31px;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1.25px;
    color: rgba(12, 15, 74, 0.5);
    background: rgba(12, 15, 74, 0.05);
    border-radius: 7px;
    border: 0;
    text-transform: uppercase;
    margin-top: 36px;
    &__active {
      @extend .btn;
      color: #fff;
      background: rgba(250, 161, 0, 1);
    }
  }

  :deep(.el-input__inner) {
    border: none;
    border-bottom: 2px solid rgba(12, 15, 74, 0.2);
    border-radius: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    &::placeholder {
      color: rgba(12, 15, 74, 0.5);
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
    }
  }
}
</style>
