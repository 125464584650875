<template>
    <div class="profile-picture-placeholder circular-bg relative cursor-pointer">
      <Icon
        v-if="isVerified"
        class="profile-picture-placeholder__verified-icon absolute"
        iconName="passport-verified.svg"
        customPath="icons/trade-passport-icons"/>
      <Icon iconName="upload-profile.svg" iconHeight="0.75rem" iconWidth="0.75rem"/>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Icon from '@/core/components/ui/Icon.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';

export default {
  name: 'ProfilePicturePlaceholder',

  components: {
    Icon
  },

  computed: {
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation', 'activeUserWorkstation']),

    userProfileState() {
      return this.activeUserWorkstation?.verificationStatus;
    },

    isVerified() {
      return this.userProfileState === UserVerificationStatusEnum.VERIFIED;
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-picture-placeholder {
  background-color: #E6EAF2 !important;
  padding: 1.2rem;
  box-shadow: none !important;

  &__verified-icon{
    top: -1.2rem;
    right: -.8rem;
  }
}
</style>
