import { defineComponent } from 'vue';
import Button from '@/core/components/ui/Button.vue';
import ImageUploadForm from '@/modules/project-dashboard-children/components/ImageUploadForm.vue';
import UploadedFilesPreview from '@/modules/project-dashboard-children/components/UploadedFilesPreview.vue';
export default defineComponent({
    components: {
        Button,
        UploadedFilesPreview,
        ImageUploadForm
    },
});
