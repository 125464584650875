import { defineComponent } from '@vue/runtime-core';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'user-build-profile-modal',
    emits: ['close'],
    components: {
        BaseModal,
    },
    props: {
        show: Boolean,
    },
    methods: {
        cancel() {
            this.$emit('close');
        },
        submit() {
            this.$router.push('/account/tradesperson');
            this.$emit('close');
        }
    }
});
