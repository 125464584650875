<template>
    <div class="profile-avatar">
        <div class="profile-avatar__alias-thumbnail flex-row ai-c jc-c" v-if="!img || img === ''">
          <Typography class="profile-avatar__alias" variant="p" textColor="#FFF" textWeight="500">{{ getFirstLetters(name) }}</Typography>
        </div>
        <UserThumbnail
            v-else
            borderRadius="100%"
            :width="imgSize"
            :height="imgSize"
            :source="img"
        ></UserThumbnail>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Typography from '@/core/components/ui/Typography.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

export default defineComponent({
  components: {
    Typography,
    UserThumbnail
  },

  props: {
    img: String,
    name: String,
    imgSize: {
      type: String,
      default: '38px'
    }
  },

  methods: {
    getFirstLetters(str) {
      const firstLetters = str
        .split(' ')
        .map((word) => word[0])
        .join('')
        .slice(0, 2);

      return firstLetters;
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/variables/layout" as *;

  .profile-avatar {
    &__alias-thumbnail {
      min-width: v-bind('$props.imgSize');
      min-height: v-bind('$props.imgSize');
      max-width: v-bind('$props.imgSize');
      max-height: v-bind('$props.imgSize');
      background: #FFA500;
      border-radius: 100px;
    }
  }

  @include media-sm-max-width() {
    .profile-avatar {
      &__alias-thumbnail {
        min-width: 20px !important;
        min-height: 20px !important;
        max-width: 20px !important;
        max-height: 20px !important;
      }

      &__alias {
        font-size: 0.60rem !important;
      }
    }
  }
</style>
