import CharacterCount from '@tiptap/extension-character-count';
import TextAlign from '@tiptap/extension-text-align';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent } from '@tiptap/vue-3';
import { defineComponent } from 'vue';
import Icon from './Icon.vue';
export default defineComponent({
    components: {
        EditorContent,
        Icon
    },
    data() {
        return {
            editor: new Editor({
                editorProps: {
                    attributes: {
                        class: 'text-editor__content',
                    },
                },
                content: '<p>Description here</p>',
                extensions: [
                    StarterKit,
                    TextAlign.configure({
                        types: ['paragraph'],
                    }),
                    CharacterCount.configure({
                        mode: 'nodeSize',
                        limit: 250,
                    })
                ],
            }),
        };
    },
});
