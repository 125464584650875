<template>
  <div class="association-form flex-column gap-2">
    <el-form :model="form" class="default-form" label-position="top">
      <el-form-item label="Company Name">
        <el-input v-model="searchKey" placeholder="e.g Plumbing & Heating"></el-input>
      </el-form-item>
    </el-form>
    <Button :isActive="isFormReady" buttonText="find company" @handle-click="addToAssociationList"/>
    <!-- <Typography
      v-if="userCertificates.length"
      @click="backToList"
      class="cursor-pointer"
      variant="p"
      textColor="#FAA500"
      textWeight="500">
      Cancel
    </Typography> -->
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
// import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Button,
    // Typography
  },

  emits: ['cancel'],

  data() {
    return {
      isFormReady: false,
      selectedValue: null,

      searchKey: ''
    };
  },

  computed: {
  },

  watch: {
  },

  methods: {
    backToList() {
      this.$emit('cancel');
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.association-form {
  width: 50%;
}
:deep(.el-form-item) {
  margin-bottom: 0;
}
:deep(.el-input) {
  height: 42px;
  width: 100%;

  .el-input__inner {
    background: rgba(12, 15, 74, 0.05);
    border-radius: 100px;
    outline:none;
    border: none;
    padding: 20px 20px 20px 20px;
  }
}
.el-select {
  width: 100%;
}

@include media-sm-max-width() {
  .association-form {
    width: 100%;
  }
}
</style>
