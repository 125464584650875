import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import SidebarHeader from '@/core/components/layouts/SidebarHeader.vue';
import { BusinessDetailTypeEnum, RolesEnum, UserVerificationStatusEnum } from '@/core/packages/shared-library';
import AccountSetupDialog from '@/modules/account-setup/dialog/AccountSetupDialog.vue';
import { CALENDAR_ROUTE } from '@/modules/calendar/routes';
import { DOCUMENTS_ROUTE } from '@/modules/documents/routes';
import { E_WALLET_ROUTE } from '@/modules/e-wallet/routes';
import { MANAGE_JOB_ROUTE } from '@/modules/manage-job/routes';
import { MEMBERS_ROUTE } from '@/modules/members/routes';
import { MESSAGES_ROUTE } from '@/modules/messages/routes';
import { OPPORTUNITY_ROUTE } from '@/modules/opportunity/routes';
import { PROJECT_JOBS_ROUTE } from '@/modules/project-jobs/routes';
import { PROJECT_SEARCH_ROUTE } from '@/modules/project-search/routes';
import { PROJECTS_ROUTE } from '@/modules/projects/routes';
import { PROPERTY_LISTING } from '@/modules/property-listing/routes';
import { SELECT_PROPERTY_ROUTE } from '@/modules/select-property/routes';
import { TRADE_PASSPORT_ROUTE } from '@/modules/trade-passport/routes';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USER_WORKSTATION_MEMBERS_STORE } from '@/store/modules/user-workstation-member';
import { USERS_STORE } from '@/store/modules/users';
import { LOGIN_ROUTE } from '../../../modules/login/routes';
import { MESSAGES_STORE } from '../../../store/modules/messages';
import { AUTH_USER_NAV_COLLAPSE } from '../../constants';
import SidebarSkeleton from './skeleton/SidebarSkeleton.vue';
const DEFAULT_WORKSTATION = 'Default';
export default defineComponent({
    name: 'sidebar',
    components: {
        SidebarHeader,
        AccountSetupDialog,
        SidebarSkeleton
    },
    props: {
        user: Object,
    },
    data() {
        return {
            initializing: false,
            RolesEnum,
            windowWidth: window.innerWidth,
            canAddWorkstation: false,
            BusinessDetailTypeEnum,
            availableWorkstations: [],
            modifiedAvailableUserWorkstations: [],
            colors: [
                '#4A92E5',
                '#D84F50',
                '#8454DD',
                '#EB8C49',
                '#3E72E1',
                '#5DBA59'
            ],
            // randomColor: "#ff0000",
            // workStationType: '',
            // workStationBusinessName: '',
            // workStationSubscriptionData: null,
            openWorkstationModal: false,
            cohortsMenu: [
                {
                    label: 'Home',
                    icon: 'home.svg',
                    name: 'dashboard',
                    route: '/dashboard',
                    class: '',
                    authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
                    active: false
                },
                {
                    label: 'Cohort',
                    icon: 'home.svg',
                    name: 'cohorts',
                    route: '/cohorts',
                    class: '',
                    authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
                    active: false
                },
            ],
            toolTipContent: '',
            modifiedSidebarMenu: [],
            hostedWorkstations: [],
            modifiedHostedWorkstations: [],
            // force set account detail
            accountSubDisplay: 'inherit',
            DEFAULT_WORKSTATION,
            OPPORTUNITY_ROUTE,
            MEMBERS_ROUTE,
            MESSAGES_ROUTE
        };
    },
    mounted() {
        this.initializeSideNavState();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    watch: {
        windowWidth(newWidth) {
            this.windowWidth = newWidth;
        },
        accountStatuses: {
            immediate: true,
            deep: true,
            handler() {
                this.canAddWorkstation = this.canUserAddWorkstation;
            }
        },
        'activeUserWorkstation.verificationStatus': {
            immediate: true,
            deep: true,
            handler() {
                this.modifySidebarMenu();
            },
        },
        userWorkstations: {
            immediate: true,
            deep: true,
            handler(value) {
                this.userWorkstations = value;
                this.availableWorkstations = value;
                this.modifiedUserWorkStations();
            }
        },
        openWorkstationModal: {
            immediate: true,
            handler(value) {
                this.openWorkstationModal = value;
            }
        },
    },
    computed: {
        ...mapGetters(['isNavCollapse', 'sidebarMenu', 'isNavCollapse', 'isTradesperson', 'isProjectOwner', 'openAccountSetupModal']),
        ...mapGetters(USERS_STORE, ['user', 'canUserAddWorkstation', 'isCohort', 'hasMainLayoutInitialized', 'isHosted', 'wsRole', 'isWsAdmin']),
        ...mapGetters(MESSAGES_STORE, ['totalNewMessages']),
        ...mapGetters(AUTHENTICATION_STORE, ['isAuthenticated', 'authenticatedUser']),
        ...mapGetters(USER_WORKSTATION, ['userWorkstations', 'activeUserWorkstation']),
        sessionIsNavCollapse() {
            const defaultState = this.isNavCollapse;
            const sessionState = JSON.parse(window.localStorage.getItem(AUTH_USER_NAV_COLLAPSE) || '{}');
            return typeof sessionState.isNavCollapse !== 'undefined' ? sessionState.isNavCollapse : defaultState;
        },
        currentRoute() {
            const route = this.$route;
            return route;
        },
        activeMenuItem() {
            // logic to determine the active menu item
            for (const menu of this.modifiedSidebarMenu) {
                if (!menu.restricted && this.currentRoute.name === menu.name) {
                    return this.currentRoute.name;
                }
            }
            return null;
        },
    },
    async created() {
        // check
        await this.initialize();
    },
    methods: {
        ...mapActions(['setNavCollapseState', 'setWorkstationChooseModal', 'setAccountSetupModal', 'setWorkstationSetupModal']),
        ...mapActions(USERS_STORE, [
            'initializeUserState',
            'setUser',
            'setIsHosted',
            'setWsRole',
            'setInvitedUser',
            'getAccountStatuses'
        ]),
        ...mapActions(AUTHENTICATION_STORE, ['logout']),
        ...mapActions(USER_WORKSTATION, [
            'getActiveUserWorkstation',
            'getUserWorkstations',
            'setActiveUserWorkstation',
            'activateWorkstation',
            'activateHostedWorkstation',
            'setModifiedactiveUserWorkstation'
        ]),
        ...mapActions(USER_WORKSTATION_MEMBERS_STORE, [
            'getMyUserWorkstationMembers',
            'activateUserWorkstationMember'
        ]),
        async initialize(owner = true) {
            this.initializing = true;
            const { userId } = this.authenticatedUser;
            if (!owner) {
                await this.setUser(this.activeUserWorkstation.user.id);
                this.accountSubDisplay = 'none';
            }
            else {
                window.localStorage.removeItem('hst');
                this.setIsHosted(false);
                await this.initializeUserState();
                this.accountSubDisplay = 'inherit';
            }
            await this.getUserWorkstations(userId);
            // load my hosts workstations
            await this.getMyUserWorkstationMembers(userId).then((response) => {
                this.hostedWorkstations = response;
                // add colors and other props needed
                this.modifyHostedWorkstations();
            });
            this.modifiedSidebarMenu = this.sidebarMenu;
            this.modifySidebarMenu();
            if (this.isTradesperson) {
                this.toolTipContent = 'You need to complete your profile setup first, before you can access this area.';
            }
            if (this.isProjectOwner) {
                this.toolTipContent = 'You need to complete your initial account setup, before you can access this area.';
            }
            this.initializing = false;
        },
        modifySidebarMenu() {
            const { hasAccountSetup } = this.user;
            // THIS ONE CHECKS IF THE ACTIVE TP WORKSTATIONS IS VERIFIED
            if (this.isTradesperson && (this.activeUserWorkstation.verificationStatus !== UserVerificationStatusEnum.VERIFIED)) {
                this.modifiedSidebarMenu = this.sidebarMenu.map((menu) => {
                    if (menu.name === OPPORTUNITY_ROUTE
                        || menu.name === PROJECT_SEARCH_ROUTE
                        || menu.name === PROJECT_JOBS_ROUTE
                        || menu.name === DOCUMENTS_ROUTE
                        || menu.name === MANAGE_JOB_ROUTE
                        || menu.name === MESSAGES_ROUTE
                        || menu.name === MEMBERS_ROUTE) {
                        return {
                            ...menu,
                            restricted: true
                        };
                    }
                    return menu;
                });
            }
            // THIS ONE CHECKS IF THE ACTIVE PO WORKSTATION HAS SETUP THE INITIAL ACCOUNT SETUP
            if (!hasAccountSetup && this.isProjectOwner) {
                this.modifiedSidebarMenu = this.sidebarMenu.map((menu) => {
                    if (menu.name === SELECT_PROPERTY_ROUTE
                        || menu.name === PROPERTY_LISTING
                        || menu.name === DOCUMENTS_ROUTE
                        || menu.name === PROJECTS_ROUTE
                        || menu.name === MEMBERS_ROUTE) {
                        return {
                            ...menu,
                            restricted: true
                        };
                    }
                    return menu;
                });
            }
            // if it's hosted, still to finalize
            const allowed = [
                MESSAGES_ROUTE,
                OPPORTUNITY_ROUTE,
                CALENDAR_ROUTE,
                PROJECTS_ROUTE,
                E_WALLET_ROUTE,
                MEMBERS_ROUTE,
                SELECT_PROPERTY_ROUTE,
                E_WALLET_ROUTE,
                TRADE_PASSPORT_ROUTE
            ];
            const restrictionList = [];
            if (this.isHosted) {
                if (!this.isWsAdmin) {
                    restrictionList.push(SELECT_PROPERTY_ROUTE);
                    restrictionList.push(E_WALLET_ROUTE);
                }
                this.modifiedSidebarMenu = this.sidebarMenu.map((menu) => {
                    if (allowed.includes(menu.name)) {
                        return {
                            ...menu,
                            // restricted: false,
                            active: !restrictionList.includes(menu.name),
                        };
                    }
                    return menu;
                });
            }
        },
        isCurrentRouteActive(menuName, menuRestricted) {
            return this.currentRoute.name === menuName && !menuRestricted;
        },
        hideMenu(menuName, menuTobeHide, isDefaultWorkstation) {
            if (isDefaultWorkstation) {
                return menuName === menuTobeHide && this.activeUserWorkstation.name === DEFAULT_WORKSTATION;
            }
            return menuName === menuTobeHide && this.activeUserWorkstation.name !== DEFAULT_WORKSTATION;
        },
        isMessagesDotPulsatingWrong(menuName) {
            return !this.totalNewMessages && menuName === MESSAGES_ROUTE;
        },
        initializeSideNavState() {
            const sidebarWidth = this.getSidebarWidth();
            if (sidebarWidth > 300) {
                this.setNavCollapseState(false);
            }
            if (sidebarWidth < 300) {
                this.setNavCollapseState(true);
            }
        },
        toggleSideNav(e) {
            const sidebarWidth = this.getSidebarWidth();
            if (e.index && sidebarWidth && sidebarWidth > 300) {
                this.setNavCollapseState(!this.isNavCollapse);
            }
            if (!e.index) {
                this.setNavCollapseState(!this.isNavCollapse);
            }
        },
        getSidebarWidth() {
            const { $refs } = this;
            let width = 0;
            if ($refs.sidebar) {
                const { sidebar } = $refs;
                const sidebarWidth = sidebar.offsetWidth;
                width = sidebarWidth;
            }
            return width;
        },
        onLogout() {
            this.logout();
            this.$router.push({ name: LOGIN_ROUTE });
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        toggleNavOnMobile(index) {
            if (this.windowWidth <= 991) {
                this.toggleSideNav(index);
            }
            return true;
        },
        onCreateWorkstation() {
            // this.setAccountSetupModal(true);
            // this.openWorkstationModal = true;
            // this.setWorkstationChooseModal(true);
            this.setWorkstationSetupModal(true);
        },
        onCloseWorkstationModal() {
            this.openWorkstationModal = false;
        },
        async onSelectUserWorkstation(userWorkstation) {
            const { userId } = this.authenticatedUser;
            const { activeUserWorkstation } = this;
            if (activeUserWorkstation?.id === userWorkstation.id) {
                return;
            }
            await this.activateWorkstation({
                userId,
                userWorkstationId: userWorkstation.id
            });
            this.setIsHosted(false);
            this.setWsRole(null);
            await this.initialize();
            await this.getAccountStatuses();
            this.$router.push({ name: 'dashboard' });
        },
        async onSelectHostedUserWorkstation(userWorkstation) {
            const { user } = this;
            const { activeUserWorkstation } = this;
            if (activeUserWorkstation?.id === userWorkstation.id) {
                return;
            }
            window.localStorage.setItem('hst', `${userWorkstation.id}`);
            await this.activateHostedWorkstation(userWorkstation);
            await this.activateUserWorkstationMember({
                userId: user.id,
                id: userWorkstation.memberId
            }).then((response) => {
                this.setWsRole(response.role);
            }).catch(() => { });
            this.setIsHosted(true);
            this.setInvitedUser(user);
            await this.initialize(false);
            await this.getAccountStatuses();
            this.$router.push({ name: 'dashboard' });
        },
        getFirstLetters(str) {
            const firstLetters = str
                .split(' ')
                .map((word) => word[0])
                .join('');
            return firstLetters;
        },
        // this needs to be transferred in the service
        modifiedUserWorkStations() {
            if (this.availableWorkstations?.length) {
                const { activeUserWorkstation } = this;
                let matchedActive = null;
                this.modifiedAvailableUserWorkstations = [];
                this.availableWorkstations.forEach((i, index) => {
                    index %= 6; // eslint-disable-line no-param-reassign
                    i.color = this.colors[index]; // eslint-disable-line no-param-reassign
                });
                this.modifiedAvailableUserWorkstations = this.availableWorkstations.map((workstation) => {
                    const nameConstant = 'Default';
                    let defaultAlias = nameConstant;
                    if (workstation.name === nameConstant) {
                        defaultAlias = workstation.user.firstName[0].concat(workstation.user.lastName[0]);
                    }
                    const newValue = {
                        ...workstation,
                        alias: defaultAlias !== nameConstant ? defaultAlias : this.getFirstLetters(workstation.name),
                    };
                    if (activeUserWorkstation.id === workstation.id) {
                        matchedActive = newValue;
                    }
                    return newValue;
                });
                if (matchedActive) {
                    this.setModifiedactiveUserWorkstation(matchedActive);
                }
            }
        },
        // this needs to be transferred in the service
        modifyHostedWorkstations() {
            if (this.hostedWorkstations?.length) {
                this.modifiedHostedWorkstations = [];
                this.hostedWorkstations.forEach((i, index) => {
                    index %= 6; // eslint-disable-line no-param-reassign
                    i.color = this.colors[index]; // eslint-disable-line no-param-reassign
                });
                // get all invited but filter which role I'm in
                this.modifiedHostedWorkstations = this.hostedWorkstations
                    .filter((item) => item.user.role.id === this.user.role.id).map((host) => {
                    const { userWorkstation: workstation, user, color, id: memberId } = host;
                    const nameConstant = 'Default';
                    let defaultAlias = nameConstant;
                    if (workstation.name === nameConstant) {
                        defaultAlias = user.firstName[0].concat(user.lastName[0]);
                    }
                    const newValue = {
                        ...workstation,
                        color,
                        memberId,
                        user,
                        alias: defaultAlias !== nameConstant ? defaultAlias : this.getFirstLetters(workstation.name),
                    };
                    return newValue;
                });
            }
        },
    }
});
