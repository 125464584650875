import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
// ONBOARDING
import AssociatedCompany from '@/modules/account-setup/on-boarding/AssociatedCompany.vue';
import Certificate from '@/modules/account-setup/on-boarding/Certificate.vue';
import CompanyTypeSetup from '@/modules/account-setup/on-boarding/CompanyTypeSetup.vue';
import IdCheck from '@/modules/account-setup/on-boarding/IdCheck.vue';
import Insurance from '@/modules/account-setup/on-boarding/Insurance.vue';
import LimitedCompany from '@/modules/account-setup/on-boarding/LimitedCompany.vue';
import PhoneNumber from '@/modules/account-setup/on-boarding/PhoneNumber.vue';
import ProfilePhoto from '@/modules/account-setup/on-boarding/ProfilePhoto.vue';
import ResidentialAddress from '@/modules/account-setup/on-boarding/ResidentialAddress.vue';
import SoleTraderName from '@/modules/account-setup/on-boarding/SoleTraderName.vue';
import Specialism from '@/modules/account-setup/on-boarding/Specialism.vue';
import Subscription from '@/modules/account-setup/on-boarding/Subscription.vue';
import TermsAndConditions from '@/modules/account-setup/on-boarding/TermsAndConditions.vue';
import Tour from '@/modules/account-setup/on-boarding/Tour.vue';
import JobSpecifics from '@/modules/project-dashboard-children/components/tabs/JobSpecifics.vue';
// CREATE PROJECT
import ProjectAttachments from '@/modules/project-dashboard-children/components/tabs/ProjectAttachments.vue';
import ProjectType from '@/modules/project-dashboard-children/components/tabs/ProjectType.vue';
import SelectProperty from '@/modules/project-dashboard-children/components/tabs/SelectProperty.vue';
import Timeline from '@/modules/project-dashboard-children/components/tabs/Timeline.vue';
export default defineComponent({
    name: 'wizard-form',
    emits: ['set-active-form', 'on-complete'],
    props: ['config'],
    components: {
        Certificate,
        Tour,
        Specialism,
        ArrowRightBold,
        ArrowLeftBold,
        ProfilePhoto,
        ResidentialAddress,
        PhoneNumber,
        AssociatedCompany,
        CompanyTypeSetup,
        Insurance,
        LimitedCompany,
        Subscription,
        IdCheck,
        TermsAndConditions,
        SoleTraderName,
        SelectProperty,
        ProjectType,
        JobSpecifics,
        Timeline,
        ProjectAttachments
    },
    data() {
        return {
            activeIndex: 0,
            componentHidden: ''
        };
    },
    computed: {
        activeForm() {
            const { config } = this;
            let activeForm = config.find((form) => form.isActive);
            if (!activeForm) {
                const [firstForm] = config;
                if (firstForm) {
                    firstForm.isActive = true;
                    firstForm.index = 1;
                    activeForm = firstForm;
                }
            }
            return activeForm;
        },
        hideArrow() {
            if (this.activeForm.hideArrow) {
                return this.activeForm.hideArrow;
            }
            return false;
        },
    },
    created() {
        this.$emit('set-active-form', this.activeForm);
        this.getActiveIndex();
    },
    methods: {
        getActiveIndex() {
            const { activeForm, config } = this;
            const activeIndex = config.findIndex((form) => form.name === activeForm.name);
            const len = config.length;
            this.activeIndex = activeIndex;
            return { len, activeIndex, config };
        },
        getPrevIndex(index, len) {
            return !index ? len - 1 : index - 1;
        },
        getNextIndex(index, len) {
            return index === len - 1 ? false : index + 1;
        },
        setForm(form) {
            this.activeForm.isActive = false;
            form.isActive = true;
            this.$emit('set-active-form', this.activeForm);
            this.getActiveIndex();
        },
        setPrevious() {
            const { len, activeIndex, config } = this.getActiveIndex();
            let prevIndex;
            if (activeIndex !== -1) {
                this.activeForm.isActive = false;
                prevIndex = this.getPrevIndex(activeIndex, len);
                if (prevIndex !== false) {
                    config[prevIndex].isActive = true;
                    config[prevIndex].index = prevIndex;
                }
            }
            this.$emit('set-active-form', this.activeForm);
            this.getActiveIndex();
        },
        setNext() {
            const { len, activeIndex, config } = this.getActiveIndex();
            let nextIndex;
            if (activeIndex !== -1) {
                this.activeForm.isActive = false;
                nextIndex = this.getNextIndex(activeIndex, len);
                if (nextIndex !== false) {
                    config[nextIndex].isActive = true;
                    config[nextIndex].index = nextIndex;
                }
            }
            this.$emit('set-active-form', this.activeForm);
            this.getActiveIndex();
        },
        onComplete() {
            this.$emit('on-complete');
        },
    }
});
