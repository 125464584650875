<template>
    <div class="workstation-dropdown-item flex-row ai-fs gap-half">
        <ProfileAvatar :name="name" :img="img"/>
        <div class="flex-column gap-0">
          <Typography variant="custom" textSize="13px" lineHeight="20px">{{ name }}</Typography>
          <Typography
            variant="custom"
            textSize="12px"
            textWeight="500"
            lineHeight="20px">
            {{ label }}
          </Typography>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import ProfileAvatar from '@/core/components/common/thumbnail/ProfileAvatar.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    ProfileAvatar
  },

  props: {
    img: String,
    name: String,
    label: String,
  }
});
</script>
<style lang="scss" scoped>
</style>
