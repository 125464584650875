import { defineComponent } from 'vue';
import Button from '@/core/components/ui/Button.vue';
import TextEditor from '@/core/components/ui/TextEditor.vue';
import TitleComponent from '../TitleComponent.vue';
export default defineComponent({
    components: {
        TextEditor,
        Button,
        TitleComponent
    },
    methods: {
        onValueChange(value) {
            console.log('🚀 ~ onValueChange ~ value:', value);
        }
    }
});
