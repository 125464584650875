<template>
  <SetupDialogSlot header="Company Setup">
    <template #content>
      <CompanyTypeSetup/>
    </template>
  </SetupDialogSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import SetupDialogSlot from '@/core/components/slots/SetupDialogSlot.vue';
import CompanyTypeSetup from '@/modules/account-setup/on-boarding/CompanyTypeSetup.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    CompanyTypeSetup,
    SetupDialogSlot
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(USERS_STORE, ['isOnPassportOverview'])
  },

  methods: {
    ...mapActions(USERS_STORE, ['setNotInMainLayout']),

    onComplete() {
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
