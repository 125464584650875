const FLEXIBLE = 'flexible';
const SCHEDULE = 'schedule';
const WEEKLY = 'weekly';
const FORTNIGHTLY = 'fortnightly';
const MONTHLY = 'monthly';
const YEARLY = 'yearly';
const CUSTOM = 'custom';
export default {
    FLEXIBLE,
    SCHEDULE,
    WEEKLY,
    FORTNIGHTLY,
    MONTHLY,
    YEARLY,
    CUSTOM
};
