<template>
  <el-container class="tour flex-column">
    <section class="tour__wrapper flex-column">
      <div class="tour__image-wrapper">
        <el-carousel :interval="10000" height="150px">
          <el-carousel-item v-for="(file, i) in attachments" :key="i">
            <el-image
              class="tour__attachment-image"
              fit="cover"
              :src="file.src"
              :preview-src-list="previewImages"
              :append-to-body="true"
              >
              <template v-slot:error>
                <div class="tour__image-slot flex-row ai-c jc-c">
                  <el-icon color="#FFF"><Picture /></el-icon>
                </div>
              </template>
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <typography variant="h6" textWeight="500" textColor="#264FD5">1/3</typography>
    </section>
    <section class="tour__wrapper flex-column ai-fs">
      <Typography variant="h3" textWeight="400">How iknowa benefits your trade</Typography>
      <Typography variant="h6" textWeight="500" textColor="rgba(12, 15, 74, 0.50)">
        Take a minute to familiarise yourself with the platform, and what<br>
        iknowa can do fo you and your trades business.
      </Typography>
    </section>
    <section class="tour__wrapper flex-column gap-half">
      <div class="flex-row ai-c" v-for="(benefit, index) in benefits" :key="index">
        <Icon iconName="blue-check-filled-icon.svg" iconHeight="32px" iconWidth="32px"/>
        <Typography variant="h6" textWeight="400">{{ benefit.label }}</Typography>
      </div>
    </section>
  </el-container>
</template>
<script>
// import { Picture } from '@element-plus/icons';
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Icon,
    Typography,
    // Picture
  },

  data() {
    return {
      benefits: [
        {
          label: 'Benefit one'
        },
        {
          label: 'Benefit two'
        },
        {
          label: 'Benefit three'
        },
      ],

      attachments: [],
      previewImages: []
    };
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.tour {
  &__image-wrapper {
    border-radius: 8px;
    background: #D9D9D9;
  }
  &__attachment-image {
    padding: 0 5px;
    max-width: 300px;
    max-height: 200px;
    width: 100%;
    height: 200px;
  }
}

@include media-sm-max-width() {
  .tour {
    gap: .5rem;
    &__image-wrapper {
      height: 105px;
    }
    &__wrapper {
      gap: .5rem;
    }
  }
}
</style>
