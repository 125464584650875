<template>
    <div class="uploaded-files-preview flex-column w-100 h-100">
      <div class="flex-column w-100 gap-half">
        <Typography variant="p">Uploaded Files</Typography>
        <el-divider class="w-100 m-0 p-0"/>
      </div>
      <el-scrollbar>
        <div class="uploaded-files-preview__scrollbar-content flex-column w-100 h-100">
          <div class="uploaded-files-preview__item flex-column w-100" v-for="item in 9" :key="item">
            <div class="uploaded-files-preview__image-wrapper">
              <img
                class="uploaded-files-preview__image h-100 w-100"
                :src="require(`@/assets/images/sample-image.jpg`)"
                alt="uploaded image">
            </div>
            <div class="flex-row ai-c jc-sb">
              <Typography variant="custom" textSize="12px">living_room.pdf</Typography>
              <Icon iconName="trash-icon.svg" iconHeight="16px" iconWidth="16px"/>
            </div>
            <div class="flex-row ai-c w-100">
              <el-select
                v-model="type"
                placeholder="Property"
                size="large">
                <el-option
                  v-for="item in typeOption" :key="item.value"
                  :label="item.label" :value="item.value"/>
              </el-select>
              <el-select
                v-model="type"
                placeholder="Category"
                size="large">
                <el-option
                  v-for="item in categoryOption" :key="item.value"
                  :label="item.label" :value="item.value"/>
              </el-select>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    Icon
  },

  data() {
    return {
      type: '',
      category: '',
      typeOption: [
        {
          value: 'property',
          label: 'Property',
        },
        {
          value: 'projects',
          label: 'Projects',
        }
      ],
      categoryOption: [
        {
          value: 'category',
          label: 'Category',
        },
      ]
    };
  },
});
</script>
<style lang="scss" scoped>
.uploaded-files-preview {
    // max-width: 476px;

    &__item {
      border-bottom: 1px solid #C5D2F2;
      padding-bottom: 1rem;
      box-sizing: border-box;
    }

    &__image-wrapper {
      width: 156px;
      height: 88px;
      border-radius: 8px;
      overflow: hidden;
    }

    &__image {
      object-fit: cover;
    }

    .el-select {
        width: 100%;
    }
}
</style>
