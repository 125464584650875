<template>
    <div class="select-property flex-column jc-sb h-100 w-100">
      <div class="flex-column jc-sb h-100 w-100">
        <div class="flex-row ai-c jc-sb">
          <div class="flex-row ai-c gap-half">
            <Typography variant="custom" textSize="12px" textColor="#48597B">
                12 <span class="select-property__span-text">Properties</span>
            </Typography>
            <el-divider direction="vertical"/>
            <Typography variant="custom" textSize="12px" textColor="#48597B">
                1 <span class="select-property__span-text">Selected</span>
            </Typography>
          </div>
          <DisplayViewComponent
            :viewType="viewType"
            @set-view="setView"
            :showAddButton="false"
            :showMapMenu="true"
            :showDeactivated="false"
            :showSort="true"
            :showListMenu="false"
            @add-function="routeToAddPropertyForm"/>
        </div>
        <div class="select-property__content grid h-100 overflow-y-auto gap-2">
          <PropertyCard v-for="item in cardCount" :key="item"/>
        </div>
        <section class="flex-column w-100">
          <div>
            <ActionItemComponent
                label="load 5 more Properties"
                icon="arrow-right.svg"
                :isReverse="true"
                fontColor="#FFA500"/>
          </div>
          <div class="flex-row ai-c w-100">
            <Button
              class="select-property__button"
              buttonText="add new property"
              :isActive="true"
              activeColor="#FFF"
              activeFontColor="#4F55F0"
              borderColor="#4F55F0"/>
            <Button
              class="select-property__button"
              buttonText="continue"
              :isActive="true"
              activeColor="#4B4BFF"
              activeFontColor="#FFFFFF"/>
          </div>
        </section>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import PropertyCard from '@/core/components/common/cards/PropertyCard.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import displayType from '@/core/constants/display';
import DisplayViewComponent from '@/modules/project-search/components/DisplayViewComponent.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';

export default defineComponent({
  components: {
    DisplayViewComponent,
    Typography,
    ActionItemComponent,
    Button,
    PropertyCard
  },

  data() {
    return {
      displayType,
      viewType: displayType.TILE,

      cardCount: 10
    };
  },

  computed: {
    ...mapGetters(PROPERTY_STORE, ['getOpenViewPropertyMapDialog', 'getIsMapView']),
  },

  methods: {
    ...mapActions(PROPERTY_STORE, ['getProperties', 'setOpenViewPropertyMapDialog', 'setIsMapView']),

    setView(event) {
      if (event === displayType.MAP) {
        this.setIsMapView(true);
      } else {
        this.setIsMapView(false);
      }
      this.viewType = event;
    },
  },
});
</script>
<style lang="scss" scoped>
.select-property {
  height: calc(100% - 6.5rem);
  &__span-text {
    font-weight: 400;
  }

  &__button {
    min-width: 342px;
    max-width: 342px;
  }

  &__content.grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(150px, 1fr));
    padding: 1rem;
    box-sizing: border-box;
  }
}
</style>
