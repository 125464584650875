<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="cancel"
  :hasActions="false"
  customClass="large-modal margin-top-none-on-mobile"
>
  <div class="modal-content flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="modal-content__modal-header flex-row jc-sb ai-c">
            <h2 class="modal-content__header-title default-text">items</h2>
            <div class="modal-content__header-icon">
              <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <DefaultGridLayoutSlot>
            <div class="modal-content__content flex-column">
                <div class="modal-content__row">
                    <div class="flex-row jc-sb ai-c">
                        <p class="modal-content__fee-summary-title default-text">Workstation <span class="gray">({{ getTypeWorkstation }})</span></p>
                        <p class="modal-content__fee-summary default-text">£4.99</p>
                    </div>
                </div>
                <div class="modal-content__row">
                    <BillingMembersCountComponent/>
                </div>
                <div class="modal-content__row">
                    <BillingStorageTypeComponent :plan="selectedPlan" @on-select-plan="onSelectPlan"/>
                    <div class="flex-row jc-sb ai-c">
                        <p class="modal-content__fee-summary-title default-text">Storage plan - <span class="gold">10 GB</span> <span class="text-small">(included)</span></p>
                        <!-- <p class="modal-content__fee-summary default-text">£0.00</p> -->
                    </div>
                </div>
                <!-- <BillingPaymentMethodComponent class="hide-in-mobile"/> -->
            </div>
            <WorkstationBillingSummary @on-pay="onPay"/>
        </DefaultGridLayoutSlot>
    </div>
    <div class="modal-content__summary-container flex-column">
      <PaymentButtonComponent/>
    </div>
  </div>
</BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import WorkstationBillingSummary from '@/core/components/common/modals/WorkstationBillingSummary.vue';
import DefaultGridLayoutSlot from '@/core/components/slots/DefaultGridLayoutSlot.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { BusinessDetailTypeEnum } from '@/core/packages/shared-library';
import $axios from '@/core/utils/axios-api-config';
import BillingMembersCountComponent from '@/modules/properties-billing/components/BillingMembersCountComponent.vue';
// import BillingPaymentMethodComponent from '@/modules/properties-billing/components/BillingPaymentMethodComponent.vue';
import BillingStorageTypeComponent from '@/modules/properties-billing/components/BillingStorageTypeComponent.vue';
import { BILLING_STORE } from '@/store/modules/billing';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';

import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from '../../../../config';

function initializeStripe() {
  let stripe = null;

  if ('Stripe' in window) {
    const { Stripe } = window;

    // eslint-disable-next-line no-new
    stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
  }

  return stripe;
}

export default defineComponent({
  name: 'current-members-plan-modal',
  emits: ['close'],
  components: {
    BaseModal,
    // BillingPaymentMethodComponent,
    BillingStorageTypeComponent,
    DefaultGridLayoutSlot,
    BillingMembersCountComponent,
    WorkstationBillingSummary
  },
  data() {
    return {
      selectedPlan: null,
      currentBill: null,
    };
  },
  computed: {
    ...mapGetters(BILLING_STORE, ['getStoragePlan', 'getTotalMember', 'getPerMemberPrice', 'getListingAmount', 'getPaymentMethod', 'getAddedMember']),
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation', 'selectedWorkstationDetail']),

    ...mapGetters(USERS_STORE, ['user']),
  },

  async created() {
    this.setAddedMember(0);

    this.onSelectPlan();
  },

  methods: {
    ...mapActions(BILLING_STORE, ['setAddedMember']),
    ...mapActions(USER_WORKSTATION, ['setSelectedWorkstationDetail']),

    onSelectPlan() {
      this.selectedPlan = this.getStoragePlan;
    },

    cancel() {
      this.setSelectedWorkstationDetail(null);
      this.$emit('close');
    },

    async onPay(bill) {
      this.currentBill = bill;
      await this.initializeStripePayment();
    },

    async initializeStripePayment() {
      this.stripe = initializeStripe();

      if (this.stripe) {
        await this.onCheckout();
      }
    },

    async createSession() {
      const {
        getTypeWorkstation, user, currentBill, selectedWorkstationDetail
      } = this;
      const { id: userId, userStripeCustomer, email } = user;

      let customer = { customerId: '' };
      const productName = `workstation-${getTypeWorkstation}-${userId}`;

      const entity = JSON.stringify({ id: userId, name: 'workstation' });

      if (!userStripeCustomer.length) {
        // tmp, needs to be transferred to service
        await $axios.post(`/payment/${userId}/customer`, {
          email,
          entity
        }).then((customerResponse) => {
          customer = customerResponse;
        })
          .catch((e) => {
            this.$notify.error({
              message: e || 'Error occured, please try again'
            });
          });
      } else {
        const [stripeCustomer] = userStripeCustomer; // 1-1 customer only for now
        customer = stripeCustomer;
      }

      const host = `${window.location.protocol}//${window.location.host}`;
      this.redirectUrl = `${host}/dashboard`;
      const typeTEAM = 'team';

      const { customerId } = customer;
      const { totalPrice } = currentBill;
      const { name, companyNumber } = selectedWorkstationDetail;
      const teamName = getTypeWorkstation === typeTEAM ? name : '';
      const companyName = !teamName ? name : '';
      const type = getTypeWorkstation === typeTEAM
        ? typeTEAM
        : BusinessDetailTypeEnum.LIMITED_COMPANY;

      if (customerId) {
        await $axios.post(`/payment/${userId}/session`, {
          customerId,
          price: parseFloat(totalPrice.replace('£', '', totalPrice)),
          redirectUrl: this.redirectUrl,
          productName,
          metadata: JSON.stringify({
            productName,
            userId,
            userWorkstationType: getTypeWorkstation,
            name,
            teamName,
            companyName,
            companyNumber,
            businessType: type,
            ...this.selectedPlan,
            totalMember: this.getAddedMember,
          }),
          isTrial: true
        }).then(async (sessionResponse) => {
          if (sessionResponse) {
            this.sessionId = sessionResponse.id;
          }
        });
      }
    },

    async onCheckout() {
      await this.createSession();

      if (this.sessionId) {
        this.stripe.redirectToCheckout({
          sessionId: this.sessionId,
        }).then((result) => {
          if (result.error) {
            this.$notify.error({
              message: result.error || 'Error.'
            });
          } else {
            this.isLoading = false;
          }
        });
      }
    },

    confirm() {
      this.$emit('close');
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;
    margin: 0;
    color: #0C0F4A;
}
.modal-content {
    &__content {
        padding-right: 1rem;
        border-right: 2px solid rgba(12, 15, 74, 0.1);
    }
    &__header-title {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    &__header-icon {
        cursor: pointer;
    }
    &__row {
        width: 100%;
        padding-bottom: 1rem;
        border-bottom:  2px solid rgba(12, 15, 74, 0.1);
    }

    &__fee-summary-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: left;

        span {
            font-size: 14px;
            font-weight: 500;
        }
        span.gold {
            color: #FFA500;
        }
        span.gray {
            color: rgba(12, 15, 74, 0.5);
        }
        span.text-small {
            font-size: 10px;
        }
    }
    &__fee-summary {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.25px;
    }
}
@include media-xs-max-width() {
  .modal-content {
    &__content {
        padding-right: 0;
        border-right: none;
    }
  }
}
</style>
