import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import DescriptionComponent from '@/modules/project-post/components/DescriptionComponent.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    name: 'project-description-modal',
    emits: ['close'],
    components: {
        BaseModal,
        DescriptionComponent
    },
    props: {
        show: Boolean,
    },
    data: () => {
        return {
            typeChoose: '',
            form: {}
        };
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, [
            'currentSelectedProject',
        ]),
    },
    methods: {
        ...mapActions(['setProjectDescriptionModal']),
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId', 'updateProject', 'setCurrentSelectedProject']),
        cancel() {
            this.$emit('close');
        },
        onUpdateValue(value) {
            this.form = value;
        },
        async confirm() {
            await this.updateProjectDetail();
        },
        async updateProjectDetail() {
            const { form } = this;
            const formData = { ...form.value };
            const { currentSelectedProject } = this;
            this.updateProject({ projectId: currentSelectedProject.id, formData })
                .then(async (response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Project Update Successful',
                        message: 'Updating Project Details successful.',
                        duration: 4000,
                    });
                    await this.getProjectByRefId(currentSelectedProject.refId)
                        .then((projectResponse) => {
                        if (projectResponse) {
                            this.setCurrentSelectedProject(projectResponse);
                        }
                    });
                    this.$emit('close');
                }
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Project Update Error',
                    message: e?.data?.message ?? 'Updating Project Details error occurred.',
                });
            })
                .finally(() => {
            });
        }
    }
});
