<template>
    <div class="certificate-list flex-column">
      <Typography variant="h6" textWeight="600">Your Qualifications</Typography>
      <el-scrollbar>
        <div class="certificate-list__scrollbar-content">
          <div
            class="certificate-list__item flex-row ai-c jc-sb"
            v-for="(certificate, index) in addedCertificates" :key="index">
            <div class="flex-column gap-0">
              <Typography variant="p"> {{ certificate.certificate.name }}</Typography>
              <!-- <Typography variant="p" textWeight="400">Level 3 (College)</Typography> -->
            </div>
            <Icon
              @click="removeCertificate(certificate.id)"
              class="cursor-pointer"
              iconName="trash-icon.svg"
              iconWidth="18px"
              iconHeight="18px"/>
          </div>
        </div>
      </el-scrollbar>
      <section class="flex-column gap-2">
        <Button
          style="border: 1px solid #4F55F0"
          @handle-click="addCertificate"
          :isActive="true"
          activeColor="#FFF"
          activeFontColor="#4F55F0"
          buttonText="add another qualification"/>
        <Button
          :isActive="true"
          @handle-click="onNext"
          buttonText="confirm"/>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    Button,
    Icon
  },

  emits: ['add-certificate', 'remove-certificate', 'next'],

  props: ['addedCertificates'],

  methods: {
    addCertificate() {
      this.$emit('add-certificate');
    },

    removeCertificate(userCertificateId) {
      this.$emit('remove-certificate', userCertificateId);
    },

    onNext() {
      this.$emit('next', true);
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.certificate-list {
    width: 50%;

    &__scrollbar-content {
      max-height: 150px;
      padding: 0 0.5rem .3rem 0;
    }
}

@include media-sm-max-width() {
  .certificate-list {
    width: 100%;
  }
}
</style>
