import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    data() {
        return {
            prevMinAmount: null,
            prevMaxAmount: null,
            value: [0, 250000],
            marks: {
                0: '',
                250000: ''
            }
        };
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value[0] !== 0 || value[1] !== 250000) {
                    this.onBudgetChange(value);
                }
            }
        }
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, [
            'currentSelectedProject',
        ]),
        ...mapGetters(PROPERTIES_PROJECT_POST_STORE, [
            'addedProjectMembers',
            'unsavedProjectMembers',
            'projectBudget'
        ]),
        minValue: {
            get() {
                if (this.value[0] !== null) {
                    return this.formatMinPound(this.value[0]);
                }
                return null;
            },
            set(newValue) {
                this.value[0] = this.parsePound(newValue);
            }
        },
        maxValue: {
            get() {
                if (this.value[1] !== null) {
                    return this.formatMaxPound(this.value[1]);
                }
                return null;
            },
            set(newValue) {
                this.value[1] = this.parsePound(newValue);
            }
        },
    },
    methods: {
        ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
            'setProjectBudget',
        ]),
        initialize() {
            const { projectBudget, currentSelectedProject } = this;
            if (currentSelectedProject?.minBudget
                || currentSelectedProject?.maxBudget) {
                this.value = [
                    currentSelectedProject?.minBudget, currentSelectedProject?.maxBudget
                ];
            }
            if (projectBudget.hasChanged) {
                const { min, max } = projectBudget;
                this.value = [
                    min, max
                ];
            }
        },
        onBudgetChange(data) {
            const minValue = data[0];
            const maxValue = data[1];
            this.setProjectBudget({
                min: minValue,
                max: maxValue,
                hasChanged: 1
            });
            this.$emit('on-update-value', {
                type: 'budget',
                value: {
                    min: minValue,
                    max: maxValue
                }
            });
        },
        formatMinPound(num) {
            let value = '';
            if (Number.isFinite(num)) {
                value = `£${Number(num).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
                this.prevMinAmount = value;
                this.value[0] = num;
            }
            return this.prevMinAmount;
        },
        formatMaxPound(num) {
            let value = '';
            if (Number.isFinite(num)) {
                value = `£${Number(num).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
                this.prevMaxAmount = value;
            }
            return this.prevMaxAmount;
        },
        parsePound(text) {
            return Number(text.toString().replace('£', '').replace(/,/g, ''));
        },
    }
});
