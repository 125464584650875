<template>
    <div class="money-in">
        <div class="money-in__header">
            <el-icon :size="24" @click="setMoneyInRightbar(false)" class="">
                <ArrowLeftBold />
            </el-icon>
            <h2>Money in</h2>
        </div>
        <div class="money-in__bank">
            <p class="money-in__bank__title">Select your bank</p>
            <el-select v-model="bankSelected" placeholder="Type or select by clicking the arrow" size="large">
                <el-option v-for="item in bankOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <p class="money-in__bank__other">Can't find your bank? <span @click="setMoneyInPayManuallyRightbar(true)">Pay manually</span></p>
        </div>
        <div class="money-in__amount">
            <el-input v-model.number="numberWithCommas" type="text" placeholder="£0.00"/>
        </div>
        <div class="money-in__term">
            By agreeing and continuing you consent to us requesting funds from your selected bank and sharing your
            account
            details with them. <span>Terms & conditions apply.</span> You will be redirect to your bank app for payment.
        </div>
        <el-button class="money-in__next-btn" @click="setMoneyInSuccessRightbar(true)">Next</el-button>
    </div>
</template>

<script>
import { ArrowLeftBold } from '@element-plus/icons';
import { mapActions } from 'vuex';

import { WALLET_STORE } from '@/store/modules/wallet';

export default {
  components: {
    ArrowLeftBold,
  },
  data() {
    return {
      amount: null,
      prevAmount: null,
      bankSelected: '',
      bankOptions: [
        {
          label: 'Barclay',
          value: 'barclay'
        }
      ]
    };
  },
  watch: {
    amount: {
      handler() {
        if (this.amount === 0) {
          this.amount = null;
        }
      }
    },
  },
  computed: {
    numberWithCommas: {
      get() {
        if (this.amount !== null) {
          return this.formatPound(this.amount);
        }
        return null;
      },
      set(newValue) {
        this.amount = this.parsePound(newValue);
      }
    },
  },
  methods: {
    ...mapActions(WALLET_STORE, ['setMoneyInRightbar', 'setMoneyInPayManuallyRightbar', 'setMoneyInSuccessRightbar']),
    formatPound(num) {
      let value = '';
      if (Number.isFinite(num)) {
        value = `£${Number(num).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
        this.prevAmount = value;
      }
      return this.prevAmount;
    },
    parsePound(text) {
      return Number(text.toString().replace('£', '').replace(/,/g, ''));
    },
  }
};
</script>

<style lang="scss" scoped>
.money-in {
    font-family: "Mullish", Arial;
    color: #0c0f4a;
    &__header {
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: 700;

        .el-icon {
            cursor: pointer;
        }
    }

    &__bank {
        &__other {
            color: rgba(12, 15, 74, 0.5);
            font-weight: 500;
            font-size: 14px;

            &__title {
                font-weight: 700;
            }

            span {
                color: #FFA500;
                cursor: pointer;
            }
        }
    }

    &__term {
        color: rgba(12, 15, 74, 0.5);
        line-height: 18px;
        letter-spacing: 0.25px;
        text-align: center;
        font-size: 10px;

        span {
            color: #0C0F4A;
        }
    }

    &__amount {
        margin: 6rem 0;
    }

    &__next-btn {
        width: 100%;
         background-color: #ffa500;
        font-size: 14px;
        text-transform: uppercase;
        border-radius: 7px;
        color: #fff;
        padding: 14px 20px;
        margin-top: 20px;
    }

    :deep(.money-in__bank .el-input__inner) {
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(12, 15, 74, 0.2);
        border-radius: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;

        &::placeholder {
            color: rgba(12, 15, 74, 0.5);
            font-weight: 400;
            font-size: 12px;
            line-height: 30px;
        }
    }

    :deep(.money-in__amount .el-input__inner) {
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(12, 15, 74, 0.2);
        border-radius: 0;
        padding: 0;
        text-align: center;
        font-weight: 700;
        font-size: 30px;
        letter-spacing: .15px;
        line-height: 24px;
        color: #FAA100;

        &::placeholder {
          width: 100%;
        border: none;
        border-radius: 0;
        padding: 0;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        letter-spacing: .15px;
        line-height: 24px;
        }
    }

    .el-select {
        width: 100%;
    }
}
</style>
