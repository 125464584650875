import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import HeaderProfile from '@/core/components/common/header/HeaderProfile.vue';
import NavigationMenu from '@/core/components/common/menu/NavigationMenu.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'TradePassportHeader',
    components: {
        NavigationMenu,
        Typography,
        HeaderProfile,
    },
    data() {
        return {
            openAccountSetupModal: false,
        };
    },
    computed: {
        ...mapGetters(['isTradesperson', 'workstationSetupModal']),
        ...mapGetters(USERS_STORE, ['user']),
        routeTitle() {
            return (this.$route?.meta?.title || '');
        },
        showProfile() {
            return (this.$route?.meta?.showProfile || false);
        },
    },
});
