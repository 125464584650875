import { defineComponent } from '@vue/runtime-core';
import { mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { USERS_STORE } from '../../../../store/modules/users';
export default defineComponent({
    name: 'user-refer-and-earn-modal',
    emits: ['close'],
    components: {
        BaseModal,
    },
    props: {
        show: Boolean,
    },
    data: () => {
        return {
            copyReferralCodeToClipBoard: false,
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user'])
    },
    methods: {
        cancel() {
            this.$emit('close');
        },
        copyReferralCode() {
            const copyText = document.querySelector('#code');
            copyText.select();
            document.execCommand('copy');
            this.copyReferralCodeToClipBoard = true;
            setTimeout(() => {
                this.copyReferralCodeToClipBoard = false;
            }, 3000);
        },
        submit() {
            this.$emit('close');
        }
    }
});
