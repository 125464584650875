// import { BellFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { AUTH_SESSION_EXPIRY, AUTH_USER } from '@/core/constants';
import StringHelper from '@/core/helpers/string.helper';
import { RolesEnum } from '@/core/packages/shared-library';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '@/core/utils/common';
import { DASHBOARD_ROUTE } from '@/modules/dashboard/routes';
import { USERS_STORE } from '@/store/modules/users';
import { NOTIFICATIONS_STORE } from '../../../store/modules/notifications';
export default defineComponent({
    name: 'sidebar-header',
    components: {
        UserThumbnail,
    },
    data() {
        return {
            currentRole: '',
            options: [
                {
                    value: RolesEnum.PROJECT_OWNER,
                    label: 'Property owner',
                },
                {
                    value: RolesEnum.TRADESPERSON,
                    label: 'Tradesperson',
                }
            ],
        };
    },
    created() {
        const authUser = getLocalStorageWithExpiry(AUTH_USER);
        if (this.user) {
            this.currentRole = authUser.roleId;
        }
    },
    computed: {
        ...mapGetters(['isNavCollapse', 'isTradesperson', 'isProjectOwner']),
        ...mapGetters(NOTIFICATIONS_STORE, ['hasNewNotifications']),
        ...mapGetters(USERS_STORE, ['user', 'isHosted', 'invitedUser']),
        profileImage() {
            return this.isHosted ? this.invitedUser?.userPublicProfile?.profileImage
                : this.user?.userPublicProfile?.profileImage || null;
        },
        firstName() {
            return this.isHosted ? this.invitedUser?.firstName : this.user.firstName;
        },
        lastName() {
            return this.isHosted ? this.invitedUser?.lastName : this.user.lastName;
        }
    },
    props: {
        user: Object
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'selectAccount',
            'updateUser',
            'saveUserState',
            'getUser'
        ]),
        ...mapActions(NOTIFICATIONS_STORE, ['toggleDrawer']),
        async onSwitchRole(selectedRole) {
            const { user } = this;
            if (this.isHosted) {
                return;
            }
            await this.selectAccount({
                userId: user.id,
                roleId: selectedRole,
                email: user.email
            }).then(async (response) => {
                const authUser = getLocalStorageWithExpiry(AUTH_USER);
                const newUserState = await this.getUser(response.id);
                authUser.userId = newUserState.id;
                authUser.roleId = selectedRole;
                authUser.aliased = StringHelper.isAliased(newUserState.email);
                setLocalStorageWithExpiry(authUser, AUTH_USER, AUTH_SESSION_EXPIRY);
                await this.saveUserState(newUserState);
                window.location.href = `/${DASHBOARD_ROUTE}`;
            }).catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'A problem occured. Please try again.'
                });
            });
        }
    }
});
