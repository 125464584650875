import { defineComponent, ref } from 'vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
export default defineComponent({
    emits: ['handle-upload'],
    components: {
        Typography,
        Icon
    },
    data() {
        return {
            images: [],
        };
    },
    methods: {
        onFileChanged($event) {
            console.log('🚀 ~ onFileChanged ~ event:', $event);
            const file = ref();
            const target = $event.target;
            if (target && target.files) {
                // eslint-disable-next-line prefer-destructuring
                file.value = target.files[0];
            }
            if (file.value) {
                this.images.push(file.value);
            }
        }
    }
});
