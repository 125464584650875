<template>
    <el-container class="subscription w-100">
      <SubscriptionScreen v-if="screenType === DEFAULT_SCREEN" @next-screen="setScreen(PAYMENT_SCREEN)"/>
      <PaymentOverviewScreen v-if="screenType === PAYMENT_SCREEN" @next="onNext"/>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';

import PaymentOverviewScreen from '@/modules/account-setup/components/PaymentOverviewScreen.vue';
import SubscriptionScreen from '@/modules/account-setup/components/SubscriptionScreen.vue';

const DEFAULT_SCREEN = 'default';
const PAYMENT_SCREEN = 'payment';

export default defineComponent({
  components: {
    SubscriptionScreen,
    PaymentOverviewScreen
  },

  emits: ['next'],

  data() {
    return {
      PAYMENT_SCREEN,
      DEFAULT_SCREEN,

      screenType: DEFAULT_SCREEN,
    };
  },

  computed: {
  },

  async created() {
  },

  methods: {
    setScreen(screen) {
      this.screenType = screen;
    },

    onNext() {
      this.$emit('next');
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.subscription {
}

@include media-md-max-width() {
  .subscription {
    overflow-y: auto;
  }
}

@include media-sm-max-width() {
  .subscription {
  }
}
</style>
