<template>
  <el-container class="payment-overview grid w-100 gap-2">
    <section class="payment-overview__wrapper flex-column">
      <div class="payment-overview__section flex-row ai-c jc-sb">
        <span class="flex-column gap-half">
          <Typography variant="h6">Iknowa Workstation</Typography>
          <Typography variant="p" textWeight="500" textColor="rgba(12, 15, 74, 0.50);">(Limited Company Monthly)</Typography>
        </span>
        <Typography variant="p">£4.99</Typography>
      </div>
      <div class="payment-overview__section flex-column ai-c jc-c gap-half">
        <BillingMembersCountComponent class="flex-column" :center="true"/>
        <BillingDetailItem class="w-100" :labelSize="LABEL_SIZE_MEDIUM" labelText="Members fee" amountTextSize="14px" amount="2.49"/>
      </div>
      <div class="payment-overview__section flex-column ai-c jc-c gap-half">
        <BillingDetailItem class="w-100" labelText="Sub total" amount="7.48"/>
        <BillingDetailItem class="w-100" labelText="VAT (20%)" amount="1.50"/>
      </div>
      <div class="payment-overview__section flex-column ai-c jc-c gap-half">
        <BillingDetailItem class="w-100" :labelSize="LABEL_SIZE_MEDIUM" labelText="Due today" amountTextSize="14px" amount="0"/>
        <BillingDetailItem class="w-100" labelText="Billed monthly after 14 days" color="rgba(12, 15, 74, 0.5)" amount="8.98"/>
      </div>
      <div class="flex-column ai-c w-100">
        <Button class="w-100" :isActive="true" buttonText="next" @handle-click="onNext"/>
        <Typography variant="p" textColor="rgba(12, 15, 74, 0.5)" textWeight="600" textAlign="center">
          After your 14 day trial ends, you will be charged £8.98 monthly,<br> starting [DD/MM/YYYY]. Cancel anytime.
        </Typography>
      </div>
    </section>
    <section class="payment-overview__reviews flex-column ai-c jc-c hide-in-mobile">
      <ReviewItem/>
      <ReviewItem/>
      <ReviewItem/>
    </section>
  </el-container>
</template>
<script>
import { defineComponent } from 'vue';

import BillingDetailItem from '@/core/components/billing/BillingDetailItem.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import {
  LABEL_SIZE_LARGE, LABEL_SIZE_MEDIUM, LABEL_SIZE_SMALL, LABEL_SIZE_XLARGE
} from '@/core/constants/label';
import ReviewItem from '@/modules/account-setup/components/ReviewItem.vue';
import BillingMembersCountComponent from '@/modules/properties-billing/components/BillingMembersCountComponent.vue';

export default defineComponent({
  components: {
    Typography,
    BillingMembersCountComponent,
    BillingDetailItem,
    Button,
    ReviewItem
  },

  emits: ['next'],

  data() {
    return {
      LABEL_SIZE_LARGE,
      LABEL_SIZE_MEDIUM,
      LABEL_SIZE_SMALL,
      LABEL_SIZE_XLARGE
    };
  },

  methods: {
    onNext() {
      this.$emit('next');
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.payment-overview {
  grid-template-columns: 1fr 1fr;

  &__section {
    padding-bottom: .5rem;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(12, 15, 74, 0.10);
  }

  &__reviews div:nth-child(2){
    margin-left: 5rem;
  }

  &__reviews div:last-child {
    margin-right: 3rem;
  }
}

@include media-sm-max-width() {
  .payment-overview {
    grid-template-columns: 1fr;
  }
}

@include media-xs-max-width() {
  br {
    display: none;
  }
}
</style>
