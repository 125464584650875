import { defineComponent } from '@vue/runtime-core';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'e-wallet-confirm-interest-modal',
    emits: ['close'],
    components: {
        BaseModal,
    },
    props: {
        show: Boolean,
    },
    data: () => {
        return {};
    },
    computed: {},
    methods: {
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        }
    }
});
