import { defineComponent } from 'vue';
import SelectionCard from '@/core/components/common/cards/SelectionCard.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import projectType from '@/core/constants/status/project-type';
import ProjectCard from '../ProjectCard.vue';
// import ProjectTimeline from '../ProjectTimeline.vue';
export default defineComponent({
    components: {
        Typography,
        // ProjectTimeline,
        ProjectCard,
        Button,
        SelectionCard
    },
    data() {
        return {
            projectType,
            timelineData: [
                {
                    value: projectType.EMERGENCY,
                    icon: 'exclamation-mark.svg',
                    title: 'Emergency',
                    label: 'This project needs to start now'
                },
                {
                    value: projectType.STANDARD,
                    icon: 'light-bulb.svg',
                    title: 'Standard',
                    label: 'This project has a flexible or preferred start date'
                },
                {
                    value: projectType.ROUTINE,
                    icon: 'routine.svg',
                    title: 'Routine',
                    label: 'This project occurs regularly'
                },
            ],
            testCard: [
                {
                    score: 21,
                    address: '33 Collier Close, KT19',
                    projectName: 'New EPC Assessment 1'
                },
                {
                    score: 24,
                    address: '33 Collier Close, KT19',
                    projectName: 'New EPC Assessment 2'
                },
                {
                    score: 55,
                    address: '33 Collier Close, KT19',
                    projectName: 'New EPC Assessment 3'
                }
            ],
            selectedProjectType: ''
        };
    },
    methods: {
        handleSelect(type) {
            this.selectedProjectType = type;
        }
    },
});
