<template>
    <el-container class="certificate-component flex-column">
      <section class="flex-column gap-half">
        <Typography variant="h3" textWeight="400">Do you have any certifications?</Typography>
        <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
            Please enter your qualification details.
        </Typography>
        <Typography variant="p" textColor="rgba(12, 15, 74, 0.5)" textWeight="500" :isItalic="true">
            *Please note. we will require proof before engaging in any projects on iknowa.
        </Typography>
      </section>
      <CertificationForm
        v-if="showForm || !showForm && userCertificates.length <= 0"
        :userCertificates="userCertificates"
        :submitting="submitting"
        @add-to-certificate-list="addToCerticationList"
        @cancel="backToList"/>
      <CertificateList
        v-else
        :addedCertificates="userCertificates"
        @next="onNext"
        @add-certificate="onAddAnotherCertificate"
        @remove-certificate="onRemoveCertificate"/>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Typography from '@/core/components/ui/Typography.vue';
import CertificateList from '@/modules/account-setup/components/CertificateList.vue';
import CertificationForm from '@/modules/account-setup/forms/CertificationForm.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    CertificationForm,
    Typography,
    CertificateList
  },

  emits: ['next'],

  data() {
    return {
      showForm: false,
      addedCertificates: [],
      submitting: false
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user', 'userCertificates', 'certificateVerificationState']),
  },

  async created() {
    await this.getUserCertificates(this.user.id);
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'addNewCertificate',
      'getUserCertificates',
      'updateUserCertificate',
      'removeUserCertificates',
      'getAccountStatuses'
    ]),

    addToCerticationList(certificate) {
      const certificateAlreadyExist = this.userCertificates.find((userCert) => userCert.certificate.id === certificate.type);

      if (certificateAlreadyExist) {
        this.$notify.error({
          message: 'Certificate already exist.'
        });
        return;
      }

      const userCertificate = {
        userId: this.user.id,
        certificateId: certificate.type,
        metadata: {
          registrationNumber: certificate.membershipNumber,
          url: certificate.url
        },
        attachments: certificate.attachments
      };

      this.submitting = true;

      this.addNewCertificate(userCertificate)
        .then(async () => {
          this.showForm = false;
          this.$notify.success({
            message: 'New certificate successfully saved.'
          });
        })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error submitting certificate details.'
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    },

    onAddAnotherCertificate() {
      this.showForm = true;
    },

    onRemoveCertificate(userCertificateId) {
      this.removeUserCertificates({
        userId: this.user.id,
        userCertificateId
      })
        .then(async () => {
          this.$notify.success({
            message: 'Certificate successfully removed.'
          });
        })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error removing certificate details.'
          });
        });
    },

    backToList() {
      this.showForm = false;
    },

    onNext() {
      this.$emit('next', true);
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
