<template>
    <el-container class="flex-column h-100">
      <section class="flex-column gap-half">
        <Typography variant="h3" textWeight="400">Profile picture</Typography>
        <typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
            Please add your profile picture
        </typography>
      </section>
      <ProfilePhotoForm @next="onNext"/>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';

import Typography from '@/core/components/ui/Typography.vue';
import ProfilePhotoForm from '@/modules/account-setup/forms/ProfilePhotoForm.vue';

export default defineComponent({
  components: {
    Typography,
    ProfilePhotoForm
  },

  emits: ['complete', 'next'],

  methods: {
    onComplete() {
      this.$emit('complete');
    },
    onNext() {
      this.$emit('next');
    },
  }
});
</script>
<style lang="scss" scoped>
</style>
