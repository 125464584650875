<template>
    <div class="rating-star flex-row ai-c gap-half">
        <el-rate
            :max="5"
            :show-text="false"
            :show-score="false"
            v-model="value"
            disabled>
        </el-rate>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['count'],

  data() {
    return {
      value: 0,
    };
  },

  created() {
    this.value = this.count;
  },
});
</script>
<style lang="scss" scoped>
</style>
