<template>
    <div class="notification-dropdown-item flex-row ai-fs gap-half">
        <ProfileAvatar :name="name" :img="img"/>
        <div class="flex-column gap-0">
          <Typography variant="custom" textSize="13px" lineHeight="18px">
            {{ name }} <span style="font-size: 12px">{{ label }}</span>
          </Typography>
          <Typography
            variant="custom"
            textSize="12px"
            textWeight="500">
            {{ time }} Ago
          </Typography>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import ProfileAvatar from '@/core/components/common/thumbnail/ProfileAvatar.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    ProfileAvatar
  },

  props: {
    img: String,
    name: String,
    label: String,

    // time receive will be raw time, so we need to convert the time in to a display string: e.g '10 Minutes'
    time: String
  }
});
</script>
<style lang="scss" scoped>
</style>
