import { defineComponent } from 'vue';
export default defineComponent({
    components: {},
    emits: ['on-update-value'],
    data() {
        return {
            title: ''
        };
    },
    watch: {
        title() {
            this.$emit('on-update-value', this.title);
        }
    },
});
