<template>
    <div class="subscription-item flex-column jc-sb">
        <div class="flex-row ai-c gap-half">
          <Tag :tag="isYearly ? 'YEARLY' : 'MONTHLY'" :dataValue="1"/>
          <Tag tagLabel="14 DAYS FREE TRIAL" v-if="isYearly" :dataValue="1"/>
        </div>
        <div class="flex-row ai-c gap-0">
          <Typography variant="h5">{{ isYearly ? '£3.99' : '£4.99'}}</Typography>
          <Typography variant="p" textWeight="400">per month{{ isYearly ? '/ £47.88 today' : ''}}</Typography>
        </div>
      <div class="subscription-item__footer flex-row w-100">
        <Button
          class="subscription-item__button w-100"
          :isActive="true"
          :buttonText="isYearly ? 'choose YEARLY ' : 'choose monthly '"
          @handle-click="onSelectItem"/>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import Tag from '@/core/components/ui/Tag.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Button,
    Tag,
    Typography
  },

  emits: ['on-select'],

  props: ['isYearly'],

  methods: {
    onSelectItem() {
      this.$emit('on-select', this.isYearly);
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.subscription-item {
  padding: 1.5rem 1rem;
  box-sizing: border-box;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  gap: 1.5rem;
}

@include media-md-max-width() {
  .subscription-item {
    gap: .5rem;
  }
}
</style>
