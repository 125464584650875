<template>
   <div class="flex-row ai-c">
      <div class="header-title-container">
        <p class="header-date">{{ getGetCurrentDay }}, {{ getGetCurrentDayNumber }} {{ getGetCurrentMonth }}</p>
        <p class="header-name">Welcome back <span>{{ firstName }}</span></p>
      </div>

      <Button
        v-if="isTradesperson"
        :isActive="true"
        buttonText="open account setup wizard"
        @handle-click="onOpenAccountSetupModal"/>

      <Button
        v-if="isTradesperson"
        :isActive="true"
        buttonText="open company type"
        @handle-click="onOpenCompanyType"/>

      <Button
        v-if="isTradesperson"
        :isActive="true"
        buttonText="open trades passport"
        @handle-click="onOpenTradesPassport"/>

      <!-- MODAL FOR DEMO PURPOSES ONLY -->
      <AccountSetupWizardDialog
        :show="openAccountSetupModal && isTradesperson"
        :showBusinessTypeOnly="showBusinessTypeOnly"
        @close="setAccountSetupModal(false)"/>

      <CompanyTypeDialog
        :show="companyTypeModal && isTradesperson"
        @close="setCompanyTypeModal(false)"/>
   </div>
</template>
<script>
import moment from 'moment';
import { computed } from 'vue';
import { mapGetters } from 'vuex';

import Button from '@/core/components/ui/Button.vue';
import AccountSetupWizardDialog from '@/modules/account-setup/dialog/AccountSetupWizardDialog.vue';
import CompanyTypeDialog from '@/modules/account-setup/dialog/CompanyTypeDialog.vue';
import { USERS_STORE } from '@/store/modules/users';

export default {
  props: ['user'],

  components: {
    AccountSetupWizardDialog,
    Button,
    CompanyTypeDialog
  },

  computed: {
    ...mapGetters(['isTradesperson']),
    ...mapGetters(USERS_STORE, ['isHosted', 'invitedUser']),

    firstName() {
      return this.isHosted
        ? this.invitedUser?.firstName : this.user.firstName;
    }
  },

  data() {
    return {
      showBusinessTypeOnly: false,

      openAccountSetupModal: false,
      companyTypeModal: false
    };
  },

  setup() {
    const getGetCurrentMonth = computed(() => {
      return moment(new Date()).format('MMMM');
    });

    const getGetCurrentDay = computed(() => {
      return moment(new Date()).format('dddd');
    });

    const getGetCurrentDayNumber = computed(() => {
      return moment(new Date()).format('D');
    });

    return {
      getGetCurrentMonth,
      getGetCurrentDay,
      getGetCurrentDayNumber
    };
  },

  methods: {
    setAccountSetupModal(bool) {
      this.openAccountSetupModal = bool;
    },

    setCompanyTypeModal(bool) {
      this.companyTypeModal = bool;
    },

    onOpenAccountSetupModal() {
      this.setCompanyTypeModal(false);
      this.setAccountSetupModal(true);
    },

    onOpenCompanyType() {
      this.setAccountSetupModal(false);
      this.setCompanyTypeModal(true);
    },

    onOpenTradesPassport() {
      this.$router.push({ name: 'trade-passport' });
    }
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.header-title-container {
  display:flex;
  flex-direction: column;
  gap:.1rem;
  p {
    margin: 0;
  }
  .header-date {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(12, 15, 74, 0.5);
  }
  .header-name {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;

    span {
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: left;
    }
  }
}

@include media-xs-max-width() {
  .header-title-container {
    .header-date {
      font-size: 12px;
    }
    .header-name {
      font-size: 17px;

      span {
        font-size: 17px;
      }
    }
  }
}
</style>
