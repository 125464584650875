<template>
    <el-container class="insurance-component flex-column jc-sb">
      <section class="flex-column">
        <section class="flex-column gap-half">
          <Typography variant="h3" textWeight="400">Let’s confirm your insurances</Typography>
          <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
              In order to register yourself as a Sole Trader, please confirm your insurances.
          </Typography>
        </section>
        <section class="insurance-component__wrapper w-50">
          <InsuranceForm
            v-if="isOnTheForm"
            @on-update-value="onUpdateValue"
            @on-taggle-form="onToggleForm"
            :value="userInsurances"/>
          <InsuranceDetail
            v-else
            :value="userInsurances"
            :actionIsButtonType="true"
            @on-taggle-form="onToggleForm"
            @on-delete-value="onDeleteValue"/>
        </section>
      </section>
      <section class="insurance-component__footer flex-row ai-c jc-sb w-100">
        <Button
          class="insurance-component__button w-50"
          :isActive="isFormReady"
          @handle-click="onHandleClick"
          :buttonText="isOnTheForm ? 'save' : 'next'"/>
        <div class="flex-row ai-c gap-half">
          <Typography
            class="cursor-pointer"
            variant="p"
            textColor="rgba(12, 15, 74, 0.5)"
            textWeight="500">
            Need trade insurance?
          </Typography>
          <div class="flex-row ai-c gap-half cursor-pointer">
            <Typography
                class="cursor-pointer"
                variant="p"
                whiteSpace="nowrap"
                textColor="#FAA500"
                textWeight="500">
                Get quotes
            </Typography>
            <el-icon :size="14" color="#FAA500"><ArrowRightBold/></el-icon>
          </div>
        </div>
      </section>
    </el-container>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import InsuranceDetail from '@/core/components/common/forms/insurance/InsuranceDetail.vue';
import InsuranceForm from '@/core/components/common/forms/insurance/InsuranceForm.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    Typography,
    InsuranceDetail,
    InsuranceForm,
    Button,
    ArrowRightBold
  },

  emits: ['next'],

  data() {
    return {
      userInsurances: [],
      showForm: false,
      isFormReady: false
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),

    isOnTheForm() {
      return this.showForm || (!this.showForm && this.userInsurances.length <= 0);
    }
  },

  async created() {
    const userId = this.user.id;

    if (!this.userInsurances.length) {
      await this.setUserInsurances(userId);
    }
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'getUserInsurances'
    ]),

    async setUserInsurances(userId) {
      this.userInsurances = await this.getUserInsurances(userId).catch(() => {}) || [];
    },

    onToggleForm() {
      this.showForm = !this.showForm;
    },

    onHandleClick() {}
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.insurance-component {
  &__wrapper {
  }
}

@include media-sm-max-width() {
  .insurance-component {
    &__wrapper {
      width: 100%;
    }
    &__footer {
      flex-direction: column;
      gap: .5rem;
      align-items: flex-start;
    }
    &__button {
      width: 100%;
    }
  }
}
</style>
