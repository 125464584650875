<template>
  <div class="certificate-form flex-column gap-2">
    <el-form :model="form" class="default-form" label-position="top">
      <el-form-item label="Qualification Name">
        <el-select v-model="form.type" class="options" placeholder="E.g. Gas Safe Registry">
          <el-option
            v-for="certificate in certificates"
            :key="certificate.id"
            :label="certificate.name"
            :value="certificate.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Membership number (optional)">
        <el-input v-model="form.membershipNumber" placeholder="E.g. 12345678" autocomplete="off" />
      </el-form-item>
      <el-form-item label="URL (optional)">
        <el-input v-model="form.url" placeholder="E.g. https://www.gassaferegister.co.uk" autocomplete="off" />
      </el-form-item>
    </el-form>
    <UploadForm
      :record="form"
      :attachmentSources="attachmentSources"
      :useCustomUploadIcon="true"
      />
    <Button :isActive="isFormReady" :loading="submitting" buttonText="add qualification" @handle-click="addToCertificateList"/>
    <Typography
      v-if="userCertificates.length && !hideCancelCta"
      @click="backToList"
      class="cursor-pointer"
      variant="p"
      textColor="#FAA500"
      textWeight="500">
      Cancel
    </Typography>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { CERTIFICATES_STORE } from '@/store/modules/certificates';

export default defineComponent({
  components: {
    Button,
    Typography,
    UploadForm
  },

  emits: ['cancel'],

  props: ['userCertificates', 'submitting', 'hideCancelCta'],

  data() {
    return {
      selectedCertificate: null,

      form: {
        type: '',
        membershipNumber: '',
        url: '',
        // postcode: '',
        attachments: []
      },
      attachmentSources: [],

      isFormReady: false,
      selectedValue: null,
    };
  },

  computed: {
    ...mapGetters(CERTIFICATES_STORE, [
      'certificates',
    ]),
  },

  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(value) {
        this.validateForm(value);
      }
    }
  },

  async created() {
    if (!this.certificates.length) {
      await this.getCertificates();
    }
  },

  methods: {
    ...mapActions(CERTIFICATES_STORE, ['getCertificates']),

    addToCertificateList() {
      // const certificate = this.certificates.find((cert) => cert.id === this.selectedCertificate);

      this.$emit('add-to-certificate-list', this.form);
    },

    backToList() {
      this.$emit('cancel');
    },

    validateForm(data) {
      let value = data;
      let isValid = false;
      const fields = Object.keys(data);

      if (fields.length && data.type !== '' && data.attachments.length) {
        isValid = true;
      } else {
        isValid = false;
      }

      if (!isValid) {
        value = {};
      }

      this.isFormReady = isValid;

      // this.$emit('on-update-value', value);
      console.log(value, 'value');
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.certificate-form {
  width: 50%;
}
:deep(.el-form-item) {
  margin-bottom: 0;
}
:deep(.el-input) {
  height: 42px;
  width: 100%;

  .el-input__inner {
    background: rgba(12, 15, 74, 0.05);
    border-radius: 100px;
    outline:none;
    border: none;
    padding: 20px 20px 20px 20px;
  }
}
.el-select {
  width: 100%;
}

@include media-sm-max-width() {
  .certificate-form {
    width: 100%;
  }
}
</style>
