import { Check, Setting } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import NotificationCard from '@/core/components/notifications/NotificationCard.vue';
import { NOTIFICATIONS_STORE } from '@/store/modules/notifications';
import { USERS_STORE } from '@/store/modules/users';
import { MESSAGES_STORE } from '../../../store/modules/messages';
export default defineComponent({
    name: 'notification-drawer',
    components: {
        NotificationCard,
        Setting,
        Check
    },
    data: () => {
        return {
            isDataLoaded: false,
        };
    },
    mounted() {
        this.initialize();
    },
    computed: {
        ...mapGetters(NOTIFICATIONS_STORE, ['notificationList', 'drawerOpen']),
        ...mapGetters(USERS_STORE, ['user']),
    },
    methods: {
        ...mapActions(MESSAGES_STORE, ['getUnreadMessagesByUser']),
        ...mapActions(NOTIFICATIONS_STORE, ['toggleDrawer', 'init', 'pushNotificationItem']),
        initialize() {
            this.init()
                .then(() => {
                this.isDataLoaded = true;
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Notification list error occurred',
                });
            });
            // console.info('Notifications initializing data.');
            this.$pusher.connection.bind('error', () => {
                // console.error(err);
            });
            // initiate private channel per authUser session
            const userNotificationChannel = this.$pusher.subscribe(`private-notifications-user-${this.user.id}`);
            userNotificationChannel.bind('user.notifications', (data) => {
                this.pushNotificationItem(data);
            });
            userNotificationChannel.bind('new.message', () => {
                this.getUnreadMessagesByUser({ fromUser: false });
            });
            userNotificationChannel.bind('pusher:subscription_succeeded', () => {
                // console.info('Subscription of private channel succeeded.');
            });
            userNotificationChannel.bind('pusher:subscription_error', () => {
                // console.error('Error notification pusher subscription.');
                // re-authenticate subscription event
                // (this as any).$pusher.subscribe(`private-notifications-user-${(this as any).user.id}`);
            });
        },
        handleCommand(command) {
            this.$store.dispatch(`${NOTIFICATIONS_STORE}/${command}`);
        },
        closeDrawer() {
            this.toggleDrawer();
        }
    }
});
