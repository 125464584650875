<template>
    <div class="money-in">
        <div class="money-in__header">
            <el-icon :size="24" @click="setMoneyInPayManuallyRightbar(false)" class="">
                <ArrowLeftBold />
            </el-icon>
            <h2>Money in Pay</h2>
        </div>
        <div class="money-in__content">
            <div class="money-in__content__title">Pay manually</div>
            <div class="money-in__content__item">
                <div class="money-in__content__item__title">
                    Beneficiary
                </div>
                <div class="money-in__content__item__value">
                    Amanda Po
                </div>
                <div class="money-in__content__item__icon">
                    <el-icon>
                        <CopyDocument />
                    </el-icon>
                </div>
            </div>
            <div class="money-in__content__item">
                <div class="money-in__content__item__title">
                    Account number
                </div>
                <div class="money-in__content__item__value">
                    376567547
                </div>
                <div class="money-in__content__item__icon">
                    <el-icon>
                        <CopyDocument />
                    </el-icon>
                </div>
            </div>
            <div class="money-in__content__item">
                <div class="money-in__content__item__title">
                    Sort code
                </div>
                <div class="money-in__content__item__value">
                    20-20-45
                </div>
                <div class="money-in__content__item__icon">
                    <el-icon>
                        <CopyDocument />
                    </el-icon>
                </div>
            </div>
            <div class="money-in__content__item">
                <div class="money-in__content__item__title">
                    Reference
                </div>
                <div class="money-in__content__item__value">
                    # 321ABC
                </div>
                <div class="money-in__content__item__icon">
                    <el-icon>
                        <CopyDocument />
                    </el-icon>
                </div>
            </div>
        </div>
        <div class="money-in__term">
            By agreeing and continuing you consent to us requesting funds from your selected bank and sharing your
            account
            details with them. <span>Terms & conditions apply.</span> You will be redirect to your bank app for payment.
        </div>
        <div class="btn-container">
            <div class="primary-btn">I have already make the payment</div>
            <div class="secondary-btn">Cancel</div>
        </div>
    </div>
</template>

<script>
import { ArrowLeftBold, CopyDocument } from '@element-plus/icons';
import { mapActions } from 'vuex';

import { WALLET_STORE } from '@/store/modules/wallet';

export default {
  components: {
    ArrowLeftBold,
    CopyDocument
  },
  data() {
    return {
      amount: null,
      bankSelected: '',
      bankOptions: [
        {
          label: 'Barclay',
          value: 'barclay'
        }
      ]
    };
  },
  methods: {
    ...mapActions(WALLET_STORE, ['setMoneyInPayManuallyRightbar'])
  }
};
</script>

<style lang="scss" scoped>
.money-in {
    font-family: "Mullish", Arial;
    color: #0c0f4a;

    &__header {
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: 700;

        .el-icon {
            cursor: pointer;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        &__title {
            margin: 50px 0;
            color: #0C0F4A;
            letter-spacing: 0.25px;
        }

        &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            line-height: 30px;

            &__title {
                color: rgba(12, 15, 74, 0.5);
                font-size: 12px;
            }

            &__value {
                color: #0C0F4A;
                font-size: 24px;
                line-height: 30px;
            }

            &__icon {
                cursor: pointer;
            }
        }
    }

    &__term {
        color: rgba(12, 15, 74, 0.5);
        line-height: 18px;
        letter-spacing: 0.25px;
        text-align: center;
        font-size: 10px;
        margin-top: 6rem;

        span {
            color: #0C0F4A;
        }
    }

    &__amount {
        margin: 6rem 0;
    }

    :deep(.money-in__bank .el-input__inner) {
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(12, 15, 74, 0.2);
        border-radius: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;

        &::placeholder {
            color: rgba(12, 15, 74, 0.5);
            font-weight: 400;
            font-size: 12px;
            line-height: 30px;
        }
    }

    :deep(.money-in__amount .el-input__inner) {
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(12, 15, 74, 0.2);
        border-radius: 0;
        padding: 0;
        text-align: center;
        font-weight: 700;
        font-size: 30px;
        letter-spacing: .15px;
        line-height: 24px;
        color: #FAA100;

        &::placeholder {
            width: 100%;
            border: none;
            border-radius: 0;
            padding: 0;
            text-align: center;
            font-weight: 700;
            font-size: 24px;
            letter-spacing: .15px;
            line-height: 24px;
        }
    }

    .el-select {
        width: 100%;
    }

    .btn-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 3rem;

        .primary-btn {
            color: #FAA100;
            border-bottom: 1px solid #FAA100;
            font-weight: 700;
        }

        .secondary-btn {
            text-align: center;
            font-size: 12px;
            color: rgba(12, 15, 74, 0.5);
        }

    }
}
</style>
