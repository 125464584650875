<template>
  <SetupDialogSlot :header="activeForm?.formHeader">
    <template #content>
      <WizardForm
        :config="wizardFormConfig"
        @set-active-form="setActiveForm"
        @on-complete="onComplete"/>
    </template>
  </SetupDialogSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import WizardForm from '@/core/components/common/forms/WizardForm.vue';
import SetupDialogSlot from '@/core/components/slots/SetupDialogSlot.vue';
import { PASSPORT_OVERVIEW } from '@/modules/passport-overview/routes';
import { WORKSTATION_SETUP_ROUTE } from '@/modules/workstation-setup/routes';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    WizardForm,
    SetupDialogSlot
  },

  data() {
    return {
      wizardFormConfig: [
        // {
        //   name: 'insurance',
        //   title: 'Insurances',
        //   component: 'Insurance',
        //   dependent: 'companySetup',
        //   formHeader: 'Sole Trader',
        //   hideArrow: true,
        // },
        {
          name: 'soleTraderName',
          title: 'Sole Trader',
          component: 'SoleTraderName',
          dependent: 'companySetup',
          formHeader: 'Sole Trader',
          checkValidation: true,
          hideArrow: true,
        },
        {
          name: 'subscriptionPlan',
          title: 'Subscription',
          component: 'Subscription',
          dependent: 'limitedCompany',
          formHeader: 'Subscription Plan',
          hideArrow: true,
        },
        // {
        //   name: 'IdCheck',
        //   title: 'ID Check',
        //   component: 'IdCheck',
        //   dependent: 'subscriptionPlan',
        //   formHeader: 'ID Check',
        //   hideArrow: true,
        // },
        {
          name: 'termConditions',
          title: 'Terms & Conditions',
          component: 'TermsAndConditions',
          dependent: 'IdCheck',
          formHeader: 'Terms & Conditions',
          hideArrow: true,
        },
      ],

      activeForm: null,
      PASSPORT_OVERVIEW
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['isOnPassportOverview'])
  },

  methods: {
    ...mapActions(USERS_STORE, ['setNotInMainLayout']),
    ...mapActions(['setWorkstationSetupModal', 'setLimitedCompanySetupModal', 'setSoleTraderSetupModal']),

    setActiveForm(form) {
      this.activeForm = form;
    },

    onComplete() {
      this.setWorkstationSetupModal(false);
      this.setLimitedCompanySetupModal(false);
      this.setSoleTraderSetupModal(false);
      this.$router.push({ name: WORKSTATION_SETUP_ROUTE });
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
