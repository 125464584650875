<template>
    <el-container class="phone-number flex-column jc-sb">
      <section class="flex-column gap-half">
        <Typography variant="h3" textWeight="400">What the best mobile number for you?</Typography>
        <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
            This is required for our compliance requirements.
        </Typography>
      </section>
      <PhoneForm @next="onComplete"/>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Typography from '@/core/components/ui/Typography.vue';
import PhoneForm from '@/modules/account-setup/forms/PhoneForm.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    Typography,
    PhoneForm
  },

  emits: ['next', 'complete'],

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  created() {
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'getBusinessDetails',
      'saveBusinessDetails',
      'getAccountStatuses',
      'getUser',
      'saveUserState'
    ]),

    next(event) {
      this.$emit('next', event);
    },

    onComplete() {
      this.$emit('complete');
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.phone-number {
}
.mb-0 {
  margin-bottom: 0;
}
</style>
