<template>
    <div class="selected-company-list flex-column w-100">
      <el-scrollbar>
        <div class="selected-company-list__scrollbar-content">
          <div
            class="selected-company-list__item flex-column gap-half"
            v-for="(company, index) in companies" :key="index">
            <SelectionCard
                class="selected-company-list__card-item"
                :unsetMinWidth="true"
                :data="company"
                :selectedValue="selectedCompany">
            </SelectionCard>
            <el-checkbox v-model="agreeToCondition" @change="onAgree">
              <Typography variant="p">I am a person with significant control at this company</Typography>
            </el-checkbox>
          </div>
        </div>
      </el-scrollbar>
      <section class="flex-column gap-2">
        <Button
          style="border: 1px solid #4F55F0"
          @handle-click="addCompany"
          :isActive="true"
          activeColor="#FFF"
          activeFontColor="#4F55F0"
          buttonText="add another company"/>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import SelectionCard from '@/core/components/common/cards/SelectionCard.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Button,
    SelectionCard,
    Typography
  },

  emits: ['add-company', 'remove-company', 'next', 'on-agree'],

  props: ['addedCompany'],

  data() {
    return {
      companies: [
        {
          value: 1,
          title: 'Iknowa Ltd',
          label: 'Company Number: 29846400',
        },
      ],
      agreeToCondition: false
    };
  },

  methods: {
    addCompany() {
      this.$emit('add-company');
    },

    onNext() {
      this.$emit('next', true);
    },

    onAgree() {
      this.$emit('on-agree', this.agreeToCondition);
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.selected-company-list {
    &__scrollbar-content {
      max-height: 150px;
      padding: 0 0.5rem .3rem 0;
    }

    &__icon {
      width: 18px;
      height: 18px;
      min-width: 18px;
      background: #40AE42;
      border-radius: 100%;
      box-sizing: border-box;
    }

    &__card-item {
      border: 2px solid #0037FA;
    }

    &__item {
      padding-bottom: 0.5rem;
      box-sizing: border-box;
      border-bottom: 1px solid #C5D2F2;
    }
}

@include media-sm-max-width() {
  .selected-company-list {
    width: 100%;
  }
}
</style>
