// import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import BalanceView from '@/core/components/ui/BalanceView.vue';
import MoneyInComponent from '@/modules/e-wallet/components/MoneyInComponent.vue';
import MoneyInPayManuallyComponent from '@/modules/e-wallet/components/MoneyInPayManuallyComponent.vue';
import MoneyInSuccessComponent from '@/modules/e-wallet/components/MoneyInSuccessComponent.vue';
import MoneyOutComponent from '@/modules/e-wallet/components/MoneyOutComponent.vue';
import { PAYMENTS_STORE } from '@/store/modules/payments';
import { USERS_STORE } from '@/store/modules/users';
import { WALLET_STORE } from '@/store/modules/wallet';
export default defineComponent({
    components: {
        // ArrowRightBold,
        MoneyInComponent,
        MoneyInPayManuallyComponent,
        MoneyInSuccessComponent,
        MoneyOutComponent,
        BalanceView
    },
    data() {
        return {
            userCustomerAccount: null,
            userCustomerCurrentBalance: 0,
            isLoading: false
        };
    },
    computed: {
        ...mapGetters(WALLET_STORE, [
            'openMoneyInRightbar',
            'openMoneyInPayManuallyRightbar',
            'openMoneyInSuccessRightbar',
            'openMoneyOutRightbar',
            'openMoneyOutVerifyRightbar',
            'openMoneyOutSuccessRightbar'
        ]),
        ...mapGetters(USERS_STORE, ['user', 'isHosted', 'wsRole', 'isWsAdmin']),
        getAvailableBalance() {
            return this.userCustomerCurrentBalance;
        },
    },
    async created() {
        await this.initialize();
    },
    methods: {
        ...mapActions(WALLET_STORE, ['setMoneyInRightbar', 'setMoneyOutRightbar']),
        ...mapActions(USERS_STORE, [
            'getUserCustomerAccountDetails',
            'getUserCustomerAccount',
            'sendVerificationWithdrawal'
        ]),
        ...mapActions(PAYMENTS_STORE, ['executeWithdrawFunds']),
        async initialize() {
            this.userCustomerAccount = await this.getUserCustomerAccount(this.user.id);
            if (this.userCustomerAccount?.accountId) {
                const { accountId } = this.userCustomerAccount;
                const response = await this.getUserCustomerAccountDetails({ userId: this.user.id, accountId });
                const { availableBalance } = response;
                this.userCustomerCurrentBalance = availableBalance;
            }
        },
    }
});
