import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import { DATE_FORMAT } from '@/core/constants';
import NumberHelper from '@/core/helpers/number.helper';
// import CurrencyFilter from '@/filters/currency.filter';
import { INSURANCES_STORE } from '@/store/modules/insurances';
export default defineComponent({
    name: 'insurance-form',
    emits: ['on-update-value', 'on-taggle-form'],
    props: ['value', 'showSaveButton', 'hideBackCta'],
    components: {
        UploadForm,
    },
    data() {
        return {
            DATE_FORMAT,
            form: {
                type: '',
                expiryDate: '',
                // insuredCompany: '',
                sumCoverage: '',
                attachments: []
            },
            attachmentSources: [],
            disabledDate(date) {
                return date < moment(new Date()).set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                }).toDate();
            },
            options: [],
            rules: {
                type: [
                    {
                        required: true,
                        message: 'Select an insurance type',
                        trigger: 'change'
                    },
                ],
                expiryDate: [
                    {
                        required: true,
                        message: 'Provide an expiry date',
                        trigger: 'change'
                    },
                ],
                insuredCompany: [
                    {
                        required: true,
                        message: 'Provide the insurer company',
                        trigger: 'change'
                    },
                ],
                sumCoverage: [
                    {
                        required: true,
                        message: 'Provide the cost',
                        trigger: 'change'
                    },
                ],
            },
        };
    },
    watch: {
        form: {
            immediate: true,
            deep: true,
            handler(value) {
                this.validateForm(value);
            }
        },
    },
    async created() {
        if (!this.insurances.length) {
            await this.getInsurances();
        }
        if (this.insurances.length) {
            this.options = this.insurances.map((item) => {
                const { id, name } = item;
                return { value: id, label: name };
            });
        }
    },
    computed: {
        ...mapGetters(INSURANCES_STORE, [
            'insurances',
        ]),
    },
    methods: {
        ...mapActions(INSURANCES_STORE, ['getInsurances']),
        isNumber(event, value) {
            return NumberHelper.isNumber(event, value);
        },
        convertToDecimal(value) {
            // const convertedValue = NumberHelper.convertToDecimal(value);
            // return CurrencyFilter.formatToCurrency(convertedValue);
            return NumberHelper.convertToDecimal(value);
        },
        validateForm(data) {
            let value = data;
            let isValid = true;
            const fields = Object.keys(data);
            // if (fields.length && data.type) {
            //   isValid = true;
            //   this.$emit('on-update-value', value);
            // }
            if (fields.length) {
                fields.forEach((field) => {
                    if (!data[field]) {
                        isValid = false;
                    }
                });
            }
            if (!isValid) {
                value = {};
            }
            this.$emit('on-update-value', value);
            // rules not respected, bug?
            // const { $refs } = this as any;
            // if ('insuranceForm' in $refs) {
            //   $refs.insuranceForm.validate((valid) => {
            //     // if (valid) {
            //     // }
            //   });
            // }
        },
        onToggleForm() {
            this.$emit('on-taggle-form');
        }
    }
});
