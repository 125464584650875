<template>
    <div class="navigation-menu flex-row ai-c">
      <el-dropdown placement="bottom-end" trigger="click" v-for="(menu, index) in menus" :key="index">
        <div class="navigation-menu__workspace-wrapper flex-row ai-c gap-half" v-if="menu.key === WORKSPACE">
          <ProfileAvatar :name="currentWorkspace.name" :img="currentWorkspace.img" imgSize="25px"/>
          <Typography variant="p" class="hide-in-mobile">{{ currentWorkspace.name }}</Typography>
          <el-icon :size="17" color="#0C0F4A"><ArrowDownBold/></el-icon>
        </div>
        <div class="circular-bg cursor-pointer" v-else>
          <Icon class="navigation-menu__icon" :iconName="menu.icon" iconHeight="20px" iconWidth="20px"/>
        </div>
         <template #dropdown>
          <el-dropdown-menu class="dropdown-menu-container flex-column gap-half">
            <el-dropdown-item class="p-half box-border" v-for="(item, index) in menu.dropdownItems" :key="index">
              <NotificationDropdownItem
                v-if="menu.key === NOTIFICATIONS"
                :time="item.time"
                :img="item.img"
                :name="item.name"
                :label="item.label"/>
              <WorkstationDropdownItem
                v-if="menu.key === WORKSPACE"
                :img="item.img"
                :name="item.name"
                :label="item.label"/>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
</template>
<script>
import { ArrowDownBold } from '@element-plus/icons';
import { defineComponent } from 'vue';

import NotificationDropdownItem from '@/core/components/common/item/NotificationDropdownItem.vue';
import WorkstationDropdownItem from '@/core/components/common/item/WorkstationDropdownItem.vue';
import ProfileAvatar from '@/core/components/common/thumbnail/ProfileAvatar.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

const SETTINGS = 'settings';
const NOTIFICATIONS = 'notifications';
const WORKSPACE = 'workspace';

export default defineComponent({
  name: 'NavigationMenu',

  components: {
    Icon,
    NotificationDropdownItem,
    WorkstationDropdownItem,
    ProfileAvatar,
    ArrowDownBold,
    Typography
  },

  data() {
    return {
      NOTIFICATIONS,
      SETTINGS,
      WORKSPACE,

      // TEMPORARY CURRENT WORKSPACE DATA
      currentWorkspace: {
        name: 'Jasper Gargar',
        img: null
      },

      menus: [
        {
          key: WORKSPACE,
          icon: 'workspace-logo-two.svg',
          dropdownItems: [
            {
              name: 'Thomas Crossman',
              label: 'Personal Trade Dashboard',
              img: 'sample/path'
            },
            {
              name: 'Hello Crossman Ltd',
              label: 'LTD Company Workstation',
              img: null
            },
          ]
        },
        {
          key: SETTINGS,
          icon: 'settings.svg',
        },
        {
          key: NOTIFICATIONS,
          icon: 'notification-bell.svg',
          dropdownItems: [
            {
              time: '10 Minutes', // time must be pass as raw time
              name: 'Thomas Crossman',
              label: 'has uploaded 4 documents to Cornwallis Avenue Project',
              img: 'sample/path'
            },
            {
              time: '10 Minutes', // time must be pass as raw time
              name: 'Thomas Crossman',
              label: 'has uploaded 6 documents to Cornwallis Avenue Project',
              img: null
            }
          ]
        },
      ]
    };
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/variables/layout" as *;

.dropdown-menu-container {
  max-width: 300px;
  min-width: 300px;
  padding: 1rem;
  box-sizing: border-box;
}

.navigation-menu {
  &__workspace-wrapper {
    padding: .5rem;
    box-sizing: border-box;
    background: #FFF;
    border-radius: 100px;
    cursor: pointer;
  }
}

@include media-sm-max-width() {
  .navigation-menu {
    &__icon {
      min-width: 16px;
      min-height: 16px;
      width: 16px;
      height: 16px;
    }
  }
}
</style>
