import { defineComponent } from '@vue/runtime-core';
import { mapActions } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'workstation-profile-modal',
    emits: ['close'],
    components: {
        BaseModal
    },
    props: {
        show: Boolean
    },
    data: () => {
        return {
            profile: ''
        };
    },
    computed: {},
    methods: {
        ...mapActions(['setWorkstationInviteModal', 'setWorkstationProfileModal']),
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        },
        handleBack() {
            this.setWorkstationProfileModal(false);
            this.setWorkstationInviteModal(true);
        }
    }
});
