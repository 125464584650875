import { defineComponent } from 'vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { PROJECT_SEARCH_ROUTE } from '../../../../modules/project-search/routes';
export default defineComponent({
    name: 'user-add-new-skill-redirect-modal',
    components: {
        BaseModal,
    },
    props: {
        show: Boolean,
    },
    methods: {
        submit() {
            this.$router.push('/account/skills');
            this.$emit('close');
        },
        cancel() {
            this.$router.push({ name: PROJECT_SEARCH_ROUTE });
            this.$emit('close');
        }
    }
});
