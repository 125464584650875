import { Check, DeleteFilled, MoreFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { NotificationInAppStatusEnum, NotificationInAppTypeEnum, NotificationStateEnum, RolesEnum } from '@/core/packages/shared-library';
import { PROJECT_OVERVIEW_ROUTE } from '@/modules/project-overview/routes';
import { PROJECT_QUOTE_OVERVIEW_ROUTE } from '@/modules/project-quote-overview/routes';
import { NOTIFICATIONS_STORE } from '@/store/modules/notifications';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
export default defineComponent({
    name: 'notification-card',
    components: {
        Check,
        MoreFilled,
        DeleteFilled,
    },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    data: () => {
        return {
            NotificationStateEnum,
            NotificationInAppStatusEnum,
            NotificationInAppTypeEnum,
        };
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    },
    methods: {
        goToSource() {
            let pathName;
            switch (this.notification.sourceType) {
                case NotificationInAppTypeEnum.PROJECT:
                    pathName = PROJECT_OVERVIEW_ROUTE;
                    break;
                case NotificationInAppTypeEnum.QUOTE:
                    if (this.authenticatedUser?.roleId === RolesEnum.TRADESPERSON) {
                        pathName = PROJECT_OVERVIEW_ROUTE;
                    }
                    else {
                        pathName = PROJECT_QUOTE_OVERVIEW_ROUTE;
                    }
                    break;
                default:
                    pathName = '';
                    break;
            }
            if (pathName) {
                this.$router.push({ name: pathName, params: { projectRefId: this.notification.sourceId } });
            }
        },
        getNotificationColor() {
            const status = this.notification.metadata ? this.notification.metadata.status : null;
            let color = '#40AE43';
            switch (status) {
                case NotificationInAppStatusEnum.COMPLETED:
                case NotificationInAppStatusEnum.ACCEPTED:
                    color = '#40AE43';
                    break;
                case NotificationInAppStatusEnum.IN_PROGRESS:
                    color = '#4887FF';
                    break;
                case NotificationInAppStatusEnum.NOT_STARTED:
                    color = '#E3B434';
                    break;
                case NotificationInAppStatusEnum.REJECTED:
                case NotificationInAppStatusEnum.CANCELLED:
                    color = '#F45454';
                    break;
                default:
                    color = '#40AE43';
                    break;
            }
            return color;
        },
        handleCommand(command) {
            const payload = { ...this.notification };
            this.$store.dispatch(`${NOTIFICATIONS_STORE}/${command}`, payload);
        }
    }
});
