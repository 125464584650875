<template>
    <div class="associated-company-list flex-column">
      <Typography variant="h6" textWeight="600">Your Associations</Typography>
      <el-scrollbar>
        <div class="associated-company-list__scrollbar-content">
          <div
            class="associated-company-list__item flex-row ai-c jc-sb">
            <div class="flex-column gap-0">
              <Typography variant="p">Iknowa Ltd</Typography>
              <div class="flex-row ai-c gap-half">
                <span class="associated-company-list__icon flex-row ai-c jc-c">
                  <el-icon color="#FFF" :size="13"><Select/></el-icon>
                </span>
                <Typography variant="p" textWeight="400" textColor="#40AE42">Company Verified On Iknowa</Typography>
              </div>
            </div>
            <Icon
              @click="removeCertificate(association.id)"
              class="cursor-pointer"
              iconName="trash-icon.svg"
              iconWidth="18px"
              iconHeight="18px"/>
          </div>
        </div>
      </el-scrollbar>
      <section class="flex-column gap-2">
        <Button
          style="border: 1px solid #4F55F0"
          @handle-click="addAssociation"
          :isActive="true"
          activeColor="#FFF"
          activeFontColor="#4F55F0"
          buttonText="add another company"/>
        <Button
          :isActive="true"
          @handle-click="onNext"
          buttonText="confirm"/>
      </section>
    </div>
</template>
<script>
import { Select } from '@element-plus/icons';
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    Button,
    Icon,
    Select
  },

  emits: ['add-association', 'remove-association', 'next'],

  props: ['addedAssociation'],

  methods: {
    addAssociation() {
      this.$emit('add-association');
    },

    removeAssociation(associationId) {
      this.$emit('remove-association', associationId);
    },

    onNext() {
      this.$emit('next', true);
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.associated-company-list {
    width: 50%;

    &__scrollbar-content {
      max-height: 150px;
      padding: 0 0.5rem .3rem 0;
    }

    &__icon {
      width: 18px;
      height: 18px;
      min-width: 18px;
      background: #40AE42;
      border-radius: 100%;
      box-sizing: border-box;
    }
}

@include media-sm-max-width() {
  .associated-company-list {
    width: 100%;
  }
}
</style>
