<template>
    <el-form
      label-position="top"
      class="form"
      @submit.prevent="findAddress"
      >
      <el-form-item
        label="Address line"
        class="iknowa-form-item"
      >
        <el-input
          class="search-input"
          v-model="addressLine"
          :disabled="address ? true : false"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="City/ Town"
        class="iknowa-form-item"
      >
        <el-input
          class="search-input"
          v-model="city"
          :disabled="address ? true : false"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Postcode"
        class="iknowa-form-item"
      >
        <el-input
          class="postcode-input"
          v-model="postcode"
          :disabled="address ? true : false"
        >
        </el-input>
      </el-form-item>
      <!-- <el-form-item v-if="isFilledAddress" class="iknowa-form-item">
          <el-checkbox class="legal-owner" @change="$emit('update-legalowner')">I herby confirm that I'm the legal owner or representitive of the above property.</el-checkbox>
      </el-form-item> -->
      <div class="flex-column">
        <el-button
          v-if="step === 1 && !isFilledAddress"
          native-type="submit"
          class="small-button"
          round
          :loading="submitting"
        >Add address</el-button
        >
        <Typography
          @click="backToPostCodeForm"
          class="cursor-pointer"
          variant="p"
          textColor="#FAA500"
          textWeight="500">
          Cancel
        </Typography>
      </div>
    </el-form>
</template>
<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import Typography from '@/core/components/ui/Typography.vue';
import { USERS_STORE } from '@/store/modules/users';

import { VUE_APP_OS_API, VUE_APP_OS_FIND_URL } from '../../../config';

export default defineComponent({
  components: {
    Typography
  },

  emits: ['back', 'manual-address-data'],

  props: ['step', 'address', 'isLegalOwner', 'isOnResidentialForm'],

  data() {
    return {
      addressLine: '',
      city: '',
      postcode: '',
      submitting: false,
      combinedAddress: ''
    };
  },

  created() {
    if (this.address) {
      const [addressLineRaw] = this.address.ADDRESS.split(`, ${this.address.POSTCODE}`);
      const [addressLine] = addressLineRaw.split(`, ${this.address.POST_TOWN}`);
      this.addressLine = addressLine;
      this.city = this.address.POST_TOWN;
      this.postcode = this.address.POSTCODE;
      return;
    }
    if (this.hasResidentialAddress) {
      const userBusinessDetails = this.user?.userBusinessDetails;
      this.addressLine = userBusinessDetails.residencyAddressLine;
      this.city = userBusinessDetails.residencyTown;
      this.postcode = userBusinessDetails.residencyPostcode;
    }
  },

  methods: {
    setLegalOwner() {
      this.$emit('set-legalowner');
    },
    async findAddress() {
      if (this.isFilledAddress) {
        this.submitting = true;
        this.postcodes = [];
        try {
          const response = await axios.get(`${VUE_APP_OS_FIND_URL}?maxresults=1&query=${this.addressLine},${this.city},${this.postcode}&output_srs=EPSG:4326&key=${VUE_APP_OS_API}`);
          if (response.data.results.length > 0) {
            this.$emit('select-address', response.data.results[0].DPA);
            this.$emit('validPostcode', true);
          }
          this.submitting = false;
        } catch (e) {
          this.submitting = false;
        }
      }
      if (this.formIsReady) {
        const manualAddress = {
          addressLine: this.addressLine,
          city: this.city,
          postcode: this.postcode
        };
        this.$emit('manual-address-data', manualAddress);
      } else {
        this.$notify.error({
          message: 'Please fill all fields'
        });
      }
    },

    backToPostCodeForm() {
      this.$emit('back');
    }
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),

    isFilledAddress() {
      return this.address !== null && this.address !== undefined;
    },

    formIsReady() {
      return this.postcode !== '' && this.city !== '' && this.addressLine !== '';
    },

    hasResidentialAddress() {
      const userBusinessDetails = this.user?.userBusinessDetails;
      const postcode = userBusinessDetails.residencyPostcode;
      const town = userBusinessDetails.residencyTown;
      const addressline = userBusinessDetails.residencyAddressLine;

      if (this.isOnResidentialForm) {
        return postcode && town && addressline;
      }

      return false;
    }
  },

  watch: {
    postcode(value) {
      this.postcode = value.split(' ').join('');
    }
  }
});
</script>
<style lang="scss" scoped>
.el-form {
    margin-bottom:1.5rem;
    max-width: 400px;
    .iknowa-form-item {
        margin-bottom:1rem;
    }
    .small-button {
        color: #fff;
        background: #FAA200;
        padding: 1rem 2.5rem;
        height: 42px;
        box-sizing: border-box;
        border-radius: 8px;
        border: none;
        outline: none;
        display: flex;
        align-content: center;
        justify-content: center;
    }
}
::v-deep .el-form-item.iknowa-form-item .el-form-item__label {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #0C0F4A;
    text-align: left;
    position:relative;
    z-index: 12;
}
::v-deep .el-input .el-input__inner {
    border: 0 solid rgba(255, 255, 255, 0.15);
    box-sizing: border-box;
    height: 40px;
    outline: none;
    font-size: 16px;
    line-height: 22px;
    border-radius: 100px;
    color: #0C0F4A;
    background-color: rgba(12, 15, 74, 0.05);
    padding: 5px 20px;
}

::v-deep .el-input.postcode-input .el-input__inner {
  text-transform: uppercase;
}

.legal-owner {
  :deep(.el-checkbox__label) {
    line-height: 22px;
    white-space: pre-line;
    color: #0C0F4A;
  }
  :deep(.el-checkbox__input) {
    margin-bottom: 23px;
  }
  :deep(.el-checkbox__input .el-checkbox__inner) {
    border-radius: 100px;
    border: 1px solid rgba(12, 15, 74, 0.5);
    width: 18px;
    height: 18px;
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
    display: none;
  }
  :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
    border: 1px solid rgba(12, 15, 74, 0.5);
    background: rgba(255, 255, 255, 0.15);
    transition: 0.2s ease-in-out;
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    border: 4px solid #ffa500;
    background: transparent;
    transition: 0.2s ease-in-out;
  }
}

</style>
