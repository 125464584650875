import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
export default defineComponent({
    name: 'workstation-choose-modal',
    emits: ['close'],
    components: {
        BaseModal,
    },
    props: {
        show: Boolean,
    },
    data: () => {
        return {};
    },
    computed: {
        ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation'])
    },
    methods: {
        ...mapActions(['setWorkstationChooseModal', 'setWorkstationCompanyModal']),
        ...mapActions(USER_WORKSTATION, ['setTypeWorkstation']),
        nextStep() {
            this.setWorkstationCompanyModal(true);
            this.setWorkstationChooseModal(false);
        },
        choose(value) {
            this.setTypeWorkstation(value);
        },
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        }
    }
});
