import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
export default defineComponent({
    name: 'footer-menu',
    computed: {
        ...mapGetters(['isTradesperson', 'isNavCollapse']),
        activeMenuRoute() {
            const route = this.$route;
            return route;
        }
    },
    methods: {
        ...mapActions(['setNavCollapseState']),
        toggleSideNav() {
            this.setNavCollapseState(!this.isNavCollapse);
        },
    }
});
