<template>
    <div class="review-item flex-row ai-fs jc-fs">
       <Icon
        class="review-item__icon"
        customPath="images"
        iconName="sample-avatar.jpg"
        iconHeight="34px"
        iconWidth="34px"/>
        <div class="flex-column jc-sb gap-half">
            <span class="flex-column gap-0">
               <Typography variant="h6">Renewable experts</Typography>
               <Typography class="review-item__review" variant="p" textColor="rgba(12, 15, 74, 0.5)" lineHeight="18px">
                 I've added my property to ensure it meets the EPC standard & will be reducing my energy bill with Solar.
               </Typography>
            </span>
            <RatingStar :count="5"/>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import RatingStar from '@/core/components/ratings/RatingStar.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Icon,
    Typography,
    RatingStar
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.review-item {
  min-width: 270px;
  max-width: 270px;
  min-height: 138px;
  max-height: 138px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(12, 15, 74, 0.20);
  padding: 1.3rem 1rem;
  box-sizing: border-box;

  &__icon {
    border-radius: 100%;
    overflow: hidden;
  }

  &__review {
    font-size: 10px;
  }
}
</style>
