import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Button from '@/core/components/ui/Button.vue';
import NumberHelper from '@/core/helpers/number.helper';
// import {
//   checkIfPhoneNumberExist
// } from '@/core/validators';
import { phoneUKValidator } from '@/modules/registration/validators/registration.validator';
// import VerificationCodeForm from '@/modules/account-setup/forms/VerificationCodeForm.vue';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        Button,
    },
    emits: ['next'],
    data() {
        return {
            phoneUKValidator,
            // checkIfPhoneNumberExist,
            NumberHelper,
            userForm: {
                contactNo: null
            },
            isFormReady: false,
            isSubmitting: false,
            validPhoneNumber: false,
            rules: {
                contactNo: [
                    {
                        required: true,
                        validator: (rule, value, cb) => {
                            this.formChecker(value);
                            cb();
                        },
                        message: 'Phone number is required',
                        trigger: 'blur'
                    },
                ]
            }
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
    },
    async created() {
        const { user } = this;
        await this.getUser(user.id)
            .then((response) => {
            this.userForm.contactNo = response.contactNo;
        });
        if (this.userForm.contactNo) {
            this.validPhoneNumber = true;
        }
    },
    methods: {
        ...mapActions(USERS_STORE, ['updateUser', 'getUser']),
        onSavePhonenumber() {
            const formData = { ...this.userForm };
            this.isSubmitting = true;
            this.updateUser({ user: formData, userId: this.user.id })
                .then(async (response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Success',
                        message: 'You have successfully added a phone number.'
                    });
                    this.$emit('next', true);
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'There\'s a problem saving data. Please try again.'
                });
            })
                .finally(() => {
                this.isSubmitting = false;
            });
        },
        isNumber(event, value) {
            return NumberHelper.isNumber(event, value);
        },
        setFormStatus(event) {
            this.isFormReady = event;
        },
        onSubmitCode() {
            this.$emit('next', true);
        },
        formChecker(contactNo) {
            if (contactNo && contactNo !== '') {
                this.isFormReady = true;
            }
            else {
                this.isFormReady = false;
            }
        }
    }
});
