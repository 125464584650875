<template>
    <section class="invite-container">
        <div class="form-container">
            <p class="label">Email Address</p>
            <el-form
                class="form"
                size="small"
                label-position="top"
                status-icon
                >
                <el-form-item
                    class="iknowa-form-item email-address-container"
                    prop="username"
                >
                    <el-input v-model="emailInput"/>
                </el-form-item>
                <el-form-item
                    class="iknowa-form-item role-container"
                    prop="username"
                >
                    <el-select class="members-role" v-model="value" filterable placeholder="Role">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <p class="invite-cta" @click="addToList">Add <span><img src="@/assets/icons/send-invite-icon.svg"></span></p>
        <div class="added-container">
           <p>Added</p>
           <el-scrollbar class="added-list" v-if="initialAdded && initialAdded !== null && initialAdded.length > 0">
              <AddedMemberItem v-for="(item, index) in initialAdded" :key="index" :added="initialAdded" :options="options" @on-remove="removeFromList"/>
           </el-scrollbar>
           <div class="no-content" v-else>
               <NoContentComponent/>
           </div>
        </div>
    </section>
</template>
<script>
import { ElNotification } from 'element-plus';
import { mapActions, mapGetters } from 'vuex';

// import { reactive, ref } from 'vue';
import AddedMemberItem from '@/core/components/members/AddedMemberItem.vue';
import NoContentComponent from '@/core/components/messages/NoContentComponent.vue';
import { EXISTING_EMAIL_ERROR_MESSAGE, INVALID_EMAIL_ROLE_ERROR_MESSAGE } from '@/core/constants/messages';
import StringHelper from '@/core/helpers/string.helper';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { PROPERTY_STORE } from '@/store/modules/property-details';

const POST_PROJECT_ROUTE = 'post-project';
const PROPERTY_MEMBER_ROUTE = 'property-details/members';

export default {
  components: {
    AddedMemberItem,
    NoContentComponent
  },

  beforeUnmount() {
    this.setUnsavedPropertyMembers([]);
  },

  data() {
    return {
      entityType: POST_PROJECT_ROUTE,
      emailInput: '',
      value: '',
      initialAdded: [],
      options: [
        {
          value: 'Viewer',
          label: 'Viewer'
        },
        {
          value: 'Editor',
          label: 'Editor'
        },
      ]
    };
  },

  async created() {
    this.initialize();
  },

  watch: {
    addedPropertyMembers: {
      immediate: true,
      deep: true,
      handler() {
        this.initialAdded = [];
      }
    },
  },

  computed: {
    ...mapGetters(PROPERTIES_PROJECT_POST_STORE, [
      'addedProjectMembers',
      'unsavedProjectMembers'
    ]),

    ...mapGetters(PROPERTY_STORE, [
      'addedPropertyMembers'
    ]),
  },

  methods: {
    ...mapActions(PROPERTY_STORE, [
      'setUnsavedPropertyMembers',
      'getMemberPermissions'
    ]),

    ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
      'setUnsavedProjectMembers',
    ]),

    async initialize() {
      const memberPermissions = await this.getMemberPermissions();
      this.setUnsavedPropertyMembers([]);
      this.initialAdded = this.entityType === PROPERTY_MEMBER_ROUTE
        ? [] : this.unsavedProjectMembers;

      const { $route } = this;
      const { name } = $route;

      if (name.includes(PROPERTY_MEMBER_ROUTE)) {
        this.entityType = PROPERTY_MEMBER_ROUTE;
      }

      this.options = memberPermissions.map((memberPermission) => {
        return {
          value: memberPermission.id,
          label: memberPermission.name.toUpperCase()
        };
      });
    },

    addToList() {
      const { value, emailInput, entityType } = this;

      if (StringHelper.isValidEmail(emailInput) && value !== null && value !== '' && emailInput !== null && emailInput !== '') {
        const findExistingEmail = this.initialAdded.find((item) => item.email.toLowerCase() === emailInput.toLowerCase());

        if (findExistingEmail) {
          this.$notify.error({
            message: `${EXISTING_EMAIL_ERROR_MESSAGE} ${emailInput}.`
          });
          return;
        }

        this.initialAdded.push(
          {
            email: emailInput,
            role: value
          }
        );
        this.emailInput = '';
        this.value = '';

        if (entityType === PROPERTY_MEMBER_ROUTE) {
          this.setUnsavedPropertyMembers(this.initialAdded);
        } else {
          this.setUnsavedProjectMembers(this.initialAdded);
        }
      } else {
        ElNotification.error({
          message: INVALID_EMAIL_ROLE_ERROR_MESSAGE
        });
      }
    },

    removeFromList(email) {
      this.initialAdded = this.initialAdded.filter((added) => {
        return added.email !== email;
      });

      if (this.entityType === PROPERTY_MEMBER_ROUTE) {
        this.setUnsavedPropertyMembers(this.initialAdded);
      } else {
        this.setUnsavedProjectMembers(this.initialAdded);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.invite-container {
    height: 100%;
    display:flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow: hidden;
    position: relative;
    max-width: 600px;
}
.form-container {
    .label {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: left;
        color: #0C0F4A;
        margin-top: 0;
    }
    .el-form {
        background: rgba(12, 15, 74, 0.05);
        border-radius: 100px;
        display:grid;
        grid-template-columns: 4fr 1fr;
        padding:.2rem;
        .el-form-item {
            margin: 0;
            border-radius: 100px;
        }
        .el-form-item.iknowa-form-item.email-address-container {
            :deep(.el-input__inner) {
                border: none;
                outline: none;
                background: transparent;
                border-radius: 100px;
            }
        }

        .el-form-item.iknowa-form-item.role-container {
            :deep(.el-input__inner) {
                border: none;
                outline: none;
                background: transparent;
                border-radius: 100px;
            }
        }
    }
}
    .invite-cta {
        margin: 0;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.25px;
        text-align: left;
        color: #FAA100;
        display: flex;
        align-items:center;
        gap: .7rem;
        cursor: pointer;

        img {
            display: flex;
            align-items:center;
            justify-content: center;
        }
    }
    .added-container {
        height: calc(100% - 10rem);
        width: 100%;
        // position: absolute;
        top: 10rem;
        overflow: hidden;
        display:flex;
        flex-direction: column;
        gap: 1.5rem;

        p {
            margin: 0;
            font-family: Mulish;
            font-size: 18px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0px;
            text-align: left;
            color: #0C0F4A;
        }

        .added-list {
            :deep(.el-scrollbar__view) {
                display:flex;
                flex-direction: column;
                gap: .5rem;
                // height: auto !important;
                // padding-bottom: 2rem;
            }
        }
        .no-content {
            height: 100%;
        }
    }

@include media-xs-max-width() {
  .invite-container {
      height: 100% ;
  }
}
@include media-xs-max-width() {
  // .invite-container {
  //     height: 100% ;
  // }
  .form-container {
    .el-form {
      .el-form-item.iknowa-form-item.role-container {
        :deep(.el-input__inner) {
            border: none;
            outline: none;
            background: transparent;
            border-radius: 100px;
            width: 100px;
        }
      }
    }
  }
}
</style>
