import { defineComponent } from 'vue';
import Typography from '@/core/components/ui/Typography.vue';
import KYPScoreTag from './KYPScoreTag.vue';
import PriorityTag from './PriorityTag.vue';
import RecommendationTag from './RecommendationTag.vue';
export default defineComponent({
    components: {
        Typography,
        KYPScoreTag,
        PriorityTag,
        RecommendationTag
    },
    props: ['score', 'address', 'projectName']
});
