export default {
    item1: [
        {
            span: 'Account -',
            p: 'The electronic account, also known as Modulr Account provided by us in accordance with these Terms and Conditions.'
        },
        {
            span: 'Account Information Service Provider –',
            p: 'means a third party payment service provider who is authorised by or registered with the Financial Conduct Authority or another European regulator to provide online account information services, who, with your permission will be able to access certain online account information on one or more payment accounts held by you to give you a consolidated view of your payment accounts'
        },
        {
            span: 'Account Limits –',
            p: 'Maximum limits you can have in relation to your Account, such as account maximum balance, and limits on receiving and sending payments from your Account as referred in paragraph 2.'
        },
        {
            span: 'Account Manager -',
            p: ' The individuals elected by the Account Owner to be responsible for the management of the Account, also known as an “Authorised User”.'
        },
        {
            span: 'Account Owner –',
            p: 'The agreement for your Account made up of these Terms and Conditions, together with the Introduced Client Terms of Business and the Introduced Client Application Form, which constitute the entire agreement between you and Modulr. '
        },
        {
            span: 'Application Programming Interface (API) –',
            p: 'means the interfaces provided by Modulr to the Introduced Client (and the Partner Platform on the Introduced Client’s behalf) to directly instruct Modulr Accounts via the Introduced Client’s or the Partner Platform’s own application.'
        },
        {
            span: 'AML Policy -',
            p: ' Modulr’s written policy on anti-money laundering and counter terrorist financing as may be amended from time to time by Modulr.'
        },
        {
            span: 'Applicant –',
            p: 'A customer of the Partner Platform who applies for Modulr Products but is yet to be accepted by the Modulr as an Introduced Client.'
        },
        {
            span: 'Available Balance -',
            p: 'The value of funds available on your Account.'
        },
        {
            span: 'Bacs Credit –',
            p: 'Means Bacs Direct Credit. A service enabling organisations to make payments to an account which takes 3 Business Days for the funds to be cleared.'
        },
        {
            span: 'Business Days -',
            p: 'Monday to Friday between the hours of 9am-5pm but does not include bank holidays, or public holidays in the United Kingdom.'
        },
        {
            span: 'CHAPS –',
            p: 'the Clearing House Automated Payment System, a service enabling organisations to make same-day payments to an account within the UK, within the CHAPS operating days and times. '
        },
        {
            span: 'Commencement Date –',
            p: 'the date set out in the Introduced Client Application Form.'
        },
        {
            span: 'Confidential Information -',
            p: 'any information (whether or not recorded in documentary form, or stored on any magnetic or optical disk or memory) relating to: the business, products, affairs, strategy, contracts, customer relationships, commercial pipelines, business contacts, prospective customers, existing customers, business models, customer pricing, management systems, business methods, corporate plans, maturing new business opportunities, research and development projects, marketing and sales information, sales targets and statistics, discount structures, suppliers and potential suppliers, source codes, computer programs inventions, know-how, technical specifications and other technical information relating to products and services.'
        },
        {
            span: 'Customer Services -',
            p: 'The contact centre for dealing with queries about your Account. Contact details for Customer Services can be found in the Introduced Client Application Form.'
        },
        {
            span: 'Data Protection Laws –',
            p: 'the following, to the extent they are applicable to a Party: the Data Protection Act 2018, the General Data Protection Regulation (EU) 2016/679, the Electronic Communications Data Protection Directive 2002/58/EC, the Privacy and Electronic Communications (EC Directive) Regulations 2003 and all applicable laws and regulations relating to processing of personal data and privacy (as amended or replaced from time to time), including where applicable the guidance and codes of practice issued by the Information Commissioner (in the United Kingdom) or other applicable supervisory authority.'
        },
        {
            span: 'Due Diligence Procedure -',
            p: ' Modulr’s procedures for carrying out due diligence on Introduced Clients in order to comply with its policies and regulatory obligations.'
        },
        {
            span: 'Faster Payment –',
            p: ' A service allowing you to make and receive electronic payments in the UK which is received by the recipient bank within 2 hours provided that the receiving organisation or bank is part of Faster Payments Scheme.'
        },
        {
            span: 'Fees –',
            p: 'those fees payable by the Introduced Client and set out in the Introduced Client Application Form.'
        },
        {
            span: 'Information –',
            p: 'Means any information related to the organisation, and any personal information related to Account Manager.'
        },
        {
            span: 'Intellectual Property Rights –',
            p: ' all patents, trademarks, service marks, trade names, domain names, business names, copyrights, design rights, database rights, rights to or in computer software, know-how, trade secrets, rights to or in confidential information and all other intellectual property rights and rights or forms of protection of a similar nature or effect which may subsist anywhere in the world whether or not registered or capable of registration, together with all applications for registration of, and any licence to use, any of the foregoing and "Intellectual Property" shall be construed accordingly.'
        },
        {
            span: 'Introduced Client Application Form –',
            p: ' The application form identifying the parties, Modulr Products to be provided and commercial terms that forms part of the Agreement between an Introduced Client and Modulr.'
        },
        {
            span: 'Introduced Client –',
            p: ' Any client of Modulr which has been introduced by the Partner Platform and whose account is operated by the Partner Platform based on instructions the Partner Platform receives from the Introduced Client (where relevant).'
        },
        {
            span: 'Introduced Client Terms of Business -',
            p: 'The terms on which Modulr provides Modulr Products to the Introduced Client.'
        },
        {
            span: 'Modulr –',
            p: ' Modulr Finance Ltd, a company registered in England and Wales with number 09897957 and whose registered office is at 1 Hammersmith Broadway, London, W6 9DL. Modulr Finance Ltd (FRN: 900699) is a registered agent of Modulr FS Ltd.'
        },
        {
            span: 'Modulr Account Terms and Conditions -',
            p: ' This agreement, between Modulr FS and the Introduced Client which governs the terms on which the Introduced Client may use its Account.'
        },
        {
            span: 'Modulr Products –',
            p: 'those products, including but not limited to the Account referred to in the Introduced Client Application Form.'
        },
        {
            span: 'Online Portal –',
            p: ' means the interface provided by Modulr for the Introduced Client to access via the public internet, subject to applicability based on the Introduced Client’s relationship with the Partner Platform.'
        },
        {
            span: 'Payment Initiation Service Provider –',
            p: 'means a third party payment service provider authorised by or registered with by the Financial Conduct Authority or another European regulator to provide an online service to initiate a Transaction at your request on your Account. '
        },
        {
            span: 'Partner Platform –',
            p: ' A third party that is permitted by Modulr and Modulr FS to introduce and act on behalf of Introduced Clients, and permitted by you to act as an Authorised User.'
        },
        {
            span: 'Partner Platform Agreement -',
            p: 'an agreement between the Account Owner and the Partner Platform for the provision of various services, under the terms of which the Introduced Client wishes to open an Account with Modulr to be used for the purpose and in accordance with the terms set out in the Partner Platform Agreement.'
        },
        {
            span: 'Regulator –',
            p: ' the Financial Conduct Authority, located at 12 Endeavour Square, London, E20 1JN or any authority, body or person having, or who has had, responsibility for the supervision or regulation of any regulated activities or other financial services in the United Kingdom.'
        },
        {
            span: 'Transaction –',
            p: 'any debit, credit or other adjustment to an Account that affects the balance of monies held in it.'
        },
        {
            span: 'TPP (Third Party Provider) –',
            p: 'means an Account Information Service Provider or a Payment Initiation Service Provider. '
        },
        {
            span: 'we, us, our or Modulr FS -',
            p: 'Modulr FS Ltd, a company registered in England and Wales with number 09897919 and whose registered office is at 1 Hammersmith Broadway, London, W6 9DL and who is regulated by the Financial Conduct Authority for issuance of electronic money under FRN 900573, or Modulr acting on Modulr FS Ltd’s behalf.'
        },
        {
            span: 'Website –',
            p: 'means the customer portal that Introduced Clients can login to in order to use the Modulr Products.'
        },
        {
            span: 'you, your -',
            p: 'The Account Owner, also referred to as an Introduced Client.'
        },
    ],
    item2: [
        {
            p: 'Limits may apply to the maximum value of an individual payment Transaction, the maximum aggregate value of all payment Transactions made from your Account in a particular time period e.g. during any one Business Day and the maximum number of payment Transactions made from your Account over a particular timeframe.'
        },
        {
            p: 'The limits that apply to your Account will be communicated to you during the Account set-up process. These limits may also change over time based on your Account usage; any such change will be communicated to you. You can check the limits at any time by contacting Customer Services. Any payment Transaction request which exceeds such limits will be rejected. '
        },
        {
            p: 'To manage our risk, particularly with respect to money laundering, fraud or security concerns, we also apply internal controls, including limits, to certain types of payment. We change these as necessary but for security purposes, we do not disclose them.'
        },
    ],
    item3: [
        {
            p: 'Your Account is an e-money account and the electronic money associated with it is issued to you by us. We are regulated by the Financial Conduct Authority for the issuance of electronic money (FRN 900573). Your rights and obligations relating to the use of this Account are subject to these Terms and Conditions between you and us.'
        },
        {
            p: 'This Agreement is written and available only in English and we undertake to communicate with you in English regarding any aspect of your Account.'
        },
        {
            p: 'You agree that we or the Partner Platform may communicate with you by e-mail or telephone for issuing any notices or information about your Account and therefore it is important that you ensure you keep your e-mail address and mobile phone number updated.'
        },
        {
            p: 'You can request a copy of these Terms and Conditions at any time by contacting Customer Services.'
        },
    ],
    item4: [
        {
            p: 'Your Account will be opened on your behalf by the Partner Platform. You may only hold an Account so long as you remain an approved client of the Partner Platform that provided you with your account details.'
        },
    ],
    item5: [
        {
            span: '5.1',
            p: 'Your Account can receive bank transfers and other payment types as added and notified to you by Modulr from time to time. Subject to paragraph 5.3, we will credit your Account when we receive the funds which could be up to three Business Days after the payment being instructed, depending on how the payment was sent.'
        },
        {
            span: '5.2',
            p: 'Your Account can also receive internal transfers from other Modulr Accounts owned or controlled by the Partner Platform, which apply instantly.'
        },
        {
            span: '5.3',
            p: 'An incoming payment will not be credited to your Account if:'
        },
        {
            span: '5.3.1',
            p: 'the Account has reached the Account Maximum Balance or Account Limits; or'
        },
        {
            span: '5.3.2',
            p: 'the Account is inactive or blocked or terminated; or'
        },
        {
            span: '5.3.3',
            p: 'the sender has provided incorrect/invalid Account Details for your Account; or'
        },
        {
            span: '5.3.4',
            p: 'we suspect the payment to be fraudulent.'
        },
        {
            span: '5.4',
            p: 'If we are unable to credit your Account for any of the reasons in paragraph 5.3 then the funds may be sent back to the sender without a prior notification to you.'
        },
        {
            span: '5.5',
            p: 'Your Account will be configured and operated by the Partner Platform. You agree that Modulr and we may take instructions from the Partner Platform regarding the operation of your Account, including the creation of beneficiaries and instruction of payments, on your behalf. We and Modulr have no liability for actions taken by the Partner Platform. If you disagree with any actions taken by the Partner Platform these should be discussed with the Partner Platform.'
        },
        {
            span: '5.6',
            p: 'Your Account can make payments out to external bank accounts via Faster Payments and other methods as added and notified to you by the Partner Platform from time to time.'
        },
        {
            span: '5.7',
            p: 'A Transaction is deemed to be authorised by you, when you or your Account Manager or Partner Platform enters the security information on the Modulr Online Portal to confirm a Transaction is authorised, or when it is instructed via the Modulr API with the relevant security credentials. Once the Transaction is confirmed, we cannot revoke the Transaction save for in those circumstances set out in paragraph 5.8 below. '
        },
        {
            span: '5.8',
            p: 'The following Transactions may be withdrawn:'
        },
        {
            span: '5.8.1',
            p: 'any Transaction which is agreed to take place on a date later than the date you authorised it, provided that notice is given no later than close of business on the business day before the Transaction was due to take place; and/or'
        },
        {
            span: '5.8.2',
            p: 'Transactions due to take place on a future date.'
        },
        {
            span: '5.9',
            p: 'If we refuse to execute a payment order to or to initiate a Transaction, we will, without undue delay and provided we are legally permitted to do so, notify you or the Partner Platform of the refusal. If possible, we will provide the reasons for the refusal and where it is possible to provide reasons for the refusal and those reasons relate to factual matters, the procedure of rectifying any factual errors that led to the refusal.'
        },
        {
            span: '5.10',
            p: 'The Available Balance on your Account will not earn any interest.'
        },
        {
            span: '5.11',
            p: 'You can check the balance and Transaction history of your Account at any time via the interface provided to you by the Partner Platform or by contacting Customer Services, or the Online Portal if you have relevant access details.'
        },
        {
            span: '5.12',
            p: 'You will be provided with a monthly statement by the Partner Platform or us (using the details we have associated with your Account).'
        },
    ],
    item6: [
        {
            p: 'You can instruct a TPP to access information on your Account or initiate certain Transactions from your Account provided such TPP has identified itself to us and it has acted in accordance with the relevant regulatory requirements. We will treat any instruction from an TPP as if it was from you or an Account Manager.'
        },
        {
            p: 'We may deny a TPP access to your Account if we are concerned about unauthorised or fraudulent access by that TPP. setting out the reason for such denial. Before doing so, we will tell you that we intend to deny access and give our reasons for doing so, unless it is not reasonably practicable, in which case we will immediately inform you afterwards. In either case, we will tell you in the manner in which we consider most appropriate in the circumstances. We will not tell you if doing so would compromise our security measures or would otherwise be unlawful.'
        },
        {
            p: 'We may deny a TPP access to your Account if we are concerned about unauthorised or fraudulent access by that TPP. setting out the reason for such denial. Before doing so, we will tell you that we intend to deny access and give our reasons for doing so, unless it is not reasonably practicable, in which case we will immediately inform you afterwards. In either case, we will tell you in the manner in which we consider most appropriate in the circumstances. We will not tell you if doing so would compromise our security measures or would otherwise be unlawful.'
        },
    ],
    item7: [
        {
            p: ' You may close your Account by contacting Customer Services. Please refer to your contract with the Partner Platform for any terms relating to your need to maintain your Account.'
        },
        {
            p: 'The Account will be closed if the Partner Platform instructs us to close your Account (in which case the Partner Platform will inform you of this instruction).'
        },
        {
            p: 'Any Available Balance remaining on the Account after Account closure will be transferred to your nominated bank account via Faster Payments based on instructions to us from the Partner Platform. If for any reason this is not possible, such Available Balance will remain yours for a period of six years from the date of Account closure. Within this period, you may at any time request a refund by contacting Customer Services. You will not have any access to your Account and we will not return any funds remaining on the Account after six years from the date of Account closure and this Agreement will terminate.'
        },
    ],
    item8: [
        {
            span: '8.1',
            p: 'You are responsible for understanding and complying with the Agreement including these Terms and Conditions.'
        },
        {
            span: '8.2',
            p: 'We may restrict or refuse to authorise any use of your Account if using your Account is causing or could cause a breach of these Terms and Conditions or if we have reasonable grounds for suspecting that you or a third party has committed or is about to commit a crime or other abuse in connection with your Account.'
        },
        {
            span: '8.3',
            p: 'You or the Account Manager must not:'
        },
        {
            span: '8.3.1',
            p: 'allow another person to use security information related to the Account,'
        },
        {
            span: '8.3.2',
            p: 'write down password(s) or any security information unless this is done in a way that would make it impossible for anyone else to recognise any of that information, or'
        },
        {
            span: '8.3.3',
            p: '3 disclose passwords or any security information, or otherwise make them available to any other person, whether verbally or by entering them in a way that allows them to be observed by others.'
        },
        {
            span: '8.4',
            p: 'You will be liable for all Transactions that take place as a result of you or the Account Manager acting fraudulently or failing to comply with these Terms and Conditions with intent or gross negligence. Any such Transactions and any fees and charges relating to such Transactions will be deducted from the Available Balance on your Account. '
        },
        {
            span: '8.5',
            p: 'You will be liable for all Transactions that the Partner Platform or any other Account Manager makes on your behalf as per this Agreement, along with those made by a TPP authorised by you to initiate a Transaction.'
        },
        {
            span: '8.6',
            p: 'You will be liable for all unauthorised Transactions that arise from the use of lost or stolen Account security information such as but not limited to the Online Portal log in details and API security details, if you or the Account Manager fail to keep the security features of the Account safe.'
        },
        {
            span: '8.7',
            p: ' It is your responsibility to keep us updated of changes to your Information, including e-mail address and mobile numbers. Failure to do so may result in us being unable to contact you regarding your Account or to let you know about changes to these Terms and Conditions.'
        },
        {
            span: '8.8',
            p: 'If you request to recall a Transaction due to an error or mistake caused other than by Modulr, we reserve the right to charge you (i) a handling fee of £25 per recall and (ii) any fee payable by Modulr to a third-party bank or institution for handling the recall.'
        },
        {
            span: '8.9',
            p: 'You agree to indemnify and hold harmless, us, Modulr and our distributors, partners, agents, sponsors, and service providers and their group companies from and against the costs of any legal action taken to enforce this Agreement, including these Terms and Conditions and/or any breach of these Terms and Conditions by you.'
        },
    ],
    item9: [
        {
            span: '9.1',
            p: 'If you have a reason to believe that a Transaction on your Account was unauthorised or was made incorrectly, you must inform us immediately by contacting Customer Services, but in any event within 13 months of the date of the relevant Transaction.'
        },
        {
            span: '9.2',
            p: ' If you dispute a Transaction:'
        },
        {
            span: '9.2.1',
            p: 'subject to 9.2.2 and 9.2.3 we will immediately refund the amount to your Account to the position it would have been in if the unauthorised Transaction had not taken place. We will have no further liability to you. If we subsequently discover that you were not entitled to a refund, we shall treat the refund as a mistake and be entitled to reapply the Transaction. '
        },
        {
            span: '9.2.2',
            p: 'if there are reasonable grounds for thinking that you may not be entitled to a refund (based on the evidence available to us at the time you report the unauthorised Transaction), we may investigate before giving you a refund and we will provide you with our supporting evidence if we believe you are not entitled to the refund.'
        },
        {
            span: '9.2.3',
            p: 'if the Transaction was initiated through a TPP, it is for the TPP to prove that, the Transaction was authenticated, accurately recorded and not affected by a technical breakdown or other deficiency linked to the TPP’s payment initiation service. '
        },
        {
            span: '9.3',
            p: 'If an incorrect Transaction is paid into your Account that should not have, we will, where possible, immediately send the funds back to the bank acting for the person from whose account the Transaction was made. In such circumstance you agree to return the funds to us and provide such assistance that we require in recovering the amount from you. If we cannot recover the funds, we are required to provide sufficient details about you and the incorrect payment to the bank or institution that sent the payment to enable them to recover the funds.'
        },
        {
            span: '9.4',
            p: ' You will be liable for all Transactions made from your Account if you have acted fraudulently.'
        },
    ],
    item10: [
        {
            p: 'We may change these Terms and Conditions by providing you with at least two months’ prior notice by e-mail (provided you have supplied us with an up-to-date e-mail address).'
        },
        {
            p: 'If you do not agree with the changes to the Terms and Conditions, you may at any time within the two months’ notice period notify us and these Terms and Conditions will be terminated and your Account closed. If you do not notify us to the contrary during this period then you will be deemed to have accepted the change and it will apply to you when it comes into force.'
        },
        {
            p: 'If any part of these Terms and Conditions are inconsistent with any legal requirements then we will not rely on that part but treat it as if it did actually reflect the relevant legal requirement. If we need to make operational changes before we can fully comply with the new regulatory requirement, we will make those changes as soon as reasonably practical.'
        },
    ],
    item11: [
        {
            span: '11.1',
            p: 'We can terminate your Account at any time if we give you two months’ notice and transfer any Available Balance at the time to your nominated bank account without a charge, or'
        },
        {
            span: '11.2',
            p: 'We can suspend or terminate your Account at any time with immediate effect (and until your default has been remedied or the Agreement terminated) without any prior notice to you if:'
        },
        {
            span: '11.2.1',
            p: 'we discover any of the Information that we hold for you is materially incorrect; or'
        },
        {
            span: '11.2.2',
            p: 'if we have reason to believe that you, the Account Manager or a third party has committed or is about to commit a crime or other abuse (including fraud) in connection with your Account; or'
        },
        {
            span: '11.2.3',
            p: 'if you have reached your Account Limit; or'
        },
        {
            span: '11.2.4',
            p: 'you or the Account Manager have breached these Terms and Conditions.'
        },
        {
            span: '11.2.3',
            p: 'In the event that we do suspend or terminate your Account then if we are able to do so, we will tell you in advance otherwise we will let you know immediately afterwards (to the extent the we are permitted by law).'
        },
    ],
    item12: [
        {
            span: '12.1',
            p: 'Our liability in connection with this these Terms and Conditions (whether arising in contract, tort (including negligence), breach of statutory duty or otherwise) shall be subject to the following exclusions and limitations:'
        },
        {
            span: '.12.1.1',
            p: 'we shall not be liable for any default resulting directly or indirectly from any cause beyond our control, including but not limited to, a lack of funds;'
        },
        {
            span: '.12.1.2',
            p: 'we shall not be liable for any loss of profits, loss of business, or any indirect, consequential, special or punitive losses;'
        },
        {
            span: '.12.1.3',
            p: 'where sums are incorrectly deducted from your Available Balance due to our default, our liability shall be limited to payment to you of an equivalent amount to that which was incorrectly deducted from your Available Balance;'
        },
        {
            span: '.12.1.4',
            p: 'in all other circumstances of our default, our liability will be limited to transferring any Available Balance to your nominated bank account.'
        },
        {
            span: '12.2',
            p: 'In circumstances where sums are incorrectly deducted from your Available Balance due to our fault, if we require your support to enable us to recover the incorrect deduction, you agree to provide us with all assistance that we reasonably require.'
        },
        {
            span: '12.3',
            p: 'Nothing in these Terms and Conditions shall exclude or limit our liability for death or personal injury resulting from our negligence or fraud.'
        },
        {
            span: '12.4',
            p: 'To the extent permitted by law, all conditions or warranties implied by law, statute or otherwise are expressly excluded.'
        },
        {
            span: '12.5',
            p: 'The above exclusions and limitations set out in this paragraph shall apply to any liability of our affiliates and other suppliers, contractors, agents or distributors and any of their respective affiliates (if any), to you, which may arise in connection with these Terms and Conditions.'
        },
    ],
    item13: [
        {
            p: 'Some personal data will be necessary for us to provide you with the Account and services under this Agreement. Modulr FS is a Data Controller and shall only use your personal data for this purpose. Please see the Privacy Policy (please contact Customer Services for details of where to access this) for full details on the personal data that we and Modulr Finance Ltd hold, how we will use it and how we will keep it safe.'
        },
        {
            p: 'You must update any changes to your Information by contacting Customer Services.'
        },
        {
            p: 'If we discover that the Information we hold about you is incorrect, we may have to suspend or cancel your Account until we can establish the correct Information, in order to protect us both.'
        },
        {
            p: 'If you or the Account Manager allow or give consent to an Authorised Third Party Provider to access your Account to provide their services, you should know that we have no control over how an Authorised Third Party Provider will use your information nor will we be liable for any loss of information after an Authorised Third Party Provider have access to your information.'
        }
    ],
    item14: [
        {
            p: 'Complaints regarding any element of the service provided by us can be sent to Customer Services.'
        },
        {
            p: 'All complaints will be subject to our complaints procedure. We will provide you with a copy of our complaints procedure upon request and, if we receive a complaint from you, a copy of our complaints procedure will automatically be posted or emailed to you.'
        },
        {
            p: 'In most cases we will provide a full response by email to your complaint within fifteen business days after the date we receive your complaint. In exceptional circumstances where we are unable to respond in full to your complaint, we will inform you of this giving our reasons for the delay and the timeframe within which you will receive a full reply, which in any event shall be within thirty-five days of the date we received your complaint. '
        },
        {
            p: 'If we fail to resolve your complaint to your satisfaction you may refer your complaint to the Financial Ombudsman Service (Exchange Tower, London E14 9SR, phone 0800 023 4567). Details of the service offered by the Financial Ombudsman Service are available at www.financial-ombudsman.org.uk.'
        },
    ],
    item15: [
        {
            p: 'Any delay or failure to exercise any right or remedy under these Terms and Conditions by us shall not be construed as a waiver of that right or remedy or preclude its exercise at any subsequent time.'
        },
        {
            p: 'If any provision of these Terms and Conditions is deemed unenforceable or illegal, the remaining provisions will continue in full force and effect'
        },
        {
            p: 'You may not assign or transfer any of your rights and/or benefits under these Terms and Conditions and you shall be the sole party to the contract between us. You will remain liable until the Account issued to you is terminated. We may assign our rights and benefits at any time without prior written notice to you. We may subcontract any of our obligations under these Terms and Conditions.'
        },
        {
            p: 'Save for Modulr, who acts on our behalf, no third party who is not a party to these Terms and Conditions has a right to enforce any of the provisions in these Terms and Conditions and the Contracts (Rights of Third Parties) Act 1999 shall not apply.'
        },
        {
            p: 'These Terms and Conditions contain the information set out in Schedule 4 of the Payment Service Regulations 2017 and you can obtain a copy of this Agreement at any time by contacting Customer Services.'
        },
        {
            p: 'These Terms and Conditions are governed by English law and you agree to the exclusive jurisdiction of the courts of England and Wales.'
        },
        {
            p: 'The Financial Services Compensation Scheme is not applicable for this Account. No other compensation schemes exist to cover losses claimed in connection with your Account. As a responsible e-money issuer, we will ensure that once we have received your funds they are deposited in a secure account, specifically for the purpose of redeeming Transactions made from your Account. In the event that we or Modulr become insolvent funds that you have loaded which have arrived with and been deposited by us are protected against the claims made by our creditors.'
        },
    ],
    item16: [
        {
            p: 'Customer Services are provided by the Partner Platform. The details are available in your Introduced Client Application Form.'
        },
    ],
};
