<template>
    <el-container class="terms-cons flex-column jc-sb">
      <section class="terms-cons__container flex-column">
        <section class="flex-column">
          <Typography variant="h3" textWeight="400">
            Terms & Conditions
          </Typography>
          <span class="flex-column gap-0" v-if="!isIdVerified">
            <Typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
              Please read through and agree to the terms & conditions to access your Iknowa platform.
            </Typography>
          </span>
        </section>
        <section class="terms-cons__wrapper flex-column jc-fs">
          <div class="terms-cons__terms-wrapper">
              <section class="subscription-screen__wrapper flex-column gap-half">
                <Typography variant="h6">
                    Highlights
                </Typography>
                <div class="flex-row ai-c" v-for="(value, index) in highlights" :key="index">
                  <Icon iconName="blue-check-filled-icon.svg" iconHeight="24px" iconWidth="24px"/>
                  <Typography variant="p" textWeight="400">{{ value.label }}</Typography>
                </div>
              </section>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">The Modulr Account Terms and Conditions</p>
                <p class="terms-cons__terms-item">Please read these Terms and Conditions carefully before you agree to
                     use an Account or any related services provided by or through us.</p>
                <p class="terms-cons__terms-item">By signing the Modulr Account Terms and Conditions you accept the terms of the Agreement,
                     or by agreeing to open an Account and/or using our services, you accept these Terms and Conditions. If there is anything you do not understand,
                     please contact Customer Services using the contact details in the Introduced Client Application Form. </p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">1. DEFINITIONS</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item1" :key="index">
                  <span class="default-text">{{item.span}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">2. ACCOUNT LIMITS</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item2" :key="index">
                  <span class="default-text">2.{{index + 1}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">3. SCOPE OF THESE TERMS AND CONDITIONS</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item3" :key="index">
                  <span class="default-text">3.{{index + 1}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">4. OPENING YOUR ACCOUNT</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item4" :key="index">
                  <span class="default-text">4.{{index + 1}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">5. USING THE ACCOUNT</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item5" :key="index">
                  <span class="default-text">{{item.span}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">6. THIRD PARTY ACCESS</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item6" :key="index">
                  <span class="default-text">6.{{index + 1}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">7. CLOSING YOUR ACCOUNT</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item7" :key="index">
                  <span class="default-text">7.{{index + 1}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">8. YOUR LIABILITY AND AUTHORISATIONS</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item8" :key="index">
                  <span class="default-text">{{item.span}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">9. DISPUTES AND INCORRECT TRANSACTIONS</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item9" :key="index">
                  <span class="default-text">{{item.span}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">10. VARIATION</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item10" :key="index">
                  <span class="default-text">10.{{index + 1}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">11. TERMINATION OR SUSPENSION</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item11" :key="index">
                  <span class="default-text">{{item.span}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">12. OUR LIABILITY</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item12" :key="index">
                  <span class="default-text">{{item.span}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">13. YOUR INFORMATION</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item13" :key="index">
                  <span class="default-text">13.{{index + 1}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">14. COMPLAINTS PROCEDURE</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item14" :key="index">
                  <span class="default-text">14.{{index + 1}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">15. GENERAL</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item15" :key="index">
                  <span class="default-text">15.{{index + 1}}</span> {{item.p}}</p>
              </div>
              <div class="terms-cons__terms-item-container">
                <p class="terms-cons__terms-title">16. CONTACTING CUSTOMER SERVICES</p>
                <p class="terms-cons__terms-item"
                  v-for="(item, index) in termsAndCons.item16" :key="index">
                  <span class="default-text">16.{{index + 1}}</span> {{item.p}}</p>
              </div>
          </div>
        </section>
      </section>
      <section class="terms-cons__footer flex-column w-50">
        <TermsAgreement tag="I accept & agree to the" spanTag="Terms of Service" @on-change-state="hasAgreed"/>
        <Button
          class="terms-cons__button w-100"
          :isActive="agreement"
          @handle-click="onHandleClick"
          buttonText="next"/>
      </section>
    </el-container>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import TermsAgreement from '@/core/components/ui/TermsAgreement.vue';
import Typography from '@/core/components/ui/Typography.vue';
import TermsAndConditionsData from '@/modules/account-setup/termsAndConditionsData';

export default defineComponent({
  components: {
    Typography,
    Button,
    TermsAgreement,
    Icon
  },

  emits: ['next', 'complete'],

  data() {
    return {
      termsAndCons: TermsAndConditionsData,
      showForm: false,
      isIdVerified: false,

      highlights: [
        {
          label: 'Last Updated 21 September 2023'
        },
        {
          label: 'Iknowa stores your data in the UK, we will never sell your data or share without your consent'
        },
        {
          label: 'We use cookies to give you the best experience'
        },
      ],

      agreement: false
    };
  },

  computed: {
  },

  async created() {
  },

  methods: {
    onToggleForm() {
      this.showForm = !this.showForm;
    },

    onHandleClick() {
      this.$emit('complete');
    },

    hasAgreed(event) {
      this.agreement = event;
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.terms-cons {
  &__container {
    width: 100%;
    max-width: 800px;
    max-height: 370px;
  }
  &__terms-wrapper {
    max-height: 280px;
    text-align: justify;
    line-height: 24px;
    overflow: auto;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
  }
  &__terms-item-container {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }

  &__terms-title {
    font-family: Mulish;
    font-size: 17px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #0C0F4A;
    margin: 0;
  }

  &__terms-item {
    font-family: Mulish;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
    color: rgba(12, 15, 74, 0.5);
    margin:0;

    span {
        font-weight: 700;
    }
  }
}

@include media-sm-max-width() {
  .terms-cons {
    &__wrapper {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    &__footer {
      flex-direction: column;
      gap: .5rem;
      align-items: flex-start;
      width: 100%;
    }
  }
}
</style>
