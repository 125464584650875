import { defineComponent } from 'vue';
import Typography from '@/core/components/ui/Typography.vue';
import duration from '../../constants';
export default defineComponent({
    components: {
        Typography
    },
    data() {
        return {
            duration,
            serviceDurations: [
                { key: duration.WEEKLY, label: 'Weekly', value: 7 },
                { key: duration.FORTNIGHTLY, label: 'Fortnightly', value: 14 },
                { key: duration.MONTHLY, label: 'Monthly', value: 30 },
                { key: duration.YEARLY, label: 'Yearly', value: 360 },
                { key: duration.CUSTOM, label: 'Custom' },
            ],
            selectedDuration: ''
        };
    },
    methods: {
        handleClick(key) {
            this.selectedDuration = key;
        }
    },
});
